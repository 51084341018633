import { Component } from '@angular/core';

@Component({
  selector: 'app-preview-features',
  templateUrl: './preview-features.component.html',
  styleUrls: ['./preview-features.component.scss']
})
export class PreviewFeaturesComponent {

  loading = false;

  constructor() { }

}
