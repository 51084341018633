import {PagingQuery} from "@shared";
import {EntityCode} from "@shared/models/entity-code";
import {MoneyHelper} from "@shared/services/money-helper.service";
import {InvoicingProductUnitOfMeasure} from "./invoicing-unit-of-measure";
import {sumBy} from "lodash";
import {Currency, Location, Supplier,} from './common';

const moneyHelper = new MoneyHelper();

export class DebitNote {
  id!: string;
  code?: EntityCode = new EntityCode();
  supplier?: Supplier;
  debitNoteDate!: Date;
  location?: Location;
  currency?: Currency;
  netTotal!: number;
  debitNoteStatus!: DebitNoteStatus;
  referenceNo?: string;
  lineItems: DebitNoteItem[] = [];
  paymentStatus: DebitNotePaymentStatus = DebitNotePaymentStatus.Unpaid;
  purchaseInvoiceId?: string;
  purchaseInvoiceCode?: string;
  isCancelled?: boolean;
  subTotal?: number;

  get isValid(): boolean {
    const regex = new RegExp("^[a-zA-Z0-9\\-_:\\/\\\\,\\.]+$");
    return (
      !!this.code &&
      !!this.code.fullCode &&
      regex.test(this.code.fullCode) &&
      !!this.supplier &&
      !!this.location &&
      !!this.currency &&
      !!this.debitNoteDate &&
      !!this.lineItems.length
    );
  }

  getItemsSum(propName: keyof DebitNoteItem) {
    const sum = sumBy(this.lineItems, li => +(li[propName] ?? 0));
    return moneyHelper.round(sum);
  }

  get getGrossTotal(): number {
    return this.getItemsSum("getGrossTotal");
  }

  get getLineDiscount(): number {
    return this.getItemsSum("getDiscountValue");
  }

  get getSubtotal(): number {
    return this.getItemsSum("getSubtotal");
  }

  get getTax(): number {
    return this.getItemsSum("getTax");
  }

  get getNetTotal(): number {
    return this.netTotal = this.getItemsSum("getNetTotal");
  }

  public static clone(debitNote: any): DebitNote {
    if (!debitNote) return undefined!;
    const newDebitNote: DebitNote = Object.assign(new DebitNote(), debitNote);
    newDebitNote.code = Object.assign(new EntityCode(), debitNote.code);
    newDebitNote.debitNoteDate = new Date(newDebitNote.debitNoteDate);
    newDebitNote.lineItems = [];
    for (let index = 0; index < debitNote.lineItems?.length; index++) {
      debitNote.lineItems[index].sequence = index + 1;
      newDebitNote.lineItems.push(
        DebitNoteItem.clone(debitNote.lineItems[index])
      );
    }
    return newDebitNote;
  }

  public addLineItem(lineItem: DebitNoteItem) {
    this.lineItems.unshift(lineItem);
    this.resetLineItemsSequence();
  }

  public removeLineItem(sequence: number) {
    this.lineItems.splice(sequence - 1, 1);
    this.resetLineItemsSequence();
  }

  private resetLineItemsSequence() {
    this.lineItems.forEach(function (item, index) {
      item.sequence = index + 1;
    });
  }
}

export class DebitNoteItem {
  id!: string;
  productId: string;
  productName: string;
  productDescription?: string;
  quantity: number;
  price: number;
  discount: number;
  discountType: DebitNoteLineDiscountType;
  taxRate: number;
  purchaseInvoiceItemId?: string;
  sequence?: number;
  isSelected?: boolean;
  purchaseInvoiceItemAvailableToDebit?: number;
  unitOfMeasure?: InvoicingProductUnitOfMeasure;

  productUnitOfMeasures: InvoicingProductUnitOfMeasure[] = [];
  baseUom?: InvoicingProductUnitOfMeasure;

  constructor(
    productId: string,
    productName: string,
    productdescription: string | undefined,
    quantity: number,
    price: number,
    purchaseInvoiceItemId?: string,
    purchaseInvoiceItemAvailableToDebit?: number
  ) {
    this.productId = productId;
    this.productName = productName;
    this.productDescription = productdescription;
    this.quantity = quantity;
    this.price = price;
    this.discount = 0;
    this.discountType = DebitNoteLineDiscountType.Value;
    this.taxRate = 0;
    this.purchaseInvoiceItemId = purchaseInvoiceItemId;
    this.purchaseInvoiceItemAvailableToDebit = purchaseInvoiceItemAvailableToDebit ?? 0;
  }

  get getGrossTotal(): number {
    return moneyHelper.round(this.price * this.quantity);
  }

  get getDiscountValue(): number {
    if (this.discountType === DebitNoteLineDiscountType.Value)
      return moneyHelper.round(this.discount);

    const discount = this.getGrossTotal * this.discount * 0.01;
    return moneyHelper.round(discount);
  }

  get getSubtotal(): number {
    return moneyHelper.round(this.getGrossTotal - this.getDiscountValue);
  }

  get getTax(): number {
    const tax = this.getSubtotal * this.taxRate * 0.01;
    return moneyHelper.round(tax);
  }

  get getNetTotal(): number {
    return moneyHelper.round(this.getSubtotal + this.getTax);
  }

  public static clone(
    debitNoteItem: DebitNoteItem
  ): DebitNoteItem {
    const newDebitNoteItem = new DebitNoteItem('', '', '', 0, 0);
    return Object.assign(
      newDebitNoteItem,
      debitNoteItem
    );
  }
}

export enum DebitNoteLineDiscountType {
  Value = 0,
  Percentage = 1
}

export enum DebitNotePaymentStatus {
  Unpaid = 0,
  Paid = 1,
}

export class DebitNoteQuery extends PagingQuery {
  suppliers?: string[];
  debitNoteDateStart?: Date;
  debitNoteDateEnd?: Date;
  paymentStatus?: string
}

export enum DebitNoteStatus {
  Drafted = 0,
  Submitted = 1
}
