import { EventEmitter, Injectable } from '@angular/core';
import { AccAccount } from 'src/app/shared/models/account';
import { ApiResponse } from 'src/app/shared/models/api-response';
import { Currency } from 'src/app/shared/models/currency';
import { AccountSettings, DexieDbProvider, SystemSetting, SystemSettingsEnum } from '@shared';
import { AccountingService } from './accounting.service';
import { AuthService } from '@auth';
import { CommonService } from './common.service';
import { CostType } from '@shared';
import { SystemSettingsService } from './system-settings.service';
import { WarehouseService } from '../../shared/services/warehouse.service';
import * as Sentry from "@sentry/angular";
import { EdaraNativeService } from './edara-native.service';
import { setDoc } from 'firebase/firestore';
import { environment } from 'src/environments/environment';
import { PagedList } from '@shared/models/paged-list';
import { QueryStringHelper } from '@shared/helpers/query-string.helper';
import { HttpClient } from '@angular/common/http';
import { OnlineOfflineService, FirestoreService, AppSettingsService } from '@core';
import { CorePosService } from './core-pos.service';
import { ServiceWorkerCoreService } from './service-worker-core.service';
import { PurchaseInvoiceService } from 'src/app/invoicing/services/purchase-invoice.service';
import { firstValueFrom } from 'rxjs';
import { SalesInvoicesSetting } from 'src/app/system/models/system-settings';
import { ModuleActivationService } from './module-activation.service';

@Injectable({
    providedIn: 'root'
})
export class PullDataService {

    serverDateTime?: Date;

    syncStatusChanged: EventEmitter<boolean> = new EventEmitter();
    baseUrl = '';

    constructor(
        private authService: AuthService,
        private accountingService: AccountingService,
        private warehouseService: WarehouseService,
        private posService: CorePosService,
        private serviceWorkerCore: ServiceWorkerCoreService,
        private commonService: CommonService,
        private systemSettingsService: SystemSettingsService,
        private edaraNativeService: EdaraNativeService,
        private localDbProvider: DexieDbProvider,
        private onlineOfflineService: OnlineOfflineService,
        private firestoreService: FirestoreService,
        private purchaseInvoiceService: PurchaseInvoiceService,
        private httpClient: HttpClient,
        private moduleActivationService: ModuleActivationService
    ) {
        this.baseUrl = AppSettingsService.appSettings.edaraCoreApi.baseUrl;
        if (this.baseUrl.endsWith('/')) {
            this.baseUrl = this.baseUrl.slice(0, -1);
        }
        // Sync data to indexeddb on switch tenanat
        this.authService.tenantSwitched$.subscribe({
            next: () => {
                // Request sync to load the data from the selected tenant
                setTimeout(() => this.requestSync(), 500);
            }
        });

        if (`serviceWorker` in navigator) {

            navigator.serviceWorker.addEventListener('message', event => {
                if (event.data === 'syncStarted') {
                    this.syncStatusChanged.emit(false);
                }
            });

            navigator.serviceWorker.addEventListener('message', async event => {
                if (event.data.command === 'syncCompleted') {
                    this.syncStatusChanged.emit(true);

                    if (event.data.syncLog) {
                        await this.addSyncLog(event.data.syncLog);
                    }
                }
            });

            navigator.serviceWorker.addEventListener('message', event => {
                if (event.data === 'syncSystemSettingsFinished') {
                    console.log('Sync System Settings finished.');
                } else if (event.data === 'syncAccountsFinished') {
                    console.log('Sync Accounts finished.');
                } else if (event.data === 'syncStockItemsFinished') {
                    console.log('Sync Stock Items finished.');
                } else if (event.data === 'syncStockItemSerialsFinished') {
                    console.log('Sync Stock Items Serials finished.');
                } else if (event.data === 'syncStockItemsBalancesFinished') {
                    console.log('Sync Stock Items Balances finished.');
                } else if (event.data === 'syncCustomersFinished') {
                    console.log('Sync Customers finished.');
                } else if (event.data === 'syncServiceItemsFinished') {
                    console.log('Sync service Items finished.');
                } else if (event.data === 'syncSalesPersonsFinished') {
                    console.log('Sync Sales Persons finished.');
                } else if (event.data === 'syncWarehousesFinished') {
                    console.log('Sync Warehouses finished.');
                } else if (event.data === 'syncSettingsFinished') {
                    console.log('Sync Settings finished.');
                } else if (event.data === 'syncTagsFinished') {
                    console.log('Sync Tags finished.');
                } else if (event.data === 'syncPrintTemplatesFinished') {
                    console.log('Sync Print Templates finished.');
                } else if (event.data === 'syncSalesStoresFinished') {
                    console.log('Sync Sales Stores finished.');
                } else if (event.data === 'syncSalesBundlesFinished') {
                    console.log('Sync Sales Bundles finished.');
                } else if (event.data === 'syncAssetLocationsFinished') {
                    console.log('Sync Asset Locations finished.');
                } else if (event.data === 'syncAssetUnitOfMeasuresFinished') {
                    console.log('Sync Asset Unit Of Measures finished.');
                } else if (event.data === 'syncAssetTypesFinished') {
                    console.log('Sync Asset Types finished.');
                } else if (event.data === 'syncCostTypesFinished') {
                    console.log('Sync Cost Types finished.');
                } else if (event.data === 'syncPromotionsFinished') {
                    console.log('Sync Promotions finished.');
                } else if (event.data === 'syncPosProductsFinished') {
                    console.log('Sync POS Products finished.');
                } else if (event.data === 'syncPosLocationsFinished') {
                    console.log('Sync POS Locations finished.');
                } else if (event.data === 'syncCashRegistersFinished') {
                    console.log('Sync Cash Registers finished.');
                } else if (event.data === 'syncPOSUnitOfMeasuresFinished') {
                    console.log('Sync Pos Unit Of Measures finished.');
                } else if (event.data === 'syncCurrenciesFinished') {
                    console.log('Sync Currencies finished.');
                } else if (event.data === 'syncPosTaxesFinished') {
                    console.log('Sync Pos Taxes finished.');
                } else if (event.data === 'syncPurchaseOrdersFinished') {
                    console.log('Sync Purchase Orders finished.');
                } else if (event.data === 'syncDeliveryZonesFinished') {
                    console.log('Sync Delivery Zones finished.');
                }
            });
        }
    }

    requestSync() {
        if ('serviceWorker' in navigator && 'SyncManager' in window) {
            navigator.serviceWorker.ready.then(swRegistration => swRegistration.sync.register('requestSync'))
                .catch(() => {
                    // System was unable to register for a sync, this could be an OS-level restriction
                    this.pullDataFromServer().then();
                });
        } else {
            // Serviceworker/sync not supported
            this.pullDataFromServer().then();
        }
    }

    private async addSyncLog(syncLog: any) {
        console.log('Adding sync log to cloud firestore...');

        syncLog.syncCompletedAt = new Date();
        syncLog.userInfo = {
            userId: this.authService.currentUserId,
            email: this.authService.currentUserEmail
        };
        syncLog.tenantInfo = {
            id: this.authService.currentTenant,
            name: this.authService.currentOrganization?.name
        };
        syncLog.clientInfo = {
            appVersion: environment.version,
            serviceWorkerVersion: syncLog.swVersion,
            userAgent: navigator.userAgent,
            domain: window.location.hostname
        };

        // Minify details
        if (syncLog.details?.length > 0) {
            syncLog.details.forEach((element: any) => {
                delete element.id;
                delete element.tenantId;
                delete element.serverDateTime;
            });
        }
        delete syncLog.swVersion;

        const documentRef = this.firestoreService.doc('syncLogs/' + syncLog.id);
        await setDoc(documentRef, syncLog, { merge: true });
    }

    private async pullDataFromServer() {
        const isPointOfSaleV3Enabled = await this.moduleActivationService.isPointOfSaleV3Enabled();
        if (isPointOfSaleV3Enabled)
            this.pullDataFromEdara3();
        else
            this.pullDataFromEdara2();
    }

    private async pullDataFromEdara2() {
        try {
            if (this.onlineOfflineService.isOnline) {

                // Emit syncStarted
                this.syncStatusChanged.emit(false);

                await this.getServerDateTime();

                await this.syncSettings();
                await this.syncSystemSettings();
                await this.syncCurrencies();
                await this.syncPrintTemplates();
                await this.syncCashRegisters();
                await this.syncWarehouses();
                await this.syncStockItems();

                await this.syncCustomers();
                await this.syncServiceItems();
                await this.syncSalesBundles();
                await this.syncPromotions();
                await this.syncSalesPersons();
                await this.syncTags();
                await this.syncStockItemsBalances();
                await this.syncStockItemSerials();
                await this.syncAccounts();
                await this.syncAssetUnitOfMeasures();
                await this.syncAssetLocations();
                await this.syncAssetTypes();
                await this.syncCostTypes();
                await this.syncEdara3Currencies();
                await this.syncPosUnitOfMeasures();
                // await syncSalesStores();

                // await this.syncPurchaseOrders();

                // Emit syncCompleted
                this.syncStatusChanged.emit(true);
            }
        }
        catch (err: any) {
            // Emit syncCompleted
            this.syncStatusChanged.emit(true);
        }
    }

    private async pullDataFromEdara3() {
        try {
            if (this.onlineOfflineService.isOnline) {

                // Emit syncStarted
                this.syncStatusChanged.emit(false);

                await this.getServerDateTime();

                await this.syncSystemSettings();
                await this.syncCashRegisters();
                await this.syncPosTaxes();
                await this.syncPosProducts();
                await this.syncCustomersV3();
                await this.syncPosLocations();
                await this.syncPromotions();
                await this.syncDeliveryZones();

                await this.syncAssetUnitOfMeasures();
                await this.syncAssetLocations();
                await this.syncAssetTypes();
                await this.syncCostTypes();
                await this.syncEdara3Currencies();
                await this.syncPosUnitOfMeasures();

                // await this.syncPurchaseOrders();

                // Emit syncCompleted
                this.syncStatusChanged.emit(true);
            }
        }
        catch (err: any) {
            // Emit syncCompleted
            this.syncStatusChanged.emit(true);
        }
    }

    private async syncAccounts() {
        try {
            console.log('Syncing Accounts ...\n');

            let response: ApiResponse<AccAccount[]> | undefined;
            let totalRowsCount = 1000;
            let query = await this.buildQueryParameters(EntityType.Account);

            do {
                console.log('Fetching Accounts ...');
                let startDate = new Date();
                response = await this.accountingService.getAllAccountsAsync(query);
                let endDate = new Date();

                if (response && response.status_code === 200) {
                    console.log(`Fetched records from ${query.offset} to ${query.offset + query.limit - 1}`);
                    console.log(`It took ${(endDate.getTime() - startDate.getTime())} ms.\n`);
                    totalRowsCount = response.total_count;
                    query.offset += query.limit;

                    console.log(`Saving ${response.result.length} Accounts to IndexedDb ...`);
                    startDate = new Date();
                    await this.accountingService.addAccountsToLocalDb(response.result);
                    endDate = new Date();
                    console.log(`It took ${(endDate.getTime() - startDate.getTime())} ms.\n`);
                } else if (response && response.status_code !== 404) {
                    response.error_message = response.error_message ?? `Sync Accounts failed: ${JSON.stringify(response)}`;
                    throw new Error(response.error_message);
                }

            } while (response && response.status_code === 200 && totalRowsCount > query.offset);

            this.updateSyncStatus('Account', 200, 'Sync completed successfully');

            console.log('Sync Accounts finished.');
        }
        catch (err: any) {
            Sentry.captureException(err);
            this.updateSyncStatus('Account', 500, 'Sync Accounts failed: ' + err.message);
            return Promise.reject('Sync Accounts failed.');
        }
    }

    private async syncCurrencies() {
        try {
            console.log('Syncing Currencies ...\n');

            let response: ApiResponse<Currency[]> | undefined;
            let totalRowsCount = 1000;
            let query = await this.buildQueryParameters(EntityType.Currency);

            do {
                console.log('Fetching Currencies ...');
                let startDate = new Date();
                response = await this.accountingService.getCurrenciesAsync();
                let endDate = new Date();

                if (response && response.status_code === 200) {
                    console.log(`Fetched records from ${query.offset} to ${query.offset + query.limit - 1}`);
                    console.log(`It took ${(endDate.getTime() - startDate.getTime())} ms.\n`);
                    totalRowsCount = response.total_count;
                    query.offset += query.limit;

                    console.log(`Saving ${response.result.length} Currencies to IndexedDb ...`);
                    startDate = new Date();
                    await this.accountingService.addCurrenciesToLocalDb(response.result);
                    endDate = new Date();
                    console.log(`It took ${(endDate.getTime() - startDate.getTime())} ms.\n`);
                } else if (response && response.status_code !== 404) {
                    response.error_message = response.error_message ?? `Sync Currencies failed: ${JSON.stringify(response)}`;
                    throw new Error(response.error_message);
                }

            } while (response && response.status_code === 200 && totalRowsCount > query.offset);

            this.updateSyncStatus('Currency', 200, 'Sync completed successfully');

            console.log('syncCurrenciesFinished');
        }
        catch (err: any) {
            Sentry.captureException(err);
            this.updateSyncStatus('Currency', 500, 'Sync Currencies failed: ' + err.message);
            return Promise.reject('Sync Currencies failed.');
        }
    }

    private async syncEdara3Currencies() {
        try {
            console.log('Syncing Edara3 Currencies ...\n');

            let response: Currency[] | undefined;
            console.log('Fetching Edara3 Currencies ...');
            let startDate = new Date();
            response = await this.commonService.getCurrenciesAsync();
            let endDate = new Date();

            if (response && response.length > 0) {
                console.log(`Fetched records from server`);
                console.log(`It took ${(endDate.getTime() - startDate.getTime())} ms.\n`);

                console.log(`Saving ${response.length} Edara3 Currencies to IndexedDb ...`);
                startDate = new Date();
                await this.commonService.addCurrenciesToLocalDb(response);
                endDate = new Date();
                console.log(`It took ${(endDate.getTime() - startDate.getTime())} ms.\n`);
            }
            else
                throw new Error(`Sync Edara3 Currencies failed`);

            this.updateSyncStatus('Edara3 Currency', 200, 'Sync completed successfully');

            console.log('syncEdara3CurrenciesFinished');
        }
        catch (err: any) {
            Sentry.captureException(err);
            this.updateSyncStatus('Edara3 Currency', 500, 'Sync Edara3 Currencies failed: ' + err.message);
            return Promise.reject('Sync Edara3 Currencies failed.');
        }
    }

    private async syncStockItems() {
        try {
            console.log('Syncing Stock Items ...\n');

            let response = null;
            let totalRowsCount = 1000;
            let query = await this.buildQueryParameters(EntityType.StockItem);

            do {
                console.log('Fetching Stock Items ...');
                let startDate = new Date();
                response = await this.warehouseService.getStockItemsAsync(query);
                let endDate = new Date();

                if (response && response.status_code === 200) {
                    console.log(`Fetched records from ${query.offset} to ${query.offset + query.limit - 1}`);
                    console.log(`It took ${(endDate.getTime() - startDate.getTime())} ms.\n`);
                    totalRowsCount = response.total_count;
                    query.offset += query.limit;

                    console.log(`Saving ${response.result.length} Stock Items to IndexedDb ...`);
                    startDate = new Date();
                    this.warehouseService.addStockItemsToLocalDb(response.result);
                    endDate = new Date();
                    console.log(`It took ${(endDate.getTime() - startDate.getTime())} ms.\n`);
                } else if (response && response.status_code !== 404) {
                    response.error_message = response.error_message ?? `Sync Stock Items failed: ${JSON.stringify(response)}`;
                    throw new Error(response.error_message);
                }

            } while (response && response.status_code === 200 && totalRowsCount > query.offset);

            this.updateSyncStatus('StockItem', 200, 'Sync completed successfully');

            console.log('Sync Stock Items finished.');
        }
        catch (err: any) {
            Sentry.captureException(err);
            this.updateSyncStatus('StockItem', 500, 'Sync Stock Items failed: ' + err.message);
            return Promise.reject('Sync Stock Items failed.');
        }
    }

    private async syncStockItemsBalances() {
        try {
            console.log('Syncing Stock Items Balances ...\n');

            let response = null;
            let totalRowsCount = 1000;
            let query = {
                offset: 0,
                limit: 1000
            };

            do {
                console.log('Fetching Stock Items Balances ...');
                let startDate = new Date();
                response = await this.warehouseService.getStockItemsBalancesAsync(query);
                let endDate = new Date();

                if (response && response.status_code === 200) {
                    console.log(`Fetched records from ${query.offset} to ${query.offset + query.limit - 1}`);
                    console.log(`It took ${(endDate.getTime() - startDate.getTime())} ms.\n`);
                    totalRowsCount = response.total_count;
                    query.offset += query.limit;

                    console.log(`Saving ${response.result.length} Stock Items Balances to IndexedDb ...`);
                    startDate = new Date();
                    await this.warehouseService.addStockItemsBalancesToLocalDb(response.result);
                    endDate = new Date();
                    console.log(`It took ${(endDate.getTime() - startDate.getTime())} ms.\n`);
                } else if (response && response.status_code !== 404) {
                    response.error_message = response.error_message ?? `Sync Stock Items Balances failed: ${JSON.stringify(response)}`;
                    throw new Error(response.error_message);
                }

            } while (response && response.status_code === 200 && totalRowsCount > query.offset);

            this.updateSyncStatus(EntityType.WarehouseStock, 200, 'Sync completed successfully');

            console.log('Sync Stock Items Balances finished.');
        }
        catch (err: any) {
            Sentry.captureException(err);
            this.updateSyncStatus(EntityType.WarehouseStock, 500, 'Sync Stock Items Balances failed: ' + err.message);
            return Promise.reject('Sync Stock Items Balances failed.');
        }
    }

    private async syncStockItemSerials() {
        try {
            console.log('Syncing Stock Items Serials ...\n');
            this.localDbProvider.db.stockItemSerials.clear();
            let response = null;
            let totalRowsCount = 1000;
            let query = await this.buildQueryParameters(EntityType.StockItemSerial);
            const unsyncedSalesOrders = await this.posService.getAllUnsyncedSalesOrders();

            do {
                console.log('Fetching Stock Items Serials...');
                let startDate = new Date();
                response = await this.warehouseService.getStockItemSerialsAsync(query);
                let endDate = new Date();

                if (response && response.status_code === 200) {
                    console.log(`Fetched records from ${query.offset} to ${query.offset + query.limit - 1}`);
                    console.log(`It took ${(endDate.getTime() - startDate.getTime())} ms.\n`);
                    totalRowsCount = response.total_count;
                    query.offset += query.limit;

                    // Ignore serials that are already in use by pending orders
                    this.warehouseService.deductOfflineSerials(unsyncedSalesOrders, response.result);

                    console.log(`Saving ${response.result.length} Stock Items Serials to IndexedDb ...`);
                    startDate = new Date();
                    await this.warehouseService.addStockItemSerialsToLocalDb(response.result);
                    endDate = new Date();
                    console.log(`It took ${(endDate.getTime() - startDate.getTime())} ms.\n`);
                } else if (response && response.status_code !== 404) {
                    response.error_message = response.error_message ?? `Sync Stock Item Serials failed: ${JSON.stringify(response)}`;
                    throw new Error(response.error_message);
                }

            } while (response && response.status_code === 200 && totalRowsCount > query.offset);

            this.updateSyncStatus('StockItemSerials', 200, 'Sync completed successfully');

            console.log('Sync Stock Items Serials finished.');
        }
        catch (err: any) {
            Sentry.captureException(err);
            this.updateSyncStatus('StockItemSerials', 500, 'Sync Stock Items Serials failed: ' + err.message);
            return Promise.reject('Sync Stock Item Serials failed.');
        }
    }

    private async syncWarehouses() {
        try {
            console.log('Syncing Warehouses ...\n');

            let response = null;
            let totalRowsCount = 1000;
            let query = await this.buildQueryParameters(EntityType.Warehouse);

            do {
                console.log('Fetching Warehouses ...');
                let startDate = new Date();
                response = await this.warehouseService.getWarehousesAsync(query)
                let endDate = new Date();

                if (response && response.status_code === 200) {
                    console.log(`Fetched records from ${query.offset} to ${query.offset + query.limit - 1}`);
                    console.log(`It took ${(endDate.getTime() - startDate.getTime())} ms.\n`);
                    totalRowsCount = response.total_count;
                    query.offset += query.limit;

                    console.log(`Saving ${response.result.length} Warehouses to IndexedDb ...`);
                    startDate = new Date();
                    await this.warehouseService.addWarehousesToLocalDb(response.result);
                    endDate = new Date();
                    console.log(`It took ${(endDate.getTime() - startDate.getTime())} ms.\n`);
                } else if (response && response.status_code !== 404) {
                    response.error_message = response.error_message ?? `Sync Warehouses failed: ${JSON.stringify(response)}`;
                    throw new Error(response.error_message);
                }

            } while (response && response.status_code === 200 && totalRowsCount > query.offset);

            this.updateSyncStatus('Warehouse', 200, 'Sync completed successfully');
            console.log('Sync Warehouses finished.');
        }
        catch (err: any) {
            Sentry.captureException(err);
            this.updateSyncStatus('Warehouse', 500, 'Sync Warehouses failed: ' + err.message);
            return Promise.reject('Sync Warehouses failed.');
        }
    }

    private async syncCustomers() {
        try {
            console.log('Syncing Customers ...\n');

            let response = null;
            let totalRowsCount = 1000;
            let query = await this.buildQueryParameters(EntityType.Customer);

            do {
                console.log('Fetching Customers ...');
                let startDate = new Date();
                response = await this.posService.getCustomersAsync(query)
                let endDate = new Date();

                if (response && response.status_code === 200) {
                    console.log(`Fetched records from ${query.offset} to ${query.offset + query.limit - 1}`);
                    console.log(`It took ${(endDate.getTime() - startDate.getTime())} ms.\n`);
                    totalRowsCount = response.total_count;
                    query.offset += query.limit;

                    console.log(`Saving ${response.result.length} Customers to IndexedDb ...`);
                    startDate = new Date();
                    await this.posService.addCustomersToLocalDb(response.result);
                    endDate = new Date();
                    console.log(`It took ${(endDate.getTime() - startDate.getTime())} ms.\n`);
                } else if (response && response.status_code !== 404) {
                    response.error_message = response.error_message ?? `Sync Customers failed: ${JSON.stringify(response)}`;
                    throw new Error(response.error_message);
                }

            } while (response && response.status_code === 200 && totalRowsCount > query.offset);

            this.updateSyncStatus('Customer', 200, 'Sync completed successfully');
            console.log('Sync Customers finished.');
        }
        catch (err: any) {
            Sentry.captureException(err);
            this.updateSyncStatus('Customer', 500, 'Sync Customers failed: ' + err.message);
            return Promise.reject('Sync Customers failed.');
        }
    }

    private async syncServiceItems() {
        try {
            console.log('Syncing Service Items ...\n');

            let response = null;
            let totalRowsCount = 1000;
            let query = await this.buildQueryParameters(EntityType.ServiceItem);

            do {
                console.log('Fetching Service Items ...');
                let startDate = new Date();
                response = await this.posService.getServiceItemsAsync(query);
                let endDate = new Date();

                if (response && response.status_code === 200) {
                    console.log(`Fetched records from ${query.offset} to ${query.offset + query.limit - 1}`);
                    console.log(`It took ${(endDate.getTime() - startDate.getTime())} ms.\n`);
                    totalRowsCount = response.total_count;
                    query.offset += query.limit;

                    console.log(`Saving ${response.result.length} Service Items to IndexedDb ...`);
                    startDate = new Date();
                    await this.posService.addServiceItemsToLocalDb(response.result);
                    endDate = new Date();
                    console.log(`It took ${(endDate.getTime() - startDate.getTime())} ms.\n`);
                } else if (response && response.status_code !== 404) {
                    response.error_message = response.error_message ?? `Sync Service Items failed: ${JSON.stringify(response)}`;
                    throw new Error(response.error_message);
                }

            } while (response && response.status_code === 200 && totalRowsCount > query.offset);

            this.updateSyncStatus('ServiceItem', 200, 'Sync completed successfully');
            console.log('Sync service Items finished.');
        }
        catch (err: any) {
            Sentry.captureException(err);
            this.updateSyncStatus('ServiceItem', 500, 'Sync Service Items failed: ' + err.message);
            return Promise.reject('Sync Service Items failed.');
        }
    }

    private async syncSalesPersons() {
        try {
            console.log('Syncing Sales Persons ...\n');

            let response = null;
            let totalRowsCount = 1000;
            let query = await this.buildQueryParameters(EntityType.SalesPerson);

            do {
                console.log('Fetching Sales Persons ...');
                let startDate = new Date();
                response = await this.posService.getSalesPersonsAsync(query);
                let endDate = new Date();

                if (response && response.status_code === 200) {
                    console.log(`Fetched records from ${query.offset} to ${query.offset + query.limit - 1}`);
                    console.log(`It took ${(endDate.getTime() - startDate.getTime())} ms.\n`);
                    totalRowsCount = response.total_count;
                    query.offset += query.limit;

                    console.log(`Saving ${response.result.length} Sales Persons to IndexedDb ...`);
                    startDate = new Date();
                    await this.posService.addSalesPersonsToLocalDb(response.result);
                    endDate = new Date();
                    console.log(`It took ${(endDate.getTime() - startDate.getTime())} ms.\n`);
                } else if (response && response.status_code !== 404) {
                    response.error_message = response.error_message ?? `Sync Sales Persons failed: ${JSON.stringify(response)}`;
                    throw new Error(response.error_message);
                }

            } while (response && response.status_code === 200 && totalRowsCount > query.offset);

            this.updateSyncStatus('SalesPerson', 200, 'Sync completed successfully');
            console.log('Sync Sales Persons finished.');
        }
        catch (err: any) {
            Sentry.captureException(err);
            this.updateSyncStatus('SalesPerson', 500, 'Sync Sales Persons failed: ' + err.message);
            return Promise.reject('Sync Sales Persons failed.');
        }
    }

    private async syncSettings() {
        try {
            console.log('Syncing Settings ...\n');

            let response = null;
            let totalRowsCount = 1000;
            let query = await this.buildQueryParameters(EntityType.Setting);

            do {
                console.log('Fetching Settings ...');
                let startDate = new Date();
                response = await this.commonService.getSettingsAsync(query);
                let endDate = new Date();

                if (response && response.status_code === 200) {
                    console.log(`Fetched records from ${query.offset} to ${query.offset + query.limit - 1}`);
                    console.log(`It took ${(endDate.getTime() - startDate.getTime())} ms.\n`);
                    totalRowsCount = response.total_count;
                    query.offset += query.limit;

                    console.log(`Saving ${response.result.length} Settings to IndexedDb ...`);
                    startDate = new Date();
                    await this.commonService.addSettingsToLocalDb(response.result);
                    endDate = new Date();
                    console.log(`It took ${(endDate.getTime() - startDate.getTime())} ms.\n`);
                } else if (response && response.status_code !== 404) {
                    response.error_message = response.error_message ?? `Sync Settings failed: ${JSON.stringify(response)}`;
                    throw new Error(response.error_message);
                }

            } while (response && response.status_code === 200 && totalRowsCount > query.offset);

            this.updateSyncStatus('Setting', 200, 'Sync completed successfully');
            console.log('Sync Settings finished.');
        }
        catch (err: any) {
            Sentry.captureException(err);
            this.updateSyncStatus('Setting', 500, 'Sync Settings failed: ' + err.message);
            return Promise.reject('Sync Settings failed.');
        }
    }

    private async syncPrintTemplates() {
        try {
            console.log('Syncing Print Templates ...\n');

            let response = null;
            let totalRowsCount = 1000;
            let query = await this.buildQueryParameters(EntityType.PrintTemplate);

            do {
                console.log('Fetching Print Templates ...');
                let startDate = new Date();
                response = await this.posService.getPrintTemplatesAsync(query);
                let endDate = new Date();

                if (response && response.status_code === 200) {
                    console.log(`Fetched records from ${query.offset} to ${query.offset + query.limit - 1}`);
                    console.log(`It took ${(endDate.getTime() - startDate.getTime())} ms.\n`);
                    totalRowsCount = response.total_count;
                    query.offset += query.limit;

                    console.log(`Saving ${response.result.length} Print Templates to IndexedDb ...`);
                    startDate = new Date();
                    await this.posService.addPrintTemplatesToLocalDb(response.result);
                    endDate = new Date();
                    console.log(`It took ${(endDate.getTime() - startDate.getTime())} ms.\n`);
                } else if (response && response.status_code !== 404) {
                    response.error_message = response.error_message ?? `Sync Print Templates failed: ${JSON.stringify(response)}`;
                    throw new Error(response.error_message);
                }

            } while (response && response.status_code === 200 && totalRowsCount > query.offset);

            this.updateSyncStatus('PrintTemplate', 200, 'Sync completed successfully');
            console.log('Sync Print Templates finished.');
        }
        catch (err: any) {
            Sentry.captureException(err);
            this.updateSyncStatus('PrintTemplate', 500, 'Sync Print Templates failed: ' + err.message);
            return Promise.reject('Sync Print Templates failed.');
        }
    }

    private async syncSalesStores() {
        try {
            console.log('Syncing Sales Stores ...\n');

            let response = null;
            let totalRowsCount = 1000;
            let query = await this.buildQueryParameters(EntityType.SalesStore);

            do {
                console.log('Fetching Sales Stores ...');
                let startDate = new Date();
                response = await this.posService.getSalesStoresAsync(query);
                let endDate = new Date();

                if (response && response.status_code === 200) {
                    console.log(`Fetched records from ${query.offset} to ${query.offset + query.limit - 1}`);
                    console.log(`It took ${(endDate.getTime() - startDate.getTime())} ms.\n`);
                    totalRowsCount = response.total_count;
                    query.offset += query.limit;

                    console.log(`Saving ${response.result.length} Sales Stores to IndexedDb ...`);
                    startDate = new Date();
                    await this.posService.addSalesStoresToLocalDb(response.result);
                    endDate = new Date();
                    console.log(`It took ${(endDate.getTime() - startDate.getTime())} ms.\n`);
                } else if (response && response.status_code !== 404) {
                    response.error_message = response.error_message ?? `Sync Sales Stores failed: ${JSON.stringify(response)}`;
                    throw new Error(response.error_message);
                }

            } while (response && response.status_code === 200 && totalRowsCount > query.offset);

            this.updateSyncStatus('SalesStore', 200, 'Sync completed successfully');
            console.log('Sync Sales Stores finished.');
        }
        catch (err: any) {
            Sentry.captureException(err);
            this.updateSyncStatus('SalesStore', 500, 'Sync Sales Stores failed: ' + err.message);
            return Promise.reject('Sync Sales Stores failed.');
        }
    }

    private async syncSalesBundles() {
        try {
            console.log('Syncing Sales Bundles ...\n');

            let response = null;
            let totalRowsCount = 1000;
            let query = await this.buildQueryParameters(EntityType.SalesBundle);

            do {
                console.log('Fetching Sales Bundles ...');
                let startDate = new Date();
                response = await this.posService.getSalesBundlesAsync(query);
                let endDate = new Date();

                if (response && response.status_code === 200) {
                    console.log(`Fetched records from ${query.offset} to ${query.offset + query.limit - 1}`);
                    console.log(`It took ${(endDate.getTime() - startDate.getTime())} ms.\n`);
                    totalRowsCount = response.total_count;
                    query.offset += query.limit;

                    console.log(`Saving ${response.result.length} Sales Bundles to IndexedDb ...`);
                    startDate = new Date();
                    await this.posService.addSalesBundlesToLocalDb(response.result);
                    endDate = new Date();
                    console.log(`It took ${(endDate.getTime() - startDate.getTime())} ms.\n`);
                } else if (response && response.status_code !== 404) {
                    response.error_message = response.error_message ?? `Sync Sales Bundles failed: ${JSON.stringify(response)}`;
                    throw new Error(response.error_message);
                }

            } while (response && response.status_code === 200 && totalRowsCount > query.offset);

            this.updateSyncStatus('SalesBundle', 200, 'Sync completed successfully.');
            console.log('Sync Sales Bundles finished.');
        }
        catch (err: any) {
            Sentry.captureException(err);
            this.updateSyncStatus('SalesBundle', 500, 'Sync Sales Bundles failed: ' + err.message);
            return Promise.reject('Sync Sales Bundles failed.');
        }
    }

    private async syncTags() {
        try {
            console.log('Syncing Tags ...\n');

            let response = null;
            let totalRowsCount = 1000;
            let query = await this.buildQueryParameters(EntityType.Tag);

            do {
                console.log('Fetching Tags ...');
                let startDate = new Date();
                response = await this.posService.getTagsAsync(query);
                let endDate = new Date();

                if (response && response.status_code === 200) {
                    console.log(`Fetched records from ${query.offset} to ${query.offset + query.limit - 1}`);
                    console.log(`It took ${(endDate.getTime() - startDate.getTime())} ms.\n`);
                    totalRowsCount = response.total_count;
                    query.offset += query.limit;

                    console.log(`Saving ${response.result.length} Tags to IndexedDb ...`);
                    startDate = new Date();
                    await this.posService.addTagsToLocalDb(response.result);
                    endDate = new Date();
                    console.log(`It took ${(endDate.getTime() - startDate.getTime())} ms.\n`);
                } else if (response && response.status_code !== 404) {
                    response.error_message = response.error_message ?? `Sync Tags failed: ${JSON.stringify(response)}`;
                    throw new Error(response.error_message);
                }

            } while (response && response.status_code === 200 && totalRowsCount > query.offset);

            this.updateSyncStatus('Tag', 200, 'Sync completed successfully');

            console.log('Sync Tags finished.');
        }
        catch (err: any) {
            Sentry.captureException(err);
            this.updateSyncStatus('Tag', 500, 'Sync Tags failed: ' + err.message);
            return Promise.reject('Sync Tags failed.');
        }
    }

    private async syncSystemSettings() {
        try {
            console.log('Syncing System Settings ...\n');

            let response = null;
            let totalRowsCount = 1000;
            let query = {
                offset: 0,
                limit: 1000,
            };

            do {
                console.log('Fetching System Settings ...');
                let startDate = new Date();
                response = await this.systemSettingsService.findSystemSettingsAsync(query);

                let endDate = new Date();

                if (response && response.items) {
                    console.log(`Fetched records from ${query.offset} to ${query.offset + query.limit - 1}`);
                    console.log(`It took ${(endDate.getTime() - startDate.getTime())} ms.\n`);
                    totalRowsCount = response.totalCount;
                    query.offset += query.limit;

                    console.log(`Saving ${response.items.length} System Settings to IndexedDb ...`);
                    const settings = await this.initDefaultTaxSettings(response);
                    startDate = new Date();
                    await this.systemSettingsService.addSystemSettingsToLocalDb(settings.items);
                    endDate = new Date();
                    console.log(`It took ${(endDate.getTime() - startDate.getTime())} ms.\n`);
                }

            } while (response && totalRowsCount > query.offset);

            this.updateSyncStatus('SystemSetting', 200, 'Sync completed successfully');
            console.log('Sync System Settings finished.');
        }
        catch (err: any) {
            Sentry.captureException(err);
            this.updateSyncStatus('SystemSetting', 500, 'Sync System Settings failed: ' + err.message);
            return Promise.reject('Sync System Settings failed.');
        }
    }

    private async syncAssetUnitOfMeasures() {
        try {
            console.log('Syncing Asset Unit Of Measures ...\n');

            let response = null;
            let totalRowsCount = 1000;
            let query = await this.buildQueryParameters(EntityType.AssetUnitOfMeasures);

            do {
                console.log('Fetching Asset Unit Of Measures ...');
                let startDate = new Date();
                response = await this.serviceWorkerCore.findUnitOfMeasuresAsync(query);
                let endDate = new Date();

                if (response && response.items) {
                    console.log(`Fetched records from ${query.offset} to ${query.offset + query.limit - 1}`);
                    console.log(`It took ${(endDate.getTime() - startDate.getTime())} ms.\n`);
                    totalRowsCount = response.totalCount;
                    query.offset += query.limit;

                    console.log(`Saving ${response.items.length} Asset Unit Of Measures to IndexedDb ...`);
                    startDate = new Date();
                    await this.serviceWorkerCore.addUnitOfMeasuresToLocalDb(response.items);
                    endDate = new Date();
                    console.log(`It took ${(endDate.getTime() - startDate.getTime())} ms.\n`);
                }

            } while (response && totalRowsCount > query.offset);

            this.updateSyncStatus('AssetUnitOfMeasures', 200, 'Sync completed successfully');
            console.log('Sync Asset Unit Of Measures finished.');
        }
        catch (err: any) {
            Sentry.captureException(err);
            this.updateSyncStatus('AssetUnitOfMeasures', 500, 'Sync Asset Unit Of Measures failed: ' + err.message);
            return Promise.reject('Sync Fixed Asset Unit of Measures failed.');
        }
    }

    private async syncAssetLocations() {
        try {
            console.log('Syncing Asset Locations ...\n');

            let response = null;
            let totalRowsCount = 1000;
            let query = await this.buildQueryParameters(EntityType.AssetLocations);

            do {
                console.log('Fetching Asset Locations ...');
                let startDate = new Date();
                response = await this.serviceWorkerCore.findAssetLocationsAsync(query);
                let endDate = new Date();

                if (response && response.items) {
                    console.log(`Fetched records from ${query.offset} to ${query.offset + query.limit - 1}`);
                    console.log(`It took ${(endDate.getTime() - startDate.getTime())} ms.\n`);
                    totalRowsCount = response.totalCount;
                    query.offset += query.limit;

                    console.log(`Saving ${response.items.length} Asset Locations to IndexedDb ...`);
                    startDate = new Date();
                    await this.serviceWorkerCore.addAssetLocationsToLocalDb(response.items);
                    endDate = new Date();
                    console.log(`It took ${(endDate.getTime() - startDate.getTime())} ms.\n`);
                }

            } while (response && totalRowsCount > query.offset);

            this.updateSyncStatus('AssetLocations', 200, 'Sync completed successfully');
            console.log('Sync Asset Locations finished.');
        }
        catch (err: any) {
            Sentry.captureException(err);
            this.updateSyncStatus('AssetLocations', 500, 'Sync Asset Locations failed: ' + err.message);
            return Promise.reject('Sync Fixed Asset Locations failed.');

        }
    }

    private async syncAssetTypes() {
        try {
            console.log('Syncing Asset Types ...\n');

            let response = null;
            let totalRowsCount = 1000;
            let query = await this.buildQueryParameters(EntityType.AssetTypes);

            do {
                console.log('Fetching Asset Types ...');
                let startDate = new Date();
                response = await this.serviceWorkerCore.findAssetTypessAsync(query);

                let endDate = new Date();

                if (response && response.items) {
                    console.log(`Fetched records from ${query.offset} to ${query.offset + query.limit - 1}`);
                    console.log(`It took ${(endDate.getTime() - startDate.getTime())} ms.\n`);
                    totalRowsCount = response.totalCount;
                    query.offset += query.limit;

                    console.log(`Saving ${response.items.length} Asset Types to IndexedDb ...`);
                    startDate = new Date();
                    await this.serviceWorkerCore.addAssetsTypesToLocalDb(response.items);
                    endDate = new Date();
                    console.log(`It took ${(endDate.getTime() - startDate.getTime())} ms.\n`);
                }

            } while (response && totalRowsCount > query.offset);

            this.updateSyncStatus('AssetTypes', 200, 'Sync completed successfully');
            console.log('Sync Asset Types finished.');
        }
        catch (err: any) {
            Sentry.captureException(err);
            this.updateSyncStatus('AssetTypes', 500, 'Sync Asset Types failed: ' + err.message);
            return Promise.reject('Sync Fixed Assets failed.');
        }
    }

    private async syncCostTypes() {
        try {
            console.log('Syncing Cost Types ...\n');

            let response = null;
            let totalRowsCount = 1000;
            let query = await this.buildQueryParameters(EntityType.CostType);

            do {
                console.log('Fetching Cost Types ...');
                let startDate = new Date();
                response = await this.findCostTypesAsync(query);

                let endDate = new Date();

                if (response) {
                    console.log(`Fetched records from ${query.offset} to ${query.offset + query.limit - 1}`);
                    console.log(`It took ${(endDate.getTime() - startDate.getTime())} ms.\n`);
                    totalRowsCount = response.totalCount;
                    query.offset += query.limit;

                    console.log(`Saving ${response.items.length} Cost Types to IndexedDb ...`);
                    startDate = new Date();
                    await this.addCostTypesToLocalDb(response.items);
                    endDate = new Date();
                    console.log(`It took ${(endDate.getTime() - startDate.getTime())} ms.\n`);
                }

            } while (response && totalRowsCount > query.offset);

            this.updateSyncStatus('MFG_CostTypes', 200, 'Sync completed successfully');
            console.log('Sync Cost Types finished.');
        }
        catch (err: any) {
            Sentry.captureException(err);
            this.updateSyncStatus('MFG_CostTypes', 500, 'Sync Cost Types failed: ' + err.message);
            return Promise.reject('Sync Cost Types failed.');
        }
    }

    async syncPromotions() {
        try {
            console.log('Syncing Promotions ...\n');

            let response = null;
            let totalRowsCount = 1000;
            let query = await this.buildQueryParameters(EntityType.Promotion);

            await this.posService.clearPromotionsFromLocalDb();

            do {
                console.log('Fetching Promotions ...');
                let startDate = new Date();
                response = await this.posService.findPromotionsAsync(query);
                let endDate = new Date();

                if (response) {
                    console.log(`Fetched records from ${query.offset} to ${query.offset + query.limit - 1}`);
                    console.log(`It took ${(endDate.getTime() - startDate.getTime())} ms.\n`);
                    totalRowsCount = response.totalCount;
                    query.offset += query.limit;

                    console.log(`Saving ${response.items.length} Promotions to IndexedDb ...`);
                    startDate = new Date();
                    await this.posService.addPromotionsToLocalDb(response.items);
                    endDate = new Date();
                    console.log(`It took ${(endDate.getTime() - startDate.getTime())} ms.\n`);
                }

            } while (response && totalRowsCount > query.offset);

            this.updateSyncStatus('POS_Promotions', 200, 'Sync completed successfully');
            console.log('Sync Promotions finished.');
        }
        catch (err: any) {
            Sentry.captureException(err);
            this.updateSyncStatus('POS_Promotions', 500, 'Sync Promotions failed: ' + err.message);
            return Promise.reject('Sync Promotions failed.');
        }
    }

    private async syncPosTaxes() {
        try {
            console.log('Syncing Pos Taxes ...\n');

            let response = null;
            let totalRowsCount = 1000;
            const lastSyncMetadata = await this.getLastSyncMetadata(EntityType.POS_Taxes);
            let query = {
                offset: 0,
                limit: 1000,
                updatedAtMin: (lastSyncMetadata ? lastSyncMetadata.serverDateTime : '')
            };

            do {
                console.log('Fetching Pos Taxes ...');
                let startDate = new Date();
                response = await this.posService.findTaxesAsync(query);
                let endDate = new Date();

                if (response && response.items) {
                    console.log(`Fetched records from ${query.offset} to ${query.offset + query.limit - 1}`);
                    console.log(`It took ${(endDate.getTime() - startDate.getTime())} ms.\n`);
                    totalRowsCount = response.totalCount;
                    query.offset += query.limit;

                    console.log(`Saving ${response.items.length} Pos Taxes to IndexedDb ...`);
                    startDate = new Date();
                    await this.posService.addTaxesToLocalDb(response.items);
                    endDate = new Date();
                    console.log(`It took ${(endDate.getTime() - startDate.getTime())} ms.\n`);
                }

            } while (response && totalRowsCount > query.offset);

            this.updateSyncStatus(EntityType.POS_Taxes, 200, 'Sync completed successfully');
            console.log('Sync Pos Taxes finished.');
        }
        catch (err: any) {
            Sentry.captureException(err);
            this.updateSyncStatus(EntityType.POS_Taxes, 500, 'Sync Pos Taxes failed: ' + err.message);
            return Promise.reject('Sync Pos Taxes failed.');
        }
    }


    async syncCashRegisters() {
        try {
            console.log('Syncing CashRegisters ...\n');

            const localCashRegister = await this.edaraNativeService.getCashRegistersByUserEmailAsync(this.authService.currentUserEmail);

            let response = null;

            let query = {
                activatedUser: this.authService.currentUserEmail,
                active: true
            };

            await this.posService.clearCashRegistersFromLocalDb();

            console.log('Fetching CashRegisters ...');
            let startDate = new Date();
            response = await this.posService.findCashRegistersAsync(query);
            let endDate = new Date();

            if (response) {
                console.log(`It took ${(endDate.getTime() - startDate.getTime())} ms.\n`);

                console.log(`Saving ${response.items.length} CashRegisters to IndexedDb ...`);
                startDate = new Date();
                await this.posService.addCashRegistersToLocalDb(response.items);
                if (response.items.length && response.items[0].id === localCashRegister?.id) {
                    await this.edaraNativeService.updateCashRegisterLocal(response.items);
                } else {
                    localCashRegister!.active = false;
                    await this.edaraNativeService.unpairUserFromCashRegisterLocal(localCashRegister);
                }
                endDate = new Date();
                console.log(`It took ${(endDate.getTime() - startDate.getTime())} ms.\n`);
            }

            this.updateSyncStatus(EntityType.CashRegister, 200, 'Sync completed successfully');
            console.log('Sync CashRegisters finished.');
        }
        catch (err: any) {
            Sentry.captureException(err);
            this.updateSyncStatus(EntityType.CashRegister, 500, 'Sync CashRegisters failed: ' + err.message);
            return Promise.reject('Sync CashRegisters failed.');
        }
    }

    private async syncPosProducts() {
        try {
            console.log('Syncing POS Products ...\n');

            let response = null;
            let totalRowsCount = 1000;
            const lastSyncMetadata = await this.getLastSyncMetadata(EntityType.POS_Product);
            let query = {
                offset: 0,
                limit: 1000,
                updatedAtMin: (lastSyncMetadata ? lastSyncMetadata.serverDateTime : '')
            };

            do {
                console.log('Fetching POS Products ...');
                let startDate = new Date();
                response = await this.posService.findPosProductsAsync(query);
                let endDate = new Date();

                if (response && response.items) {
                    console.log(`Fetched records from ${query.offset} to ${query.offset + query.limit - 1}`);
                    console.log(`It took ${(endDate.getTime() - startDate.getTime())} ms.\n`);
                    totalRowsCount = response.totalCount;
                    query.offset += query.limit;

                    console.log(`Saving ${response.items.length} POS Products to IndexedDb ...`);
                    startDate = new Date();
                    await this.posService.addPosProductsToLocalDb(response.items);
                    endDate = new Date();
                    console.log(`It took ${(endDate.getTime() - startDate.getTime())} ms.\n`);
                }

            } while (response && totalRowsCount > query.offset);

            this.updateSyncStatus(EntityType.POS_Product, 200, 'Sync completed successfully');
            console.log('Sync POS Products finished.');
        }
        catch (err: any) {
            Sentry.captureException(err);
            this.updateSyncStatus(EntityType.POS_Product, 500, 'Sync POS Products failed: ' + err.message);
            return Promise.reject('Sync POS Products failed.');
        }
    }

    private async syncCustomersV3() {
        try {
            console.log('Syncing Customers ...\n');

            let response = null;
            let totalRowsCount = 1000;
            const lastSyncMetadata = await this.getLastSyncMetadata(EntityType.CustomerV3);
            let query = {
                offset: 0,
                limit: 1000,
                updatedAtMin: (lastSyncMetadata ? lastSyncMetadata.serverDateTime : '')
            };

            do {
                console.log('Fetching Customers ...');
                let startDate = new Date();
                response = await this.posService.findCustomersV3Async(query);
                let endDate = new Date();

                if (response && response.items) {
                    console.log(`Fetched records from ${query.offset} to ${query.offset + query.limit - 1}`);
                    console.log(`It took ${(endDate.getTime() - startDate.getTime())} ms.\n`);
                    totalRowsCount = response.totalCount;
                    query.offset += query.limit;

                    console.log(`Saving ${response.items.length} Customers to IndexedDb ...`);
                    startDate = new Date();
                    await this.posService.addCustomersV3ToLocalDb(response.items);
                    endDate = new Date();
                    console.log(`It took ${(endDate.getTime() - startDate.getTime())} ms.\n`);
                }

            } while (response && totalRowsCount > query.offset);

            this.updateSyncStatus(EntityType.CustomerV3, 200, 'Sync completed successfully');
            console.log('Sync Customers finished.');
        }
        catch (err: any) {
            Sentry.captureException(err);
            this.updateSyncStatus(EntityType.CustomerV3, 500, 'Sync Customers failed: ' + err.message);
            return Promise.reject('Sync Customers failed.');
        }
    }

    private async syncPosLocations() {
        try {
            console.log('Syncing POS Locations ...\n');

            let response = null;
            let totalRowsCount = 1000;
            const lastSyncMetadata = await this.getLastSyncMetadata(EntityType.POS_Location);
            let query = {
                offset: 0,
                limit: 1000,
                updatedAtMin: (lastSyncMetadata ? lastSyncMetadata.serverDateTime : '')
            };

            do {
                console.log('Fetching POS Locations ...');
                let startDate = new Date();
                response = await this.posService.findLocationsAsync(query);
                let endDate = new Date();

                if (response && response.items) {
                    console.log(`Fetched records from ${query.offset} to ${query.offset + query.limit - 1}`);
                    console.log(`It took ${(endDate.getTime() - startDate.getTime())} ms.\n`);
                    totalRowsCount = response.totalCount;
                    query.offset += query.limit;

                    console.log(`Saving ${response.items.length} Locations to IndexedDb ...`);
                    startDate = new Date();
                    this.posService.addLocationsToLocalDb(response.items);
                    endDate = new Date();
                    console.log(`It took ${(endDate.getTime() - startDate.getTime())} ms.\n`);
                }

            } while (response && totalRowsCount > query.offset);

            this.updateSyncStatus(EntityType.POS_Location, 200, 'Sync completed successfully');
            console.log('Sync POS Locations finished.');
        }
        catch (err: any) {
            Sentry.captureException(err);
            this.updateSyncStatus(EntityType.POS_Location, 500, 'Sync POS Locations failed: ' + err.message);
            return Promise.reject('Sync POS Locations failed.');
        }
    }

    private async syncPosUnitOfMeasures() {
        try {
            console.log('Syncing Pos Unit Of Measures ...\n');

            let response = null;
            let totalRowsCount = 1000;
            let query = await this.buildQueryParameters(EntityType.PosUnitOfMeasures);

            do {
                console.log('Fetching Pos Unit Of Measures ...');
                let startDate = new Date();
                response = await this.serviceWorkerCore.findPosUnitOfMeasuresAsync(query);
                let endDate = new Date();

                if (response && response.items) {
                    console.log(`Fetched records from ${query.offset} to ${query.offset + query.limit - 1}`);
                    console.log(`It took ${(endDate.getTime() - startDate.getTime())} ms.\n`);
                    totalRowsCount = response.totalCount;
                    query.offset += query.limit;

                    console.log(`Saving ${response.items.length} Pos Unit Of Measures to IndexedDb ...`);
                    startDate = new Date();
                    await this.serviceWorkerCore.addPosUnitOfMeasuresToLocalDb(response.items);
                    endDate = new Date();
                    console.log(`It took ${(endDate.getTime() - startDate.getTime())} ms.\n`);
                }

            } while (response && totalRowsCount > query.offset);

            this.updateSyncStatus('PosUnitOfMeasures', 200, 'Sync completed successfully');
            console.log('Sync Pos Unit Of Measures finished.');
        }
        catch (err: any) {
            Sentry.captureException(err);
            this.updateSyncStatus('PosUnitOfMeasures', 500, 'Sync Pos Unit Of Measures failed: ' + err.message);
            return Promise.reject('Sync Pos Unit of Measures failed.');
        }
    }

    private async syncPurchaseOrders() {
        try {
            console.log('Syncing Purchase Orders ...\n');

            let response = null;
            let totalRowsCount = 1000;
            const lastSyncMetadata = await this.getLastSyncMetadata(EntityType.PRCH_PurchaseOrders);
            let query = {
                offset: 0,
                limit: 1000,
                updatedAtMin: (lastSyncMetadata ? lastSyncMetadata.serverDateTime : '')
            };

            do {
                console.log('Fetching Purchase Orders ...');
                let startDate = new Date();
                response = await this.serviceWorkerCore.findPurchaseOrdersAsync(query);
                let endDate = new Date();

                if (response && response.items) {
                    console.log(`Fetched records from ${query.offset} to ${query.offset + query.limit - 1}`);
                    console.log(`It took ${(endDate.getTime() - startDate.getTime())} ms.\n`);
                    totalRowsCount = response.totalCount;
                    query.offset += query.limit;

                    console.log(`Saving ${response.items.length} Purchase Orders to IndexedDb ...`);
                    startDate = new Date();
                    await this.serviceWorkerCore.addPurchaseOrdersToLocalDb(response.items);
                    endDate = new Date();
                    console.log(`It took ${(endDate.getTime() - startDate.getTime())} ms.\n`);
                }

            } while (response && totalRowsCount > query.offset);

            this.updateSyncStatus(EntityType.PRCH_PurchaseOrders, 200, 'Sync completed successfully');
            console.log('Sync Purchase Orders finished.');
        }
        catch (err: any) {
            Sentry.captureException(err);
            this.updateSyncStatus(EntityType.PRCH_PurchaseOrders, 500, 'Sync Purchase Orders failed: ' + err.message);
            return Promise.reject('Sync Purchase Orders failed.');
        }
    }

    private async syncDeliveryZones() {
        try {
            console.log('Syncing Delivery Zones ...\n');

            let response = null;
            let totalRowsCount = 1000;
            const lastSyncMetadata = await this.getLastSyncMetadata(EntityType.POS_DeliveryZones);
            let query = {
                offset: 0,
                limit: 1000,
                updatedAtMin: (lastSyncMetadata ? lastSyncMetadata.serverDateTime : '')
            };

            do {
                console.log('Fetching Delivery Zones ...');
                let startDate = new Date();
                response = await this.posService.findDeliveryZonesAsync(query);
                let endDate = new Date();

                if (response && response.items) {
                    console.log(`Fetched records from ${query.offset} to ${query.offset + query.limit - 1}`);
                    console.log(`It took ${(endDate.getTime() - startDate.getTime())} ms.\n`);
                    totalRowsCount = response.totalCount;
                    query.offset += query.limit;

                    console.log(`Saving ${response.items.length} Delivery Zones to IndexedDb ...`);
                    startDate = new Date();
                    await this.posService.addDeliveryZonesToLocalDb(response.items);
                    endDate = new Date();
                    console.log(`It took ${(endDate.getTime() - startDate.getTime())} ms.\n`);
                }

            } while (response && totalRowsCount > query.offset);

            this.updateSyncStatus(EntityType.POS_DeliveryZones, 200, 'Sync completed successfully');
            console.log('Sync Delivery Zones finished.');
        }
        catch (err: any) {
            Sentry.captureException(err);
            this.updateSyncStatus(EntityType.POS_DeliveryZones, 500, 'Sync Delivery Zones failed: ' + err.message);
            return Promise.reject('Sync Delivery Zones failed.');
        }
    }

    //#region Helpers

    private async getServerDateTime() {
        try {
            let response: ApiResponse<Date> | undefined;
            console.log('Syncing server datetime ...\n');
            response = await this.commonService.getServerDateTimeAsync();
            if (response && response.status_code === 200) {
                this.serverDateTime = response.result;
            }
        } catch (error) {
            Sentry.captureException(error);
            return Promise.reject('Syncing server datetime failed.');
        }
    }

    private async buildQueryParameters(entityType: EntityType) {
        const lastSyncMetadata = await this.getLastSyncMetadata(entityType);
        let query = {
            offset: 0,
            limit: 1000,
            updateDate: (lastSyncMetadata ? lastSyncMetadata.serverDateTime : '')
        };

        return query;
    }

    private async updateSyncStatus(entityName: string, statusCode: number, statusMessage: string) {
        const syncMetadata = {
            "tenantId": this.authService.currentTenant,
            "entityName": entityName,
            "serverDateTime": this.serverDateTime,
            "statusCode": statusCode,
            "statusMessage": statusMessage
        };

        this.localDbProvider.db.syncMetadata.add(syncMetadata);
    }

    private async getLastSyncMetadata(entityName: string) {
        const lastSyncMetadata = await this.localDbProvider.db.syncMetadata
            .where('tenantId').equalsIgnoreCase(this.authService.currentTenant)
            .and((itm: any) => itm.entityName === entityName)
            .and((itm: any) => itm.statusCode === 200)
            .last()
            .catch((err: any) => this.localDbProvider.handleErrors(err));

        return lastSyncMetadata;
    }

    addCostTypesToLocalDb(costType: CostType[]) {
        if (costType && costType.length > 0) {
            this.localDbProvider.db.MFG_CostTypes.bulkPut(costType)
                .catch((err: any) => this.localDbProvider.handleErrors(err));
        }
    }

    findCostTypesAsync(query: any): Promise<PagedList<CostType[]> | undefined> {
        const queryString = new QueryStringHelper().toQueryString(query);
        return firstValueFrom(
            this.httpClient.get<PagedList<CostType[]>>(this.baseUrl + '/api/costtypes/find?' + queryString)
        ).catch(() => { return undefined; });
    }

    async initDefaultTaxSettings(settings: PagedList<SystemSetting[]>) {
        const salesInvoicesSettingObj = settings.items.find(x => x.name === SystemSettingsEnum.SalesInvoice);
        const salesInvoicesSetting: SalesInvoicesSetting = salesInvoicesSettingObj ? SalesInvoicesSetting.clone(JSON.parse(salesInvoicesSettingObj.value)) : new SalesInvoicesSetting();
        if (!salesInvoicesSetting.defaultTaxId) {
            const taxes = await firstValueFrom(
                this.purchaseInvoiceService.getAllTaxes()
            ).catch(() => { return undefined; });
            const defaultTax = taxes?.find(x => x.name === 'No tax' && x.rate === 0);
            if (defaultTax) {
                salesInvoicesSetting.defaultTaxId = defaultTax.id;
            }
            const setting = await firstValueFrom(
                this.systemSettingsService.saveSystemSetting({
                    name: SystemSettingsEnum.SalesInvoice,
                    value: JSON.stringify(salesInvoicesSetting)
                })
            ).catch(() => { return undefined; });
            if (setting) {
                settings.items.push(setting);
            }
        }

        return settings;
    }

}

enum EntityType {
    Setting = 'Setting',
    SystemSetting = 'SystemSetting',

    Account = 'Account',
    Currency = 'Currency',

    StockItem = 'StockItem',
    StockItemSerial = 'StockItemSerial',
    Warehouse = 'Warehouse',
    WarehouseStock = 'WHS_WarehouseStock',

    Customer = 'Customer',
    ServiceItem = 'ServiceItem',
    SalesPerson = 'SalesPerson',
    PrintTemplate = 'PrintTemplate',
    SalesStore = 'SalesStore',
    SalesBundle = 'SalesBundle',
    Tag = 'Tag',
    Promotion = 'POS_Promotions',
    CashRegister = 'POS_CashRegisters',
    POS_Product = 'POS_Products',
    CustomerV3 = 'POS_Customers_V3',
    POS_Location = 'POS_Locations',
    POS_Taxes = 'POS_Taxes',
    POS_DeliveryZones = 'POS_DeliveryZones',

    AssetUnitOfMeasures = 'AssetUnitOfMeasures',
    AssetLocations = 'AssetLocations',
    AssetTypes = 'AssetTypes',
    CostType = 'MFG_CostTypes',
    PosUnitOfMeasures = 'PosUnitOfMeasures',

    PRCH_PurchaseOrders = 'PRCH_PurchaseOrders'
}

interface SyncManager {
    getTags(): Promise<string[]>;
    register(tag: string): Promise<void>;
}

declare global {
    interface ServiceWorkerRegistration {
        readonly sync: SyncManager;
    }
}
