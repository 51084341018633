<div class="d-flex">
    <div class="list-box-input full-width">
        <mat-form-field class="mat-custom__input mat-custom__input--bg-white field-space-none">
            <mat-label>{{ label }}</mat-label>
            <input matInput type="text" placeholder="{{placeHolder}}" [formControl]="autoCompleteControl"
                [matAutocomplete]="auto" [id]="inputId" #txtSearch>
            <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete"
                (optionSelected)="onOptionSelected($event.option.value)">
                <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                    {{option[textProperty]}}
                </mat-option>
            </mat-autocomplete>
            <div matSuffix class="d-flex align-items-center">
                <icon name="arrow_drop_down" class="text-muted" (click)="autoCompleteControl.setValue('')">
                </icon>
                <mat-spinner id="spinner" diameter="21" *ngIf="selectedOptionsLoading"></mat-spinner>
            </div>
        </mat-form-field>
        <div class="list-box">
            <div *ngFor="let option of selectedOptions">
                <div class="list-box-item" [ngClass]="{ 'list-box-item-active': option === selectedListItem }"
                    (click)="selectedListItem = option">
                    {{option}}
                </div>
            </div>
        </div>
    </div>
    <div class="d-flex flex-column">
        <button id="btn_playlistAdd" mat-icon-button (click)="onAddAllOptions()">
            <icon name="playlist_add"></icon>
        </button>
        <button id="btn_delete" mat-icon-button (click)="onRemoveOption()">
            <icon name="delete"></icon>
        </button>
        <button id="btn_deleteSweep" mat-icon-button (click)="onRemoveAllOptions()">
            <icon name="delete_sweep"></icon>
        </button>
    </div>
</div>