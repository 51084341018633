import { Injectable } from '@angular/core';
import { DocumentReference } from '@angular/fire/firestore';
import { AuthService } from '@auth';
import { FirestoreService } from '@core';
import { CollectionReference, query, updateDoc } from 'firebase/firestore';
import { MenuItem, ModulePage, ModulePageOpen } from '@shared/models/modules-landing.model';
import { BehaviorSubject } from 'rxjs';
import { PagePermissions } from '@shared/models/permissions';
import { RolesEnum } from '@shared/models/roles';

@Injectable({
  providedIn: 'root'
})
export class PageMenuDateService {

  private readonly _openedPages = new BehaviorSubject<ModulePageOpen[]>([]);
  readonly openedPages$ = this._openedPages.asObservable();

  get openedPages(): ModulePageOpen[] {
    return this._openedPages.getValue();
  }

  private set openedPages(val: ModulePageOpen[]) {
    this._openedPages.next(val);
  }

  public get pagePermissions(): typeof PagePermissions {
    return PagePermissions;
  }

  public get roles(): typeof RolesEnum {
    return RolesEnum;
  }

  private get starredPageDocumentsCollection(): CollectionReference<ModulePageOpen> {
    return this.firestoreService.col(
      `accountSettings/${this.authService.currentUserId}/openedPages`
    );
  }

  constructor(
    private authService: AuthService,
    private firestoreService: FirestoreService
  ) { }

  loadOpenedPages(): Promise<ModulePageOpen[]> {
    const queryFn = query(
      this.firestoreService.col<ModulePageOpen>(
        `accountSettings/${this.authService.currentUserId}/openedPages`
      )
    );
    return this.firestoreService.getCollectionWithIdsAsync<ModulePageOpen>(queryFn);
  }

  openedPageDocument(docId: string | undefined): DocumentReference<ModulePageOpen> {
    return this.firestoreService.doc(
      `accountSettings/${this.authService.currentUserId}/openedPages/${docId}`
    );
  }

  checkIfExist(id: string) {
    return this.openedPages.filter(el => el.id === id);
  }

  addOpenedPageDate(data: ModulePageOpen) {
    return this.firestoreService.addDoc(
      this.starredPageDocumentsCollection,
      data
    );
  }

  updateOpenedPageDate(data: ModulePageOpen) {
    return updateDoc(this.openedPageDocument(data.doc_id), { ...data, lastTimeOpend: data.lastTimeOpend });
  }

  getClickedDate(id: string) {
    const data = {
      id: id,
      lastTimeOpend: new Date().getTime()
    };
    if (this.checkIfExist(id)?.length) {
      this.updateOpenedPageDate({ ...this.checkIfExist(id)[0], ...data }).then(() => {
        this.loadOpenedPagesList();
      });
    } else {
      this.addOpenedPageDate(data).then(() => {
        this.loadOpenedPagesList();
      });
    }
  }

  loadOpenedPagesList() {
    return new Promise((resolve) => {
      this.loadOpenedPages()
        .then((openedPagesRes: any) => {
          this.openedPages = openedPagesRes;
          resolve(true);
        })
        .catch((err: any) => {
        });
    })
  }

  menuItemsMapper(menu_items: MenuItem[] = []) {
    menu_items.forEach((moduleItem: any) => {
      // moduleItem.children.forEach((group: ModuleChildrenGroup) => {
      moduleItem.details.forEach((modulePage: ModulePage) => {
        const page = this.openedPages.find((page: ModulePageOpen) => page.id === modulePage.id);
        if (page) {
          modulePage.lastTimeOpend = page.lastTimeOpend;
        }
      });
      // });
    });
  }

}
