<div class="dialog__height" [dir]="('uiDirection' | translate)">
    <mat-dialog-content>
        <!-- <p class="font-size-s margin-bottom-base margin-top-s">
            {{ "previewFeatures.header" | translate }}
        </p> -->
        <div class="text-center mb-5 mt-4">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 160" height="103" width="129">
                <g transform="translate(100, 80)">
                    <rect x="-50" y="-60" width="100" height="120" rx="5" ry="5" fill="var(--color-surface-2)"
                        stroke="var(--m3-sys-primary)" stroke-width="2.5" />
                    <rect x="-20" y="-68" width="40" height="16" rx="8" ry="8" fill="var(--m3-sys-surface)"
                        stroke="var(--m3-sys-primary)" stroke-width="2.5" />
                    <rect x="-40" y="-45" width="80" height="95" rx="3" ry="3" fill="var(--m3-sys-surface)"
                        stroke="var(--m3-sys-primary)" stroke-width="1.5" stroke-dasharray="5,3" />
                    <line x1="-30" y1="-25" x2="30" y2="-25" stroke="var(--color-surface-2)" stroke-width="4"
                        stroke-linecap="round" />
                    <line x1="-30" y1="-5" x2="30" y2="-5" stroke="var(--color-surface-2)" stroke-width="4"
                        stroke-linecap="round" />
                    <line x1="-30" y1="15" x2="30" y2="15" stroke="var(--color-surface-2)" stroke-width="4"
                        stroke-linecap="round" />
                    <line x1="-30" y1="35" x2="10" y2="35" stroke="var(--color-surface-2)" stroke-width="4"
                        stroke-linecap="round" />
                    <path d="M0,-5 L3,3 11,3 5,8 7,16 0,11 -7,16 -5,8 -11,3 -3,3 Z" fill="var(--color-surface-2)"
                        stroke="var(--m3-sys-primary)" stroke-width="2" stroke-linejoin="round" />
                    <path d="M23,-20 L24,-16 28,-15 25,-12 25,-8 22,-11 18,-9 20,-13 17,-16 21,-16 Z"
                        fill="var(--color-surface-2)" stroke="var(--m3-sys-primary)" stroke-width="1.5"
                        stroke-linejoin="round" />
                    <path d="M-23,5 L-22,9 -18,10 -21,13 -21,17 -24,14 -28,16 -26,12 -29,9 -25,9 Z"
                        fill="var(--color-surface-2)" stroke="var(--m3-sys-primary)" stroke-width="1.5"
                        stroke-linejoin="round" />
                </g>
            </svg>
            <p class="text-capitalize mt-2">
                {{ "previewFeatures.noPreviewFeaturesAvailable" | translate }}
            </p>
        </div>
    </mat-dialog-content>
</div>