import * as i0 from '@angular/core';
import { inject, ViewContainerRef, Component, Injectable, NgZone, EventEmitter, booleanAttribute, ViewEncapsulation, Input, Output, NgModule } from '@angular/core';
import { _removeFromParent, BaseComponentWrapper, VanillaFrameworkOverrides, _combineAttributesAndGridOptions, createGrid, _processOnChange, _BOOLEAN_MIXED_GRID_OPTIONS } from 'ag-grid-community';

// To speed up the removal of custom components we create a number of shards to contain them.
// Removing a single component calls a function within Angular called removeFromArray.
// This is a lot faster if the array is smaller.
class AgComponentContainer {
  constructor() {
    this.vcr = inject(ViewContainerRef);
  }
  static {
    this.ɵfac = function AgComponentContainer_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || AgComponentContainer)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: AgComponentContainer,
      selectors: [["ag-component-container"]],
      decls: 0,
      vars: 0,
      template: function AgComponentContainer_Template(rf, ctx) {},
      encapsulation: 2
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AgComponentContainer, [{
    type: Component,
    args: [{
      selector: 'ag-component-container',
      template: ''
    }]
  }], null, null);
})();
const NUM_SHARDS = 16;
let shardIdx = 0;
function createComponentContainers(vcr) {
  const containerMap = new Map();
  for (let i = 0; i < NUM_SHARDS; i++) {
    const container = vcr.createComponent(AgComponentContainer);
    containerMap.set(i, container);
    _removeFromParent(container.location.nativeElement);
  }
  return containerMap;
}
class AngularFrameworkComponentWrapper extends BaseComponentWrapper {
  setViewContainerRef(viewContainerRef, angularFrameworkOverrides) {
    this.viewContainerRef = viewContainerRef;
    this.angularFrameworkOverrides = angularFrameworkOverrides;
  }
  createWrapper(OriginalConstructor) {
    const angularFrameworkOverrides = this.angularFrameworkOverrides;
    const that = this;
    that.compShards ??= createComponentContainers(this.viewContainerRef);
    class DynamicAgNg2Component extends BaseGuiComponent {
      init(params) {
        angularFrameworkOverrides.runInsideAngular(() => {
          super.init(params);
          this._componentRef.changeDetectorRef.detectChanges();
        });
      }
      createComponent() {
        return that.createComponent(OriginalConstructor);
      }
      hasMethod(name) {
        return wrapper.getFrameworkComponentInstance()[name] != null;
      }
      callMethod(name, args) {
        const componentRef = this.getFrameworkComponentInstance();
        const methodCall = componentRef[name];
        // Special case for `doesFilterPass` as it's called very often and current implementation has
        // this filter logic as part of the component when really it is just part of the filter model.
        if (name === 'doesFilterPass') {
          return methodCall.apply(componentRef, args);
        }
        return angularFrameworkOverrides.runInsideAngular(() => methodCall.apply(componentRef, args));
      }
      addMethod(name, callback) {
        wrapper[name] = callback;
      }
    }
    const wrapper = new DynamicAgNg2Component();
    return wrapper;
  }
  createComponent(componentType) {
    shardIdx = (shardIdx + 1) % NUM_SHARDS;
    const container = this.compShards.get(shardIdx);
    return container.instance.vcr.createComponent(componentType);
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵAngularFrameworkComponentWrapper_BaseFactory;
      return function AngularFrameworkComponentWrapper_Factory(__ngFactoryType__) {
        return (ɵAngularFrameworkComponentWrapper_BaseFactory || (ɵAngularFrameworkComponentWrapper_BaseFactory = i0.ɵɵgetInheritedFactory(AngularFrameworkComponentWrapper)))(__ngFactoryType__ || AngularFrameworkComponentWrapper);
      };
    })();
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: AngularFrameworkComponentWrapper,
      factory: AngularFrameworkComponentWrapper.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AngularFrameworkComponentWrapper, [{
    type: Injectable
  }], null, null);
})();
class BaseGuiComponent {
  init(params) {
    this._params = params;
    this._componentRef = this.createComponent();
    this._agAwareComponent = this._componentRef.instance;
    this._frameworkComponentInstance = this._componentRef.instance;
    this._eGui = this._componentRef.location.nativeElement;
    // Angular appends the component to the DOM, so remove it
    _removeFromParent(this._eGui);
    this._agAwareComponent.agInit(this._params);
  }
  getGui() {
    return this._eGui;
  }
  /** `getGui()` returns the `ng-component` element. This returns the actual root element. */
  getRootElement() {
    const firstChild = this._eGui.firstChild;
    return firstChild;
  }
  destroy() {
    if (this._frameworkComponentInstance && typeof this._frameworkComponentInstance.destroy === 'function') {
      this._frameworkComponentInstance.destroy();
    }
    this._componentRef?.destroy();
  }
  getFrameworkComponentInstance() {
    return this._frameworkComponentInstance;
  }
}
class AngularFrameworkEventListenerService {
  constructor(frameworkOverrides) {
    this.frameworkOverrides = frameworkOverrides;
    // Map from user listener to wrapped listener so we can remove listener provided by user
    this.wrappedListeners = new Map();
    this.wrappedGlobalListeners = new Map();
  }
  wrap(userListener) {
    let listener = userListener;
    if (this.frameworkOverrides.shouldWrapOutgoing) {
      listener = event => {
        this.frameworkOverrides.wrapOutgoing(() => userListener(event));
      };
      this.wrappedListeners.set(userListener, listener);
    }
    return listener;
  }
  wrapGlobal(userListener) {
    let listener = userListener;
    if (this.frameworkOverrides.shouldWrapOutgoing) {
      listener = (eventType, event) => {
        this.frameworkOverrides.wrapOutgoing(() => userListener(eventType, event));
      };
      this.wrappedGlobalListeners.set(userListener, listener);
    }
    return listener;
  }
  unwrap(userListener) {
    return this.wrappedListeners.get(userListener) ?? userListener;
  }
  unwrapGlobal(userListener) {
    return this.wrappedGlobalListeners.get(userListener) ?? userListener;
  }
}
class AngularFrameworkOverrides extends VanillaFrameworkOverrides {
  constructor(_ngZone) {
    super('angular');
    this._ngZone = _ngZone;
    this.batchFrameworkComps = true;
    // Flag used to control Zone behaviour when running tests as many test features rely on Zone.
    this.isRunningWithinTestZone = false;
    // Make all events run outside Angular as they often trigger the setup of event listeners
    // By having the event listeners outside Angular we can avoid triggering change detection
    // This also means that if a user calls an AG Grid API method from within their component
    // the internal side effects will not trigger change detection. Without this the events would
    // run inside Angular and trigger change detection as the source of the event was within the angular zone.
    this.wrapIncoming = (callback, source) => this.runOutside(callback, source);
    /**
     * Make sure that any code that is executed outside of AG Grid is running within the Angular zone.
     * This means users can update templates and use binding without having to do anything extra.
     */
    this.wrapOutgoing = callback => this.runInsideAngular(callback);
    this.isRunningWithinTestZone = window?.AG_GRID_UNDER_TEST ?? !!window?.Zone?.AsyncTestZoneSpec;
    if (!this._ngZone) {
      this.runOutside = callback => callback();
    } else if (this.isRunningWithinTestZone) {
      this.runOutside = (callback, source) => {
        if (source === 'resize-observer' || source === 'popupPositioning') {
          // ensure resize observer callbacks are run outside of Angular even under test due to Jest not supporting ResizeObserver
          // which means it just loops continuously with a setTimeout with no way to flush the queue or have fixture.whenStable() resolve.
          return this._ngZone.runOutsideAngular(callback);
        }
        // When under test run inside Angular so that tests can use fixture.whenStable() to wait for async operations to complete.
        return callback();
      };
    } else {
      this.runOutside = callback => this._ngZone.runOutsideAngular(callback);
    }
  }
  /**
   * The shouldWrapOutgoing property is used to determine if events should be run outside of Angular or not.
   * If an event handler is registered outside of Angular then we should not wrap the event handler
   * with runInsideAngular() as the user may not have wanted this.
   * This is also used to not wrap internal event listeners that are registered with RowNodes and Columns.
   */
  get shouldWrapOutgoing() {
    return this._ngZone && NgZone.isInAngularZone();
  }
  createLocalEventListenerWrapper(existingFrameworkEventListenerService, localEventService) {
    if (this.shouldWrapOutgoing) {
      return existingFrameworkEventListenerService ?? (() => {
        localEventService.setFrameworkOverrides(this);
        return new AngularFrameworkEventListenerService(this);
      })();
    }
    return undefined;
  }
  createGlobalEventListenerWrapper() {
    return new AngularFrameworkEventListenerService(this);
  }
  isFrameworkComponent(comp) {
    if (!comp) {
      return false;
    }
    const prototype = comp.prototype;
    return prototype && 'agInit' in prototype;
  }
  runInsideAngular(callback) {
    if (!this._ngZone || NgZone.isInAngularZone()) {
      return callback();
    }
    // Check for _ngZone existence as it is not present when Zoneless
    return this._ngZone.run(callback);
  }
  runOutsideAngular(callback, source) {
    return this.runOutside(callback, source);
  }
  static {
    this.ɵfac = function AngularFrameworkOverrides_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || AngularFrameworkOverrides)(i0.ɵɵinject(i0.NgZone));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: AngularFrameworkOverrides,
      factory: AngularFrameworkOverrides.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AngularFrameworkOverrides, [{
    type: Injectable
  }], () => [{
    type: i0.NgZone
  }], null);
})();

// False positive lint error, ElementRef and co can't be type imports
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
class AgGridAngular {
  constructor(elementDef, _viewContainerRef, _angularFrameworkOverrides, _frameworkCompWrapper) {
    this._viewContainerRef = _viewContainerRef;
    this._angularFrameworkOverrides = _angularFrameworkOverrides;
    this._frameworkCompWrapper = _frameworkCompWrapper;
    this._initialised = false;
    this._destroyed = false;
    // in order to ensure firing of gridReady is deterministic
    this._holdEvents = true;
    this._fullyReady = new Promise(resolve => {
      this._resolveFullyReady = resolve;
    });
    // @START@
    /** Specifies the status bar components to use in the status bar.
     */
    this.statusBar = undefined;
    /** Specifies the side bar components.
     */
    this.sideBar = undefined;
    /** Set to `true` to not show the context menu. Use if you don't want to use the default 'right click' context menu.
     * @default false
     */
    this.suppressContextMenu = undefined;
    /** When using `suppressContextMenu`, you can use the `onCellContextMenu` function to provide your own code to handle cell `contextmenu` events.
     * This flag is useful to prevent the browser from showing its default context menu.
     * @default false
     */
    this.preventDefaultOnContextMenu = undefined;
    /** Allows context menu to show, even when `Ctrl` key is held down.
     * @default false
     */
    this.allowContextMenuWithControlKey = undefined;
    /** Changes the display type of the column menu.
     * `'new'` just displays the main list of menu items. `'legacy'` displays a tabbed menu.
     * @default 'new'
     * @initial
     */
    this.columnMenu = undefined;
    /** Only recommended for use if `columnMenu = 'legacy'`.
     * When `true`, the column menu button will always be shown.
     * When `false`, the column menu button will only show when the mouse is over the column header.
     * When using `columnMenu = 'legacy'`, this will default to `false` instead of `true`.
     * @default true
     */
    this.suppressMenuHide = undefined;
    /** Set to `true` to use the browser's default tooltip instead of using the grid's Tooltip Component.
     * @default false
     * @initial
     */
    this.enableBrowserTooltips = undefined;
    /** The trigger that will cause tooltips to show and hide.
     *  - `hover` - The tooltip will show/hide when a cell/header is hovered.
     *  - `focus` - The tooltip will show/hide when a cell/header is focused.
     * @default 'hover'
     * @initial
     */
    this.tooltipTrigger = undefined;
    /** The delay in milliseconds that it takes for tooltips to show up once an element is hovered over.
     *     **Note:** This property does not work if `enableBrowserTooltips` is `true`.
     * @default 2000
     */
    this.tooltipShowDelay = undefined;
    /** The delay in milliseconds that it takes for tooltips to hide once they have been displayed.
     *     **Note:** This property does not work if `enableBrowserTooltips` is `true` and `tooltipHideTriggers` includes `timeout`.
     * @default 10000
     */
    this.tooltipHideDelay = undefined;
    /** Set to `true` to have tooltips follow the cursor once they are displayed.
     * @default false
     * @initial
     */
    this.tooltipMouseTrack = undefined;
    /** This defines when tooltip will show up for Cells, Headers and SetFilter Items.
     *  - `standard` - The tooltip always shows up when the items configured with Tooltips are hovered.
     * - `whenTruncated` - The tooltip will only be displayed when the items hovered have truncated (showing ellipsis) values. This property does not work when `enableBrowserTooltips={true}`.
     * @default `standard`
     */
    this.tooltipShowMode = undefined;
    /** Set to `true` to enable tooltip interaction. When this option is enabled, the tooltip will not hide while the
     * tooltip itself it being hovered or has focus.
     * @default false
     * @initial
     */
    this.tooltipInteraction = undefined;
    /** DOM element to use as the popup parent for grid popups (context menu, column menu etc).
     */
    this.popupParent = undefined;
    /** Set to `true` to also include headers when copying to clipboard using `Ctrl + C` clipboard.
     * @default false
     */
    this.copyHeadersToClipboard = undefined;
    /** Set to `true` to also include group headers when copying to clipboard using `Ctrl + C` clipboard.
     * @default false
     */
    this.copyGroupHeadersToClipboard = undefined;
    /** Specify the delimiter to use when copying to clipboard.
     * @default '\t'
     */
    this.clipboardDelimiter = undefined;
    /** Set to `true` to copy the cell range or focused cell to the clipboard and never the selected rows.
     * @default false
     * @deprecated v32.2 Use `rowSelection.copySelectedRows` instead.
     */
    this.suppressCopyRowsToClipboard = undefined;
    /** Set to `true` to copy rows instead of ranges when a range with only a single cell is selected.
     * @default false
     * @deprecated v32.2 Use `rowSelection.copySelectedRows` instead.
     */
    this.suppressCopySingleCellRanges = undefined;
    /** Set to `true` to work around a bug with Excel (Windows) that adds an extra empty line at the end of ranges copied to the clipboard.
     * @default false
     */
    this.suppressLastEmptyLineOnPaste = undefined;
    /** Set to `true` to turn off paste operations within the grid.
     * @default false
     */
    this.suppressClipboardPaste = undefined;
    /** Set to `true` to stop the grid trying to use the Clipboard API, if it is blocked, and immediately fallback to the workaround.
     * @default false
     */
    this.suppressClipboardApi = undefined;
    /** Set to `true` to block     **cut** operations within the grid.
     * @default false
     */
    this.suppressCutToClipboard = undefined;
    /** Array of Column / Column Group definitions.
     */
    this.columnDefs = undefined;
    /** A default column definition. Items defined in the actual column definitions get precedence.
     */
    this.defaultColDef = undefined;
    /** A default column group definition. All column group definitions will use these properties. Items defined in the actual column group definition get precedence.
     * @initial
     */
    this.defaultColGroupDef = undefined;
    /** An object map of custom column types which contain groups of properties that column definitions can reuse by referencing in their `type` property.
     */
    this.columnTypes = undefined;
    /** An object map of cell data types to their definitions.
     * Cell data types can either override/update the pre-defined data types
     * (`'text'`, `'number'`,  `'boolean'`,  `'date'`,  `'dateString'` or  `'object'`),
     * or can be custom data types.
     */
    this.dataTypeDefinitions = undefined;
    /** Keeps the order of Columns maintained after new Column Definitions are updated.
     *
     * @default false
     */
    this.maintainColumnOrder = undefined;
    /** Resets pivot column order when impacted by filters, data or configuration changes
     *
     * @default false
     */
    this.enableStrictPivotColumnOrder = undefined;
    /** If `true`, then dots in field names (e.g. `'address.firstLine'`) are not treated as deep references. Allows you to use dots in your field name if you prefer.
     * @default false
     */
    this.suppressFieldDotNotation = undefined;
    /** The height in pixels for the row containing the column label header. If not specified, it uses the theme value of `header-height`.
     */
    this.headerHeight = undefined;
    /** The height in pixels for the rows containing header column groups. If not specified, it uses `headerHeight`.
     */
    this.groupHeaderHeight = undefined;
    /** The height in pixels for the row containing the floating filters. If not specified, it uses the theme value of `header-height`.
     */
    this.floatingFiltersHeight = undefined;
    /** The height in pixels for the row containing the columns when in pivot mode. If not specified, it uses `headerHeight`.
     */
    this.pivotHeaderHeight = undefined;
    /** The height in pixels for the row containing header column groups when in pivot mode. If not specified, it uses `groupHeaderHeight`.
     */
    this.pivotGroupHeaderHeight = undefined;
    /** Allow reordering and pinning columns by dragging columns from the Columns Tool Panel to the grid.
     * @default false
     */
    this.allowDragFromColumnsToolPanel = undefined;
    /** Set to `true` to suppress column moving, i.e. to make the columns fixed position.
     * @default false
     */
    this.suppressMovableColumns = undefined;
    /** If `true`, the `ag-column-moving` class is not added to the grid while columns are moving. In the default themes, this results in no animation when moving columns.
     * @default false
     */
    this.suppressColumnMoveAnimation = undefined;
    /** Set to `true` to suppress moving columns while dragging the Column Header. This option highlights the position where the column will be placed and it will only move it on mouse up.
     * @default false
     */
    this.suppressMoveWhenColumnDragging = undefined;
    /** If `true`, when you drag a column out of the grid (e.g. to the group zone) the column is not hidden.
     * @default false
     */
    this.suppressDragLeaveHidesColumns = undefined;
    /** Enable to prevent column visibility changing when grouped columns are changed.
     * @default false
     */
    this.suppressGroupChangesColumnVisibility = undefined;
    /** By default, when a column is un-grouped, i.e. using the Row Group Panel, it is made visible in the grid. This property stops the column becoming visible again when un-grouping.
     * @default false
     * @deprecated v33.0.0 - Use `suppressGroupChangesColumnVisibility: 'suppressShowOnUngroup'` instead.
     */
    this.suppressMakeColumnVisibleAfterUnGroup = undefined;
    /** If `true`, when you drag a column into a row group panel the column is not hidden.
     * @default false
     * @deprecated v33.0.0 - Use `suppressGroupChangesColumnVisibility: 'suppressHideOnGroup'` instead.
     */
    this.suppressRowGroupHidesColumns = undefined;
    /** Set to `'shift'` to have shift-resize as the default resize operation (same as user holding down `Shift` while resizing).
     */
    this.colResizeDefault = undefined;
    /** Suppresses auto-sizing columns for columns. In other words, double clicking a column's header's edge will not auto-size.
     * @default false
     * @initial
     */
    this.suppressAutoSize = undefined;
    /** Number of pixels to add to a column width after the [auto-sizing](./column-sizing/#auto-size-columns-to-fit-cell-contents) calculation.
     * Set this if you want to add extra room to accommodate (for example) sort icons, or some other dynamic nature of the header.
     * @default 20
     */
    this.autoSizePadding = undefined;
    /** Set this to `true` to skip the `headerName` when `autoSize` is called by default.
     * @default false
     * @initial
     */
    this.skipHeaderOnAutoSize = undefined;
    /** Auto-size the columns when the grid is loaded. Can size to fit the grid width, fit a provided width, or fit the cell contents.
     * @initial
     */
    this.autoSizeStrategy = undefined;
    /** A map of component names to components.
     * @initial
     */
    this.components = undefined;
    /** Set to `'fullRow'` to enable Full Row Editing. Otherwise leave blank to edit one cell at a time.
     */
    this.editType = undefined;
    /** Set to `true` to enable Single Click Editing for cells, to start editing with a single click.
     * @default false
     */
    this.singleClickEdit = undefined;
    /** Set to `true` so that neither single nor double click starts editing.
     * @default false
     */
    this.suppressClickEdit = undefined;
    /** Set to `true` to stop the grid updating data after `Edit`, `Clipboard` and `Fill Handle` operations. When this is set, it is intended the application will update the data, eg in an external immutable store, and then pass the new dataset to the grid. <br />**Note:** `rowNode.setDataValue()` does not update the value of the cell when this is `True`, it fires `onCellEditRequest` instead.
     * @default false
     */
    this.readOnlyEdit = undefined;
    /** Set this to `true` to stop cell editing when grid loses focus.
     * The default is that the grid stays editing until focus goes onto another cell.
     * @default false
     * @initial
     */
    this.stopEditingWhenCellsLoseFocus = undefined;
    /** Set to `true` along with `enterNavigatesVerticallyAfterEdit` to have Excel-style behaviour for the `Enter` key.
     * i.e. pressing the `Enter` key will move down to the cell beneath and `Shift+Enter` will move up to the cell above.
     * @default false
     */
    this.enterNavigatesVertically = undefined;
    /** Set to `true` along with `enterNavigatesVertically` to have Excel-style behaviour for the 'Enter' key.
     * i.e. pressing the Enter key will move down to the cell beneath and Shift+Enter key will move up to the cell above.
     * @default false
     */
    this.enterNavigatesVerticallyAfterEdit = undefined;
    /** Forces Cell Editing to start when backspace is pressed. This is only relevant for MacOS users.
     */
    this.enableCellEditingOnBackspace = undefined;
    /** Set to `true` to enable Undo / Redo while editing.
     * @initial
     */
    this.undoRedoCellEditing = undefined;
    /** Set the size of the undo / redo stack.
     * @default 10
     * @initial
     */
    this.undoRedoCellEditingLimit = undefined;
    /** A default configuration object used to export to CSV.
     */
    this.defaultCsvExportParams = undefined;
    /** Prevents the user from exporting the grid to CSV.
     * @default false
     */
    this.suppressCsvExport = undefined;
    /** A default configuration object used to export to Excel.
     */
    this.defaultExcelExportParams = undefined;
    /** Prevents the user from exporting the grid to Excel.
     * @default false
     */
    this.suppressExcelExport = undefined;
    /** A list (array) of Excel styles to be used when exporting to Excel with styles.
     * @initial
     */
    this.excelStyles = undefined;
    /** Text to find within the grid.
     */
    this.findSearchValue = undefined;
    /** Options for the Find feature.
     */
    this.findOptions = undefined;
    /** Rows are filtered using this text as a Quick Filter.
     * Only supported for Client-Side Row Model.
     */
    this.quickFilterText = undefined;
    /** Set to `true` to turn on the Quick Filter cache, used to improve performance when using the Quick Filter.
     * @default false
     * @initial
     */
    this.cacheQuickFilter = undefined;
    /** Hidden columns are excluded from the Quick Filter by default.
     * To include hidden columns, set to `true`.
     * @default false
     */
    this.includeHiddenColumnsInQuickFilter = undefined;
    /** Changes how the Quick Filter splits the Quick Filter text into search terms.
     */
    this.quickFilterParser = undefined;
    /** Changes the matching logic for whether a row passes the Quick Filter.
     */
    this.quickFilterMatcher = undefined;
    /** When pivoting, Quick Filter is only applied on the pivoted data
     * (or aggregated data if `groupAggFiltering = true`).
     * Set to `true` to apply Quick Filter before pivoting (/aggregating) instead.
     * @default false
     */
    this.applyQuickFilterBeforePivotOrAgg = undefined;
    /** Set to `true` to override the default tree data filtering behaviour to instead exclude child nodes from filter results.
     * @default false
     */
    this.excludeChildrenWhenTreeDataFiltering = undefined;
    /** Set to true to enable the Advanced Filter.
     * @default false
     */
    this.enableAdvancedFilter = undefined;
    /** Allows rows to always be displayed, even if they don't match the applied filtering.
     * Return `true` for the provided row to always be displayed.
     * Only works with the Client-Side Row Model.
     */
    this.alwaysPassFilter = undefined;
    /** Hidden columns are excluded from the Advanced Filter by default.
     * To include hidden columns, set to `true`.
     * @default false
     */
    this.includeHiddenColumnsInAdvancedFilter = undefined;
    /** DOM element to use as the parent for the Advanced Filter to allow it to appear outside of the grid.
     * Set to `null` or `undefined` to appear inside the grid.
     */
    this.advancedFilterParent = undefined;
    /** Customise the parameters passed to the Advanced Filter Builder.
     */
    this.advancedFilterBuilderParams = undefined;
    /** By default, Advanced Filter sanitises user input and passes it to `new Function()` to provide the best performance.
     * Set to `true` to prevent this and use defined functions instead.
     * This will result in slower filtering, but it enables Advanced Filter to work when `unsafe-eval` is disabled.
     * @default false
     */
    this.suppressAdvancedFilterEval = undefined;
    /** When using AG Grid Enterprise, the Set Filter is used by default when `filter: true` is set on column definitions.
     * Set to `true` to prevent this and instead use the Text Filter, Number Filter or Date Filter based on the cell data type,
     * the same as when using AG Grid Community.
     * @default false
     * @initial
     */
    this.suppressSetFilterByDefault = undefined;
    /** Set to `true` to Enable Charts.
     * @default false
     */
    this.enableCharts = undefined;
    /** The list of chart themes that a user can choose from in the chart panel.
     * @default ['ag-default', 'ag-material', 'ag-sheets', 'ag-polychroma', 'ag-vivid'];
     * @initial
     */
    this.chartThemes = undefined;
    /** A map containing custom chart themes.
     * @initial
     */
    this.customChartThemes = undefined;
    /** Chart theme overrides applied to all themes.
     * @initial
     */
    this.chartThemeOverrides = undefined;
    /** Allows customisation of the Chart Tool Panels, such as changing the tool panels visibility and order, as well as choosing which charts should be displayed in the chart panel.
     * @initial
     */
    this.chartToolPanelsDef = undefined;
    /** Get chart menu items. Only applies when using AG Charts Enterprise.
     */
    this.chartMenuItems = undefined;
    /** Provide your own loading cell renderer to use when data is loading via a DataSource.
     * See [Loading Cell Renderer](https://www.ag-grid.com/javascript-data-grid/component-loading-cell-renderer/) for framework specific implementation details.
     */
    this.loadingCellRenderer = undefined;
    /** Params to be passed to the `loadingCellRenderer` component.
     */
    this.loadingCellRendererParams = undefined;
    /** Callback to select which loading cell renderer to be used when data is loading via a DataSource.
     * @initial
     */
    this.loadingCellRendererSelector = undefined;
    /** A map of key->value pairs for localising text within the grid.
     * @initial
     */
    this.localeText = undefined;
    /** Set to `true` to enable Master Detail.
     * @default false
     */
    this.masterDetail = undefined;
    /** Set to `true` to keep detail rows for when they are displayed again.
     * @default false
     * @initial
     */
    this.keepDetailRows = undefined;
    /** Sets the number of details rows to keep.
     * @default 10
     * @initial
     */
    this.keepDetailRowsCount = undefined;
    /** Provide a custom `detailCellRenderer` to use when a master row is expanded.
     * See [Detail Cell Renderer](https://www.ag-grid.com/javascript-data-grid/master-detail-custom-detail/) for framework specific implementation details.
     */
    this.detailCellRenderer = undefined;
    /** Specifies the params to be used by the Detail Cell Renderer. Can also be a function that provides the params to enable dynamic definitions of the params.
     */
    this.detailCellRendererParams = undefined;
    /** Set fixed height in pixels for each detail row.
     * @initial
     */
    this.detailRowHeight = undefined;
    /** Set to `true` to have the detail grid dynamically change it's height to fit it's rows.
     * @initial
     */
    this.detailRowAutoHeight = undefined;
    /** Provides a context object that is provided to different callbacks the grid uses. Used for passing additional information to the callbacks used by your application.
     * @initial
     */
    this.context = undefined;
    /** Provide a custom drag and drop image component.
     * @initial
     */
    this.dragAndDropImageComponent = undefined;
    /** Customise the parameters provided to the Drag and Drop Image Component.
     */
    this.dragAndDropImageComponentParams = undefined;
    /**
     * A list of grids to treat as Aligned Grids.
     * Provide a list if the grids / apis already exist or return via a callback to allow the aligned grids to be retrieved asynchronously.
     * If grids are aligned then the columns and horizontal scrolling will be kept in sync.
     */
    this.alignedGrids = undefined;
    /** Change this value to set the tabIndex order of the Grid within your application.
     * @default 0
     * @initial
     */
    this.tabIndex = undefined;
    /** The number of rows rendered outside the viewable area the grid renders.
     * Having a buffer means the grid will have rows ready to show as the user slowly scrolls vertically.
     * @default 10
     */
    this.rowBuffer = undefined;
    /** Set to `true` to turn on the value cache.
     * @default false
     * @initial
     */
    this.valueCache = undefined;
    /** Set to `true` to configure the value cache to not expire after data updates.
     * @default false
     * @initial
     */
    this.valueCacheNeverExpires = undefined;
    /** Set to `true` to allow cell expressions.
     * @default false
     * @initial
     */
    this.enableCellExpressions = undefined;
    /** Disables touch support (but does not remove the browser's efforts to simulate mouse events on touch).
     * @default false
     * @initial
     */
    this.suppressTouch = undefined;
    /** Set to `true` to not set focus back on the grid after a refresh. This can avoid issues where you want to keep the focus on another part of the browser.
     * @default false
     */
    this.suppressFocusAfterRefresh = undefined;
    /** @deprecated As of v32.2 the grid always uses the browser's ResizeObserver, this grid option has no effect
     * @default false
     * @initial
     */
    this.suppressBrowserResizeObserver = undefined;
    /** @deprecated As of v33 `gridOptions` and `columnDefs` both have a `context` property that should be used for arbitrary user data. This means that column definitions and gridOptions should only contain valid properties making this property redundant.
     * @default false
     * @initial
     */
    this.suppressPropertyNamesCheck = undefined;
    /** Disables change detection.
     * @default false
     */
    this.suppressChangeDetection = undefined;
    /** Set this to `true` to enable debug information from the grid and related components. Will result in additional logging being output, but very useful when investigating problems.
     * @default false
     * @initial
     */
    this.debug = undefined;
    /** Show or hide the loading overlay.
     */
    this.loading = undefined;
    /** Provide a HTML string to override the default loading overlay. Supports non-empty plain text or HTML with a single root element.
     */
    this.overlayLoadingTemplate = undefined;
    /** Provide a custom loading overlay component.
     * @initial
     */
    this.loadingOverlayComponent = undefined;
    /** Customise the parameters provided to the loading overlay component.
     */
    this.loadingOverlayComponentParams = undefined;
    /** Disables the 'loading' overlay.
     * @deprecated v32 - Deprecated. Use `loading=false` instead.
     * @default false
     * @initial
     */
    this.suppressLoadingOverlay = undefined;
    /** Provide a HTML string to override the default no-rows overlay. Supports non-empty plain text or HTML with a single root element.
     */
    this.overlayNoRowsTemplate = undefined;
    /** Provide a custom no-rows overlay component.
     * @initial
     */
    this.noRowsOverlayComponent = undefined;
    /** Customise the parameters provided to the no-rows overlay component.
     */
    this.noRowsOverlayComponentParams = undefined;
    /** Set to `true` to prevent the no-rows overlay being shown when there is no row data.
     * @default false
     * @initial
     */
    this.suppressNoRowsOverlay = undefined;
    /** Set whether pagination is enabled.
     * @default false
     */
    this.pagination = undefined;
    /** How many rows to load per page. If `paginationAutoPageSize` is specified, this property is ignored.
     * @default 100
     */
    this.paginationPageSize = undefined;
    /** Determines if the page size selector is shown in the pagination panel or not.
     * Set to an array of values to show the page size selector with custom list of possible page sizes.
     * Set to `true` to show the page size selector with the default page sizes `[20, 50, 100]`.
     * Set to `false` to hide the page size selector.
     * @default true
     * @initial
     */
    this.paginationPageSizeSelector = undefined;
    /** Set to `true` so that the number of rows to load per page is automatically adjusted by the grid so each page shows enough rows to just fill the area designated for the grid. If `false`, `paginationPageSize` is used.
     * @default false
     */
    this.paginationAutoPageSize = undefined;
    /** Set to `true` to have pages split children of groups when using Row Grouping or detail rows with Master Detail.
     * @default false
     * @initial
     */
    this.paginateChildRows = undefined;
    /** If `true`, the default grid controls for navigation are hidden.
     * This is useful if `pagination=true` and you want to provide your own pagination controls.
     * Otherwise, when `pagination=true` the grid automatically shows the necessary controls at the bottom so that the user can navigate through the different pages.
     * @default false
     */
    this.suppressPaginationPanel = undefined;
    /** Set to `true` to enable pivot mode.
     * @default false
     */
    this.pivotMode = undefined;
    /** When to show the 'pivot panel' (where you drag rows to pivot) at the top. Note that the pivot panel will never show if `pivotMode` is off.
     * @default 'never'
     * @initial
     */
    this.pivotPanelShow = undefined;
    /** The maximum number of generated columns before the grid halts execution. Upon reaching this number, the grid halts generation of columns
     * and triggers a `pivotMaxColumnsExceeded` event. `-1` for no limit.
     * @default -1
     */
    this.pivotMaxGeneratedColumns = undefined;
    /** If pivoting, set to the number of column group levels to expand by default, e.g. `0` for none, `1` for first level only, etc. Set to `-1` to expand everything.
     * @default 0
     */
    this.pivotDefaultExpanded = undefined;
    /** When set and the grid is in pivot mode, automatically calculated totals will appear within the Pivot Column Groups, in the position specified.
     */
    this.pivotColumnGroupTotals = undefined;
    /** When set and the grid is in pivot mode, automatically calculated totals will appear for each value column in the position specified.
     */
    this.pivotRowTotals = undefined;
    /** If `true`, the grid will not swap in the grouping column when pivoting. Useful if pivoting using Server Side Row Model or Viewport Row Model and you want full control of all columns including the group column.
     * @default false
     * @initial
     */
    this.pivotSuppressAutoColumn = undefined;
    /** When enabled, pivot column groups will appear 'fixed', without the ability to expand and collapse the column groups.
     * @default false
     * @initial
     */
    this.suppressExpandablePivotGroups = undefined;
    /** If `true`, then row group, pivot and value aggregation will be read-only from the GUI. The grid will display what values are used for each, but will not allow the user to change the selection.
     * @default false
     */
    this.functionsReadOnly = undefined;
    /** A map of 'function name' to 'function' for custom aggregation functions.
     * @initial
     */
    this.aggFuncs = undefined;
    /** When `true`, column headers won't include the `aggFunc` name, e.g. `'sum(Bank Balance)`' will just be `'Bank Balance'`.
     * @default false
     */
    this.suppressAggFuncInHeader = undefined;
    /** When using aggregations, the grid will always calculate the root level aggregation value.
     * @default false
     */
    this.alwaysAggregateAtRootLevel = undefined;
    /** When using change detection, only the updated column will be re-aggregated.
     * @default false
     */
    this.aggregateOnlyChangedColumns = undefined;
    /** Set to `true` so that aggregations are not impacted by filtering.
     * @default false
     */
    this.suppressAggFilteredOnly = undefined;
    /** Set to `true` to omit the value Column header when there is only a single value column.
     * @default false
     */
    this.removePivotHeaderRowWhenSingleValueColumn = undefined;
    /** Set to `false` to disable Row Animation which is enabled by default.
     * @default true
     */
    this.animateRows = undefined;
    /** Sets the duration in milliseconds of how long a cell should remain in its "flashed" state.
     * If `0`, the cell will not flash.
     * @default 500
     */
    this.cellFlashDuration = undefined;
    /** Sets the duration in milliseconds of how long the "flashed" state animation takes to fade away after the timer set by `cellFlashDuration` has completed.
     * @default 1000
     */
    this.cellFadeDuration = undefined;
    /** Set to `true` to have cells flash after data changes even when the change is due to filtering.
     * @default false
     * @initial
     */
    this.allowShowChangeAfterFilter = undefined;
    /** Switch between layout options: `normal`, `autoHeight`, `print`.
     * @default 'normal'
     */
    this.domLayout = undefined;
    /** When `true`, the order of rows and columns in the DOM are consistent with what is on screen.
     * Disables row animations.
     * @default false
     * @initial
     */
    this.ensureDomOrder = undefined;
    /** When `true`, enables the cell span feature allowing for the use of the `colDef.spanRows` property.
     * @default false
     * @initial
     */
    this.enableCellSpan = undefined;
    /** Set to `true` to operate the grid in RTL (Right to Left) mode.
     * @default false
     * @initial
     */
    this.enableRtl = undefined;
    /** Set to `true` so that the grid doesn't virtualise the columns. For example, if you have 100 columns, but only 10 visible due to scrolling, all 100 will always be rendered.
     * @default false
     * @initial
     */
    this.suppressColumnVirtualisation = undefined;
    /** By default the grid has a limit of rendering a maximum of 500 rows at once (remember the grid only renders rows you can see, so unless your display shows more than 500 rows without vertically scrolling this will never be an issue).
     * <br />**This is only relevant if you are manually setting `rowBuffer` to a high value (rendering more rows than can be seen), or `suppressRowVirtualisation` is true, or if your grid height is able to display more than 500 rows at once.**
     * @default false
     * @initial
     */
    this.suppressMaxRenderedRowRestriction = undefined;
    /** Set to `true` so that the grid doesn't virtualise the rows. For example, if you have 100 rows, but only 10 visible due to scrolling, all 100 will always be rendered.
     * @default false
     * @initial
     */
    this.suppressRowVirtualisation = undefined;
    /** Set to `true` to enable Managed Row Dragging.
     * @default false
     */
    this.rowDragManaged = undefined;
    /** Set to `true` to suppress row dragging.
     * @default false
     */
    this.suppressRowDrag = undefined;
    /** Set to `true` to suppress moving rows while dragging the `rowDrag` waffle. This option highlights the position where the row will be placed and it will only move the row on mouse up.
     * @default false
     */
    this.suppressMoveWhenRowDragging = undefined;
    /** Set to `true` to enable clicking and dragging anywhere on the row without the need for a drag handle.
     * @default false
     */
    this.rowDragEntireRow = undefined;
    /** Set to `true` to enable dragging multiple rows at the same time.
     * @default false
     */
    this.rowDragMultiRow = undefined;
    /** A callback that should return a string to be displayed by the `rowDragComp` while dragging a row.
     * If this callback is not set, the current cell value will be used.
     * If the `rowDragText` callback is set in the ColDef it will take precedence over this, except when
     * `rowDragEntireRow=true`.
     * @initial
     */
    this.rowDragText = undefined;
    /** Provide your own cell renderer component to use for full width rows.
     * See [Full Width Rows](https://www.ag-grid.com/javascript-data-grid/full-width-rows/) for framework specific implementation details.
     */
    this.fullWidthCellRenderer = undefined;
    /** Customise the parameters provided to the `fullWidthCellRenderer` component.
     */
    this.fullWidthCellRendererParams = undefined;
    /** Set to `true` to have the Full Width Rows embedded in grid's main container so they can be scrolled horizontally.
     */
    this.embedFullWidthRows = undefined;
    /** Specifies how the results of row grouping should be displayed.
     *
     *  The options are:
     *
     * - `'singleColumn'`: single group column automatically added by the grid.
     * - `'multipleColumns'`: a group column per row group is added automatically.
     * - `'groupRows'`: group rows are automatically added instead of group columns.
     * - `'custom'`: informs the grid that group columns will be provided.
     */
    this.groupDisplayType = undefined;
    /** If grouping, set to the number of levels to expand by default, e.g. `0` for none, `1` for first level only, etc. Set to `-1` to expand everything.
     * @default 0
     */
    this.groupDefaultExpanded = undefined;
    /** Allows specifying the group 'auto column' if you are not happy with the default. If grouping, this column definition is included as the first column in the grid. If not grouping, this column is not included.
     */
    this.autoGroupColumnDef = undefined;
    /** When `true`, preserves the current group order when sorting on non-group columns.
     * @default false
     */
    this.groupMaintainOrder = undefined;
    /** When `true`, if you select a group, the children of the group will also be selected.
     * @default false
     * @deprecated v32.2 Use `rowSelection.groupSelects` instead
     */
    this.groupSelectsChildren = undefined;
    /** If grouping, locks the group settings of a number of columns, e.g. `0` for no group locking. `1` for first group column locked, `-1` for all group columns locked.
     * @default 0
     * @initial
     */
    this.groupLockGroupColumns = undefined;
    /** Set to determine whether filters should be applied on aggregated group values.
     * @default false
     */
    this.groupAggFiltering = undefined;
    /** When provided, an extra row group total row will be inserted into row groups at the specified position, to display
     * when the group is expanded. This row will contain the aggregate values for the group. If a callback function is
     * provided, it can be used to selectively determine which groups will have a total row added.
     */
    this.groupTotalRow = undefined;
    /** When provided, an extra grand total row will be inserted into the grid at the specified position.
     * This row displays the aggregate totals of all rows in the grid.
     */
    this.grandTotalRow = undefined;
    /** Suppress the sticky behaviour of the total rows, can be suppressed individually by passing `'grand'` or `'group'`.
     */
    this.suppressStickyTotalRow = undefined;
    /** If `true`, and showing footer, aggregate data will always be displayed at both the header and footer levels. This stops the possibly undesirable behaviour of the header details 'jumping' to the footer on expand.
     * @default false
     */
    this.groupSuppressBlankHeader = undefined;
    /** If using `groupSelectsChildren`, then only the children that pass the current filter will get selected.
     * @default false
     * @deprecated v32.2 Use `rowSelection.groupSelects` instead
     */
    this.groupSelectsFiltered = undefined;
    /** Shows the open group in the group column for non-group rows.
     * @default false
     */
    this.showOpenedGroup = undefined;
    /** Enable to display the child row in place of the group row when the group only has a single child.
     * @default false
     */
    this.groupHideParentOfSingleChild = undefined;
    /** Set to `true` to collapse groups that only have one child.
     * @default false
     * @deprecated v33.0.0 - use `groupHideParentOfSingleChild` instead.
     */
    this.groupRemoveSingleChildren = undefined;
    /** Set to `true` to collapse lowest level groups that only have one child.
     * @default false
     * @deprecated v33.0.0 - use `groupHideParentOfSingleChild: 'leafGroupsOnly'` instead.
     */
    this.groupRemoveLowestSingleChildren = undefined;
    /** Set to `true` to hide parents that are open. When used with multiple columns for showing groups, it can give a more pleasing user experience.
     * @default false
     */
    this.groupHideOpenParents = undefined;
    /** Set to `true` to prevent the grid from creating a '(Blanks)' group for nodes which do not belong to a group, and display the unbalanced nodes alongside group nodes.
     * @default false
     */
    this.groupAllowUnbalanced = undefined;
    /** When to show the 'row group panel' (where you drag rows to group) at the top.
     * @default 'never'
     */
    this.rowGroupPanelShow = undefined;
    /** Provide the Cell Renderer to use when `groupDisplayType = 'groupRows'`.
     * See [Group Row Cell Renderer](https://www.ag-grid.com/javascript-data-grid/grouping-group-rows/#providing-cell-renderer) for framework specific implementation details.
     */
    this.groupRowRenderer = undefined;
    /** Customise the parameters provided to the `groupRowRenderer` component.
     */
    this.groupRowRendererParams = undefined;
    /** Set to `true` to enable the Grid to work with Tree Data.
     * You must also implement the `getDataPath(data)` callback.
     * @default false
     */
    this.treeData = undefined;
    /** The name of the field to use in a data item to retrieve the array of children nodes of a node when while using treeData=true.
     * It supports accessing nested fields using the dot notation.
     */
    this.treeDataChildrenField = undefined;
    /** The name of the field to use in a data item to find the parent node of a node when using treeData=true.
     * The tree will be constructed via relationships between nodes using this field.
     * getRowId callback need to be provided as well for this to work.
     * It supports accessing nested fields using the dot notation.
     */
    this.treeDataParentIdField = undefined;
    /** Set to `true` to suppress sort indicators and actions from the row group panel.
     * @default false
     */
    this.rowGroupPanelSuppressSort = undefined;
    /** Set to `true` prevent Group Rows from sticking to the top of the grid.
     * @default false
     * @initial
     */
    this.suppressGroupRowsSticky = undefined;
    /** Data to be displayed as pinned top rows in the grid.
     */
    this.pinnedTopRowData = undefined;
    /** Data to be displayed as pinned bottom rows in the grid.
     */
    this.pinnedBottomRowData = undefined;
    /** Sets the row model type.
     * @default 'clientSide'
     * @initial
     */
    this.rowModelType = undefined;
    /** Set the data to be displayed as rows in the grid.
     */
    this.rowData = undefined;
    /** How many milliseconds to wait before executing a batch of async transactions.
     */
    this.asyncTransactionWaitMillis = undefined;
    /** Prevents Transactions changing sort, filter, group or pivot state when transaction only contains updates.
     * @default false
     */
    this.suppressModelUpdateAfterUpdateTransaction = undefined;
    /** Provide the datasource for infinite scrolling.
     */
    this.datasource = undefined;
    /** How many extra blank rows to display to the user at the end of the dataset, which sets the vertical scroll and then allows the grid to request viewing more rows of data.
     * @default 1
     * @initial
     */
    this.cacheOverflowSize = undefined;
    /** How many extra blank rows to display to the user at the end of the dataset, which sets the vertical scroll and then allows the grid to request viewing more rows of data.
     * @default 1
     * @initial
     */
    this.infiniteInitialRowCount = undefined;
    /** Set how many loading rows to display to the user for the root level group.
     * @default 1
     * @initial
     */
    this.serverSideInitialRowCount = undefined;
    /** When `true`, the Server-side Row Model will not use a full width loading renderer, instead using the colDef `loadingCellRenderer` if present.
     */
    this.suppressServerSideFullWidthLoadingRow = undefined;
    /** How many rows for each block in the store, i.e. how many rows returned from the server at a time.
     * @default 100
     */
    this.cacheBlockSize = undefined;
    /** How many blocks to keep in the store. Default is no limit, so every requested block is kept. Use this if you have memory concerns, and blocks that were least recently viewed will be purged when the limit is hit. The grid will additionally make sure it has all the blocks needed to display what is currently visible, in case this property is set to a low value.
     * @initial
     */
    this.maxBlocksInCache = undefined;
    /** How many requests to hit the server with concurrently. If the max is reached, requests are queued.
     * Set to `-1` for no maximum restriction on requests.
     * @default 2
     * @initial
     */
    this.maxConcurrentDatasourceRequests = undefined;
    /** How many milliseconds to wait before loading a block. Useful when scrolling over many blocks, as it prevents blocks loading until scrolling has settled.
     * @initial
     */
    this.blockLoadDebounceMillis = undefined;
    /** When enabled, closing group rows will remove children of that row. Next time the row is opened, child rows will be read from the datasource again. This property only applies when there is Row Grouping or Tree Data.
     * @default false
     */
    this.purgeClosedRowNodes = undefined;
    /** Provide the `serverSideDatasource` for server side row model.
     */
    this.serverSideDatasource = undefined;
    /** When enabled, always refreshes top level groups regardless of which column was sorted. This property only applies when there is Row Grouping & sorting is handled on the server.
     * @default false
     */
    this.serverSideSortAllLevels = undefined;
    /** When enabled, sorts fully loaded groups in the browser instead of requesting from the server.
     * @default false
     */
    this.serverSideEnableClientSideSort = undefined;
    /** When enabled, only refresh groups directly impacted by a filter. This property only applies when there is Row Grouping & filtering is handled on the server.
     * @default false
     * @initial
     */
    this.serverSideOnlyRefreshFilteredGroups = undefined;
    /** Used to split pivot field strings for generating pivot result columns when `pivotResultFields` is provided as part of a `getRows` success.
     * @default '_'
     * @initial
     */
    this.serverSidePivotResultFieldSeparator = undefined;
    /** To use the viewport row model you need to provide the grid with a `viewportDatasource`.
     */
    this.viewportDatasource = undefined;
    /** When using viewport row model, sets the page size for the viewport.
     * @initial
     */
    this.viewportRowModelPageSize = undefined;
    /** When using viewport row model, sets the buffer size for the viewport.
     * @initial
     */
    this.viewportRowModelBufferSize = undefined;
    /** Set to `true` to always show the horizontal scrollbar.
     * @default false
     */
    this.alwaysShowHorizontalScroll = undefined;
    /** Set to `true` to always show the vertical scrollbar.
     * @default false
     */
    this.alwaysShowVerticalScroll = undefined;
    /** Set to `true` to debounce the vertical scrollbar. Can provide smoother scrolling on slow machines.
     * @default false
     * @initial
     */
    this.debounceVerticalScrollbar = undefined;
    /** Set to `true` to never show the horizontal scroll. This is useful if the grid is aligned with another grid and will scroll when the other grid scrolls. (Should not be used in combination with `alwaysShowHorizontalScroll`.)
     * @default false
     */
    this.suppressHorizontalScroll = undefined;
    /** When `true`, the grid will not scroll to the top when new row data is provided. Use this if you don't want the default behaviour of scrolling to the top every time you load new data.
     * @default false
     */
    this.suppressScrollOnNewData = undefined;
    /** When `true`, the grid will not allow mousewheel / touchpad scroll when popup elements are present.
     * @default false
     */
    this.suppressScrollWhenPopupsAreOpen = undefined;
    /** When `true`, the grid will not use animation frames when drawing rows while scrolling. Use this if and only if the grid is working fast enough on all users machines and you want to avoid the temporarily empty rows.
     *     **Note:** It is not recommended to set suppressAnimationFrame to `true` in most use cases as this can seriously degrade the user experience as all cells are rendered synchronously blocking the UI thread from scrolling.
     * @default false
     * @initial
     */
    this.suppressAnimationFrame = undefined;
    /** If `true`, middle clicks will result in `click` events for cells and rows. Otherwise the browser will use middle click to scroll the grid.<br />**Note:** Not all browsers fire `click` events with the middle button. Most will fire only `mousedown` and `mouseup` events, which can be used to focus a cell, but will not work to call the `onCellClicked` function.
     * @default false
     */
    this.suppressMiddleClickScrolls = undefined;
    /** If `true`, mouse wheel events will be passed to the browser. Useful if your grid has no vertical scrolls and you want the mouse to scroll the browser page.
     * @default false
     * @initial
     */
    this.suppressPreventDefaultOnMouseWheel = undefined;
    /** Tell the grid how wide in pixels the scrollbar is, which is used in grid width calculations. Set only if using non-standard browser-provided scrollbars, so the grid can use the non-standard size in its calculations.
     * @initial
     */
    this.scrollbarWidth = undefined;
    /** Use the `RowSelectionOptions` object to configure row selection. The string values `'single'` and `'multiple'` are deprecated.
     */
    this.rowSelection = undefined;
    /** Configure cell selection
     */
    this.cellSelection = undefined;
    /** Set to `true` to allow multiple rows to be selected using single click.
     * @default false
     * @deprecated v32.2 Use `rowSelection.enableSelectionWithoutKeys` instead
     */
    this.rowMultiSelectWithClick = undefined;
    /** If `true`, rows will not be deselected if you hold down `Ctrl` and click the row or press `Space`.
     * @default false
     * @deprecated v32.2 Use `rowSelection.enableClickSelection` instead
     */
    this.suppressRowDeselection = undefined;
    /** If `true`, row selection won't happen when rows are clicked. Use when you only want checkbox selection.
     * @default false
     * @deprecated v32.2 Use `rowSelection.enableClickSelection` instead
     */
    this.suppressRowClickSelection = undefined;
    /** If `true`, cells won't be focusable. This means keyboard navigation will be disabled for grid cells, but remain enabled in other elements of the grid such as column headers, floating filters, tool panels.
     * @default false
     */
    this.suppressCellFocus = undefined;
    /** If `true`, header cells won't be focusable. This means keyboard navigation will be disabled for grid header cells, but remain enabled in other elements of the grid such as grid cells and tool panels.
     * @default false
     */
    this.suppressHeaderFocus = undefined;
    /** Configure the selection column, used for displaying checkboxes.
     *
     * Note that due to the nature of this column, this type is a subset of `ColDef`, which does not support several normal column features such as editing, pivoting and grouping.
     */
    this.selectionColumnDef = undefined;
    /** Configure the Row Numbers Feature.
     * @default false
     */
    this.rowNumbers = undefined;
    /** If `true`, only a single range can be selected.
     * @default false
     * @deprecated v32.2 Use `cellSelection.suppressMultiRanges` instead
     */
    this.suppressMultiRangeSelection = undefined;
    /** Set to `true` to be able to select the text within cells.
     *
     *     **Note:** When this is set to `true`, the clipboard service is disabled and only selected text is copied.
     * @default false
     */
    this.enableCellTextSelection = undefined;
    /** Set to `true` to enable Range Selection.
     * @default false
     * @deprecated v32.2 Use `cellSelection = true` instead
     */
    this.enableRangeSelection = undefined;
    /** Set to `true` to enable the Range Handle.
     * @default false
     * @deprecated v32.2 Use `cellSelection.handle` instead
     */
    this.enableRangeHandle = undefined;
    /** Set to `true` to enable the Fill Handle.
     * @default false
     * @deprecated v32.2 Use `cellSelection.handle` instead
     */
    this.enableFillHandle = undefined;
    /** Set to `'x'` to force the fill handle direction to horizontal, or set to `'y'` to force the fill handle direction to vertical.
     * @default 'xy'
     * @deprecated v32.2 Use `cellSelection.handle.direction` instead
     */
    this.fillHandleDirection = undefined;
    /** Set this to `true` to prevent cell values from being cleared when the Range Selection is reduced by the Fill Handle.
     * @default false
     * @deprecated v32.2 Use `cellSelection.suppressClearOnFillReduction` instead
     */
    this.suppressClearOnFillReduction = undefined;
    /** Array defining the order in which sorting occurs (if sorting is enabled). Values can be `'asc'`, `'desc'` or `null`. For example: `sortingOrder: ['asc', 'desc']`.
     * @default [null, 'asc', 'desc']
     * @deprecated v33 Use `defaultColDef.sortingOrder` instead
     */
    this.sortingOrder = undefined;
    /** Set to `true` to specify that the sort should take accented characters into account. If this feature is turned on the sort will be slower.
     * @default false
     */
    this.accentedSort = undefined;
    /** Set to `true` to show the 'no sort' icon.
     * @default false
     * @deprecated v33 Use `defaultColDef.unSortIcon` instead
     */
    this.unSortIcon = undefined;
    /** Set to `true` to suppress multi-sort when the user shift-clicks a column header.
     * @default false
     */
    this.suppressMultiSort = undefined;
    /** Set to `true` to always multi-sort when the user clicks a column header, regardless of key presses.
     * @default false
     */
    this.alwaysMultiSort = undefined;
    /** Set to `'ctrl'` to have multi sorting by clicking work using the `Ctrl` (or `Command ⌘` for Mac) key.
     */
    this.multiSortKey = undefined;
    /** Set to `true` to suppress sorting of un-sorted data to match original row data.
     * @default false
     */
    this.suppressMaintainUnsortedOrder = undefined;
    /** Icons to use inside the grid instead of the grid's default icons.
     * @initial
     */
    this.icons = undefined;
    /** Default row height in pixels.
     * @default 25
     */
    this.rowHeight = undefined;
    /** The style properties to apply to all rows. Set to an object of key (style names) and values (style values).
     */
    this.rowStyle = undefined;
    /** CSS class(es) for all rows. Provide either a string (class name) or array of strings (array of class names).
     */
    this.rowClass = undefined;
    /** Rules which can be applied to include certain CSS classes.
     */
    this.rowClassRules = undefined;
    /** Set to `true` to not highlight rows by adding the `ag-row-hover` CSS class.
     * @default false
     */
    this.suppressRowHoverHighlight = undefined;
    /** Uses CSS `top` instead of CSS `transform` for positioning rows. Useful if the transform function is causing issues such as used in row spanning.
     * @default false
     * @initial
     */
    this.suppressRowTransform = undefined;
    /** Set to `true` to highlight columns by adding the `ag-column-hover` CSS class.
     * @default false
     */
    this.columnHoverHighlight = undefined;
    /** Provide a custom `gridId` for this instance of the grid. Value will be set on the root DOM node using the attribute `grid-id` as well as being accessible via the `gridApi.getGridId()` method.
     * @initial
     */
    this.gridId = undefined;
    /** When enabled, sorts only the rows added/updated by a transaction.
     * @default false
     */
    this.deltaSort = undefined;
    /**/
    this.treeDataDisplayType = undefined;
    /** @initial
     */
    this.enableGroupEdit = undefined;
    /** Initial state for the grid. Only read once on initialization. Can be used in conjunction with `api.getState()` to save and restore grid state.
     * @initial
     */
    this.initialState = undefined;
    /** Theme to apply to the grid, or the string "legacy" to opt back into the
     * v32 style of theming where themes were imported as CSS files and applied
     * by setting a class name on the parent element.
     *
     * @default themeQuartz
     */
    this.theme = undefined;
    /** If your theme uses a font that is available on Google Fonts, pass true to load it from Google's CDN.
     */
    this.loadThemeGoogleFonts = undefined;
    /** The CSS layer that this theme should be rendered onto. If your
     * application loads its styles into a CSS layer, use this to load the grid
     * styles into a previous layer so that application styles can override grid
     * styles.
     *
     * @see https://developer.mozilla.org/en-US/docs/Web/CSS/@layer
     */
    this.themeCssLayer = undefined;
    /** The nonce attribute to set on style elements added to the document by
     * themes. If "foo" is passed to this property, the grid can use the Content
     * Security Policy `style-src 'nonce-foo'`, instead of the less secure
     * `style-src 'unsafe-inline'`.
     *
     * Note: CSP nonces are global to a page, where a page has multiple grids,
     * every one must have the same styleNonce set.
     */
    this.styleNonce = undefined;
    /** An element to insert style elements into when injecting styles into the
     * grid. If undefined, styles will be added to the document head for grids
     * rendered in the main document fragment, or to the grid wrapper element
     * for other grids (e.g. those rendered in a shadow DOM or detached from the
     * document).
     *
     * @initial
     */
    this.themeStyleContainer = undefined;
    /** For customising the context menu.
     */
    this.getContextMenuItems = undefined;
    /** For customising the main 'column header' menu.
     * @initial
     */
    this.getMainMenuItems = undefined;
    /** Allows user to process popups after they are created. Applications can use this if they want to, for example, reposition the popup.
     */
    this.postProcessPopup = undefined;
    /** Allows the user to process the columns being removed from the pinned section because the viewport is too small to accommodate them.
     * Returns an array of columns to be removed from the pinned areas.
     * @initial
     */
    this.processUnpinnedColumns = undefined;
    /** Allows you to process cells for the clipboard. Handy if for example you have `Date` objects that need to have a particular format if importing into Excel.
     */
    this.processCellForClipboard = undefined;
    /** Allows you to process header values for the clipboard.
     */
    this.processHeaderForClipboard = undefined;
    /** Allows you to process group header values for the clipboard.
     */
    this.processGroupHeaderForClipboard = undefined;
    /** Allows you to process cells from the clipboard. Handy if for example you have number fields and want to block non-numbers from getting into the grid.
     */
    this.processCellFromClipboard = undefined;
    /** Allows you to get the data that would otherwise go to the clipboard. To be used when you want to control the 'copy to clipboard' operation yourself.
     */
    this.sendToClipboard = undefined;
    /** Allows complete control of the paste operation, including cancelling the operation (so nothing happens) or replacing the data with other data.
     */
    this.processDataFromClipboard = undefined;
    /** Grid calls this method to know if an external filter is present.
     */
    this.isExternalFilterPresent = undefined;
    /** Should return `true` if external filter passes, otherwise `false`.
     */
    this.doesExternalFilterPass = undefined;
    /** Callback to be used to customise the chart toolbar items.
     * @initial
     */
    this.getChartToolbarItems = undefined;
    /** Callback to enable displaying the chart in an alternative chart container.
     * @initial
     */
    this.createChartContainer = undefined;
    /** Allows overriding the element that will be focused when the grid receives focus from outside elements (tabbing into the grid).
     * @returns `True` if this function should override the grid's default behavior, `False` to allow the grid's default behavior.
     */
    this.focusGridInnerElement = undefined;
    /** Allows overriding the default behaviour for when user hits navigation (arrow) key when a header is focused. Return the next Header position to navigate to or `null` to stay on current header.
     */
    this.navigateToNextHeader = undefined;
    /** Allows overriding the default behaviour for when user hits `Tab` key when a header is focused.
     * Return the next header position to navigate to, `true` to stay on the current header,
     * or `false` to let the browser handle the tab behaviour.
     */
    this.tabToNextHeader = undefined;
    /** Allows overriding the default behaviour for when user hits navigation (arrow) key when a cell is focused. Return the next Cell position to navigate to or `null` to stay on current cell.
     */
    this.navigateToNextCell = undefined;
    /** Allows overriding the default behaviour for when user hits `Tab` key when a cell is focused.
     * Return the next cell position to navigate to, `true` to stay on the current cell,
     * or `false` to let the browser handle the tab behaviour.
     */
    this.tabToNextCell = undefined;
    /** A callback for localising text within the grid.
     * @initial
     */
    this.getLocaleText = undefined;
    /** Allows overriding what `document` is used. Currently used by Drag and Drop (may extend to other places in the future). Use this when you want the grid to use a different `document` than the one available on the global scope. This can happen if docking out components (something which Electron supports)
     */
    this.getDocument = undefined;
    /** Allows user to format the numbers in the pagination panel, i.e. 'row count' and 'page number' labels. This is for pagination panel only, to format numbers inside the grid's cells (i.e. your data), then use `valueFormatter` in the column definitions.
     * @initial
     */
    this.paginationNumberFormatter = undefined;
    /** Callback to use when you need access to more then the current column for aggregation.
     */
    this.getGroupRowAgg = undefined;
    /** (Client-side Row Model only) Allows groups to be open by default.
     */
    this.isGroupOpenByDefault = undefined;
    /** Allows default sorting of groups.
     */
    this.initialGroupOrderComparator = undefined;
    /** Callback for the mutation of the generated pivot result column definitions
     */
    this.processPivotResultColDef = undefined;
    /** Callback for the mutation of the generated pivot result column group definitions
     */
    this.processPivotResultColGroupDef = undefined;
    /** Callback to be used when working with Tree Data when `treeData = true`.
     * @initial
     */
    this.getDataPath = undefined;
    /** Allows setting the child count for a group row.
     * @initial
     */
    this.getChildCount = undefined;
    /** Allows providing different params for different levels of grouping.
     * @initial
     */
    this.getServerSideGroupLevelParams = undefined;
    /** Allows groups to be open by default.
     */
    this.isServerSideGroupOpenByDefault = undefined;
    /** Allows cancelling transactions.
     */
    this.isApplyServerSideTransaction = undefined;
    /** SSRM Tree Data: Allows specifying which rows are expandable.
     */
    this.isServerSideGroup = undefined;
    /** SSRM Tree Data: Allows specifying group keys.
     */
    this.getServerSideGroupKey = undefined;
    /** Return a business key for the node. If implemented, each row in the DOM will have an attribute `row-business-key='abc'` where `abc` is what you return as the business key.
     * This is useful for automated testing, as it provides a way for your tool to identify rows based on unique business keys.
     */
    this.getBusinessKeyForNode = undefined;
    /** Provide a pure function that returns a string ID to uniquely identify a given row. This enables the grid to work optimally with data changes and updates.
     * @initial
     */
    this.getRowId = undefined;
    /** When enabled, getRowId() callback is implemented and new Row Data is set, the grid will disregard all previous rows and treat the new Row Data as new data. As a consequence, all Row State (eg selection, rendered rows) will be reset.
     * @default false
     */
    this.resetRowDataOnUpdate = undefined;
    /** Callback fired after the row is rendered into the DOM. Should not be used to initiate side effects.
     */
    this.processRowPostCreate = undefined;
    /** Callback to be used to determine which rows are selectable. By default rows are selectable, so return `false` to make a row un-selectable.
     * @deprecated v32.2 Use `rowSelection.isRowSelectable` instead
     */
    this.isRowSelectable = undefined;
    /** Callback to be used with Master Detail to determine if a row should be a master row. If `false` is returned no detail row will exist for this row.
     */
    this.isRowMaster = undefined;
    /** Callback to fill values instead of simply copying values or increasing number values using linear progression.
     * @deprecated v32.2 Use `cellSelection.handle.setFillValue` instead
     */
    this.fillOperation = undefined;
    /** Callback to perform additional sorting after the grid has sorted the rows.
     */
    this.postSortRows = undefined;
    /** Callback version of property `rowStyle` to set style for each row individually. Function should return an object of CSS values or undefined for no styles.
     */
    this.getRowStyle = undefined;
    /** Callback version of property `rowClass` to set class(es) for each row individually. Function should return either a string (class name), array of strings (array of class names) or undefined for no class.
     */
    this.getRowClass = undefined;
    /** Callback version of property `rowHeight` to set height for each row individually. Function should return a positive number of pixels, or return `null`/`undefined` to use the default row height.
     */
    this.getRowHeight = undefined;
    /** Tells the grid if this row should be rendered as full width.
     */
    this.isFullWidthRow = undefined;
    /** The tool panel visibility has changed. Fires twice if switching between panels - once with the old panel and once with the new panel.
     */
    this.toolPanelVisibleChanged = new EventEmitter();
    /** The tool panel size has been changed.
     */
    this.toolPanelSizeChanged = new EventEmitter();
    /** The column menu visibility has changed. Fires twice if switching between tabs - once with the old tab and once with the new tab.
     */
    this.columnMenuVisibleChanged = new EventEmitter();
    /** The context menu visibility has changed (opened or closed).
     */
    this.contextMenuVisibleChanged = new EventEmitter();
    /** Cut operation has started.
     */
    this.cutStart = new EventEmitter();
    /** Cut operation has ended.
     */
    this.cutEnd = new EventEmitter();
    /** Paste operation has started.
     */
    this.pasteStart = new EventEmitter();
    /** Paste operation has ended.
     */
    this.pasteEnd = new EventEmitter();
    /** A column, or group of columns, was hidden / shown.
     */
    this.columnVisible = new EventEmitter();
    /** A column, or group of columns, was pinned / unpinned.
     */
    this.columnPinned = new EventEmitter();
    /** A column was resized.
     */
    this.columnResized = new EventEmitter();
    /** A column was moved.
     */
    this.columnMoved = new EventEmitter();
    /** A value column was added or removed.
     */
    this.columnValueChanged = new EventEmitter();
    /** The pivot mode flag was changed.
     */
    this.columnPivotModeChanged = new EventEmitter();
    /** A pivot column was added, removed or order changed.
     */
    this.columnPivotChanged = new EventEmitter();
    /** A column group was opened / closed.
     */
    this.columnGroupOpened = new EventEmitter();
    /** User set new columns.
     */
    this.newColumnsLoaded = new EventEmitter();
    /** The list of grid columns changed.
     */
    this.gridColumnsChanged = new EventEmitter();
    /** The list of displayed columns changed. This can result from columns open / close, column move, pivot, group, etc.
     */
    this.displayedColumnsChanged = new EventEmitter();
    /** The list of rendered columns changed (only columns in the visible scrolled viewport are rendered by default).
     */
    this.virtualColumnsChanged = new EventEmitter();
    /** @deprecated v32.2 Either use `onDisplayedColumnsChanged` which is fired at the same time,
     * or use one of the more specific column events.
     */
    this.columnEverythingChanged = new EventEmitter();
    /** A mouse cursor is initially moved over a column header.
     */
    this.columnHeaderMouseOver = new EventEmitter();
    /** A mouse cursor is moved out of a column header.
     */
    this.columnHeaderMouseLeave = new EventEmitter();
    /** A click is performed on a column header.
     */
    this.columnHeaderClicked = new EventEmitter();
    /** A context menu action, such as right-click or context menu key press, is performed on a column header.
     */
    this.columnHeaderContextMenu = new EventEmitter();
    /** Only used by Angular, React and VueJS AG Grid components (not used if doing plain JavaScript).
     * If the grid receives changes due to bound properties, this event fires after the grid has finished processing the change.
     */
    this.componentStateChanged = new EventEmitter();
    /** Value has changed after editing (this event will not fire if editing was cancelled, eg ESC was pressed) or
     *  if cell value has changed as a result of cut, paste, cell clear (pressing Delete key),
     * fill handle, copy range down, undo and redo.
     */
    this.cellValueChanged = new EventEmitter();
    /** Value has changed after editing. Only fires when `readOnlyEdit=true`.
     */
    this.cellEditRequest = new EventEmitter();
    /** A cell's value within a row has changed. This event corresponds to Full Row Editing only.
     */
    this.rowValueChanged = new EventEmitter();
    /** Editing a cell has started.
     */
    this.cellEditingStarted = new EventEmitter();
    /** Editing a cell has stopped.
     */
    this.cellEditingStopped = new EventEmitter();
    /** Editing a row has started (when row editing is enabled). When row editing, this event will be fired once and `cellEditingStarted` will be fired for each individual cell. Only fires when doing Full Row Editing.
     */
    this.rowEditingStarted = new EventEmitter();
    /** Editing a row has stopped (when row editing is enabled). When row editing, this event will be fired once and `cellEditingStopped` will be fired for each individual cell. Only fires when doing Full Row Editing.
     */
    this.rowEditingStopped = new EventEmitter();
    /** Undo operation has started.
     */
    this.undoStarted = new EventEmitter();
    /** Undo operation has ended.
     */
    this.undoEnded = new EventEmitter();
    /** Redo operation has started.
     */
    this.redoStarted = new EventEmitter();
    /** Redo operation has ended.
     */
    this.redoEnded = new EventEmitter();
    /** Cell selection delete operation (cell clear) has started.
     */
    this.cellSelectionDeleteStart = new EventEmitter();
    /** Cell selection delete operation (cell clear) has ended.
     */
    this.cellSelectionDeleteEnd = new EventEmitter();
    /** Range delete operation (cell clear) has started.
     *
     * @deprecated v32.2 Use `onCellSelectionDeleteStart` instead
     */
    this.rangeDeleteStart = new EventEmitter();
    /** Range delete operation (cell clear) has ended.
     *
     * @deprecated v32.2 Use `onCellSelectionDeleteEnd` instead
     */
    this.rangeDeleteEnd = new EventEmitter();
    /** Fill operation has started.
     */
    this.fillStart = new EventEmitter();
    /** Fill operation has ended.
     */
    this.fillEnd = new EventEmitter();
    /** Filter has been opened.
     */
    this.filterOpened = new EventEmitter();
    /** Filter has been modified and applied.
     */
    this.filterChanged = new EventEmitter();
    /** Filter was modified but not applied. Used when filters have 'Apply' buttons.
     */
    this.filterModified = new EventEmitter();
    /** Advanced Filter Builder visibility has changed (opened or closed).
     */
    this.advancedFilterBuilderVisibleChanged = new EventEmitter();
    /** Find details have changed (e.g. Find search value, active match, or updates to grid cells).
     */
    this.findChanged = new EventEmitter();
    /** A chart has been created.
     */
    this.chartCreated = new EventEmitter();
    /** The data range for the chart has been changed.
     */
    this.chartRangeSelectionChanged = new EventEmitter();
    /** Formatting changes have been made by users through the Customize Panel.
     */
    this.chartOptionsChanged = new EventEmitter();
    /** A chart has been destroyed.
     */
    this.chartDestroyed = new EventEmitter();
    /** DOM event `keyDown` happened on a cell.
     */
    this.cellKeyDown = new EventEmitter();
    /** The grid has initialised and is ready for most api calls, but may not be fully rendered yet      */
    this.gridReady = new EventEmitter();
    /** Fired the first time data is rendered into the grid. Use this event if you want to auto resize columns based on their contents     */
    this.firstDataRendered = new EventEmitter();
    /** The size of the grid `div` has changed. In other words, the grid was resized.
     */
    this.gridSizeChanged = new EventEmitter();
    /** Displayed rows have changed. Triggered after sort, filter or tree expand / collapse events.
     */
    this.modelUpdated = new EventEmitter();
    /** A row was removed from the DOM, for any reason. Use to clean up resources (if any) used by the row.
     */
    this.virtualRowRemoved = new EventEmitter();
    /** Which rows are rendered in the DOM has changed.
     */
    this.viewportChanged = new EventEmitter();
    /** The body was scrolled horizontally or vertically.
     */
    this.bodyScroll = new EventEmitter();
    /** Main body of the grid has stopped scrolling, either horizontally or vertically.
     */
    this.bodyScrollEnd = new EventEmitter();
    /** When dragging starts. This could be any action that uses the grid's Drag and Drop service, e.g. Column Moving, Column Resizing, Range Selection, Fill Handle, etc.
     */
    this.dragStarted = new EventEmitter();
    /** When dragging stops. This could be any action that uses the grid's Drag and Drop service, e.g. Column Moving, Column Resizing, Range Selection, Fill Handle, etc.
     */
    this.dragStopped = new EventEmitter();
    /** When dragging is cancelled stops. This is caused by pressing `Escape` while dragging elements within the grid that uses the grid's Drag and Drop service, e.g. Column Moving, Column Resizing, Range Selection, Fill Handle, etc.
     */
    this.dragCancelled = new EventEmitter();
    /** Grid state has been updated.
     */
    this.stateUpdated = new EventEmitter();
    /** Triggered every time the paging state changes. Some of the most common scenarios for this event to be triggered are:
     *
     *  - The page size changes.
     *  - The current shown page is changed.
     *  - New data is loaded onto the grid.
     */
    this.paginationChanged = new EventEmitter();
    /** A drag has started, or dragging was already started and the mouse has re-entered the grid having previously left the grid.
     */
    this.rowDragEnter = new EventEmitter();
    /** The mouse has moved while dragging.
     */
    this.rowDragMove = new EventEmitter();
    /** The mouse has left the grid while dragging.
     */
    this.rowDragLeave = new EventEmitter();
    /** The drag has finished over the grid.
     */
    this.rowDragEnd = new EventEmitter();
    /** The drag has been cancelled over the grid.
     */
    this.rowDragCancel = new EventEmitter();
    /** A row group column was added, removed or reordered.
     */
    this.columnRowGroupChanged = new EventEmitter();
    /** A row group was opened or closed.
     */
    this.rowGroupOpened = new EventEmitter();
    /** Fired when calling either of the API methods `expandAll()` or `collapseAll()`.
     */
    this.expandOrCollapseAll = new EventEmitter();
    /** Exceeded the `pivotMaxGeneratedColumns` limit when generating columns.
     */
    this.pivotMaxColumnsExceeded = new EventEmitter();
    /** The client has set new pinned row data into the grid.
     */
    this.pinnedRowDataChanged = new EventEmitter();
    /** Client-Side Row Model only. The client has updated data for the grid by either a) setting new Row Data or b) Applying a Row Transaction.
     */
    this.rowDataUpdated = new EventEmitter();
    /** Async transactions have been applied. Contains a list of all transaction results.
     */
    this.asyncTransactionsFlushed = new EventEmitter();
    /** A server side store has finished refreshing.
     */
    this.storeRefreshed = new EventEmitter();
    /** Header is focused.
     */
    this.headerFocused = new EventEmitter();
    /** Cell is clicked.
     */
    this.cellClicked = new EventEmitter();
    /** Cell is double clicked.
     */
    this.cellDoubleClicked = new EventEmitter();
    /** Cell is focused.
     */
    this.cellFocused = new EventEmitter();
    /** Mouse entered cell.
     */
    this.cellMouseOver = new EventEmitter();
    /** Mouse left cell.
     */
    this.cellMouseOut = new EventEmitter();
    /** Mouse down on cell.
     */
    this.cellMouseDown = new EventEmitter();
    /** Row is clicked.
     */
    this.rowClicked = new EventEmitter();
    /** Row is double clicked.
     */
    this.rowDoubleClicked = new EventEmitter();
    /** Row is selected or deselected. The event contains the node in question, so call the node's `isSelected()` method to see if it was just selected or deselected.
     */
    this.rowSelected = new EventEmitter();
    /** Row selection is changed. Use the `selectedNodes` field to get the list of selected nodes at the time of the event. When using the SSRM, `selectedNodes` will be `null`
     * when selecting all nodes. Instead, refer to the `serverSideState` field.
     */
    this.selectionChanged = new EventEmitter();
    /** Cell is right clicked.
     */
    this.cellContextMenu = new EventEmitter();
    /** A change to range selection has occurred.
     *
     * @deprecated v32.2 Use `onCellSelectionChanged` instead
     */
    this.rangeSelectionChanged = new EventEmitter();
    /** A change to cell selection has occurred.
     */
    this.cellSelectionChanged = new EventEmitter();
    /** A tooltip has been displayed     */
    this.tooltipShow = new EventEmitter();
    /** A tooltip was hidden     */
    this.tooltipHide = new EventEmitter();
    /** Sort has changed. The grid also listens for this and updates the model.
     */
    this.sortChanged = new EventEmitter();
    this._nativeElement = elementDef.nativeElement;
    this._fullyReady.then(() => {
      // Register the status flag reset before any events are fired
      // so that we can swap to synchronous event firing as soon as the grid is ready
      this._holdEvents = false;
    });
  }
  ngAfterViewInit() {
    // Run the setup outside of angular so all the event handlers that are created do not trigger change detection
    this._angularFrameworkOverrides.runOutsideAngular(() => {
      this._frameworkCompWrapper.setViewContainerRef(this._viewContainerRef, this._angularFrameworkOverrides);
      // Get all the inputs that are valid GridOptions
      const gridOptionKeys = Object.keys(this).filter(key => !(key.startsWith('_') || key == 'gridOptions' || key == 'modules' || this[key] instanceof EventEmitter));
      const coercedGridOptions = {};
      gridOptionKeys.forEach(key => {
        const valueToUse = getValueOrCoercedValue(key, this[key]);
        coercedGridOptions[key] = valueToUse;
      });
      const mergedGridOps = _combineAttributesAndGridOptions(this.gridOptions, coercedGridOptions, gridOptionKeys);
      const gridParams = {
        globalListener: this.globalListener.bind(this),
        frameworkOverrides: this._angularFrameworkOverrides,
        providedBeanInstances: {
          frameworkCompWrapper: this._frameworkCompWrapper
        },
        modules: this.modules || [],
        setThemeOnGridDiv: true
      };
      const api = createGrid(this._nativeElement, mergedGridOps, gridParams);
      if (api) {
        this.api = api;
      }
      this._initialised = true;
      // sometimes, especially in large client apps gridReady can fire before ngAfterViewInit
      // this ties these together so that gridReady will always fire after agGridAngular's ngAfterViewInit
      // the actual containing component's ngAfterViewInit will fire just after agGridAngular's
      this._resolveFullyReady();
    });
  }
  ngOnChanges(changes) {
    if (this._initialised) {
      // Run the changes outside of angular so any event handlers that are created do not trigger change detection
      this._angularFrameworkOverrides.runOutsideAngular(() => {
        const gridOptions = {};
        for (const key of Object.keys(changes)) {
          const value = changes[key];
          gridOptions[key] = value.currentValue;
        }
        _processOnChange(gridOptions, this.api);
      });
    }
  }
  ngOnDestroy() {
    if (this._initialised) {
      // need to do this before the destroy, so we know not to emit any events
      // while tearing down the grid.
      this._destroyed = true;
      // could be null if grid failed to initialise
      this.api?.destroy();
    }
  }
  // we'll emit the emit if a user is listening for a given event either on the component via normal angular binding
  // or via gridOptions
  isEmitterUsed(eventType) {
    const emitter = this[eventType];
    // For RxJs compatibility we need to check for observed v7+ or observers v6
    const emitterAny = emitter;
    const hasEmitter = emitterAny?.observed ?? emitterAny?.observers?.length > 0;
    // gridReady => onGridReady
    const asEventName = `on${eventType.charAt(0).toUpperCase()}${eventType.substring(1)}`;
    const hasGridOptionListener = !!this.gridOptions && !!this.gridOptions[asEventName];
    return hasEmitter || hasGridOptionListener;
  }
  globalListener(eventType, event) {
    // if we are tearing down, don't emit angular events, as this causes
    // problems with the angular router
    if (this._destroyed) {
      return;
    }
    // generically look up the eventType
    const emitter = this[eventType];
    if (emitter && this.isEmitterUsed(eventType)) {
      // Make sure we emit within the angular zone, so change detection works properly
      const fireEmitter = () => this._angularFrameworkOverrides.runInsideAngular(() => emitter.emit(event));
      if (this._holdEvents) {
        // if the user is listening to events, wait for ngAfterViewInit to fire first, then emit the grid events
        this._fullyReady.then(() => fireEmitter());
      } else {
        fireEmitter();
      }
    }
  }
  static {
    this.ɵfac = function AgGridAngular_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || AgGridAngular)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.ViewContainerRef), i0.ɵɵdirectiveInject(AngularFrameworkOverrides), i0.ɵɵdirectiveInject(AngularFrameworkComponentWrapper));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: AgGridAngular,
      selectors: [["ag-grid-angular"]],
      inputs: {
        gridOptions: "gridOptions",
        modules: "modules",
        statusBar: "statusBar",
        sideBar: "sideBar",
        suppressContextMenu: [2, "suppressContextMenu", "suppressContextMenu", booleanAttribute],
        preventDefaultOnContextMenu: [2, "preventDefaultOnContextMenu", "preventDefaultOnContextMenu", booleanAttribute],
        allowContextMenuWithControlKey: [2, "allowContextMenuWithControlKey", "allowContextMenuWithControlKey", booleanAttribute],
        columnMenu: "columnMenu",
        suppressMenuHide: [2, "suppressMenuHide", "suppressMenuHide", booleanAttribute],
        enableBrowserTooltips: [2, "enableBrowserTooltips", "enableBrowserTooltips", booleanAttribute],
        tooltipTrigger: "tooltipTrigger",
        tooltipShowDelay: "tooltipShowDelay",
        tooltipHideDelay: "tooltipHideDelay",
        tooltipMouseTrack: [2, "tooltipMouseTrack", "tooltipMouseTrack", booleanAttribute],
        tooltipShowMode: "tooltipShowMode",
        tooltipInteraction: [2, "tooltipInteraction", "tooltipInteraction", booleanAttribute],
        popupParent: "popupParent",
        copyHeadersToClipboard: [2, "copyHeadersToClipboard", "copyHeadersToClipboard", booleanAttribute],
        copyGroupHeadersToClipboard: [2, "copyGroupHeadersToClipboard", "copyGroupHeadersToClipboard", booleanAttribute],
        clipboardDelimiter: "clipboardDelimiter",
        suppressCopyRowsToClipboard: [2, "suppressCopyRowsToClipboard", "suppressCopyRowsToClipboard", booleanAttribute],
        suppressCopySingleCellRanges: [2, "suppressCopySingleCellRanges", "suppressCopySingleCellRanges", booleanAttribute],
        suppressLastEmptyLineOnPaste: [2, "suppressLastEmptyLineOnPaste", "suppressLastEmptyLineOnPaste", booleanAttribute],
        suppressClipboardPaste: [2, "suppressClipboardPaste", "suppressClipboardPaste", booleanAttribute],
        suppressClipboardApi: [2, "suppressClipboardApi", "suppressClipboardApi", booleanAttribute],
        suppressCutToClipboard: [2, "suppressCutToClipboard", "suppressCutToClipboard", booleanAttribute],
        columnDefs: "columnDefs",
        defaultColDef: "defaultColDef",
        defaultColGroupDef: "defaultColGroupDef",
        columnTypes: "columnTypes",
        dataTypeDefinitions: "dataTypeDefinitions",
        maintainColumnOrder: [2, "maintainColumnOrder", "maintainColumnOrder", booleanAttribute],
        enableStrictPivotColumnOrder: [2, "enableStrictPivotColumnOrder", "enableStrictPivotColumnOrder", booleanAttribute],
        suppressFieldDotNotation: [2, "suppressFieldDotNotation", "suppressFieldDotNotation", booleanAttribute],
        headerHeight: "headerHeight",
        groupHeaderHeight: "groupHeaderHeight",
        floatingFiltersHeight: "floatingFiltersHeight",
        pivotHeaderHeight: "pivotHeaderHeight",
        pivotGroupHeaderHeight: "pivotGroupHeaderHeight",
        allowDragFromColumnsToolPanel: [2, "allowDragFromColumnsToolPanel", "allowDragFromColumnsToolPanel", booleanAttribute],
        suppressMovableColumns: [2, "suppressMovableColumns", "suppressMovableColumns", booleanAttribute],
        suppressColumnMoveAnimation: [2, "suppressColumnMoveAnimation", "suppressColumnMoveAnimation", booleanAttribute],
        suppressMoveWhenColumnDragging: [2, "suppressMoveWhenColumnDragging", "suppressMoveWhenColumnDragging", booleanAttribute],
        suppressDragLeaveHidesColumns: [2, "suppressDragLeaveHidesColumns", "suppressDragLeaveHidesColumns", booleanAttribute],
        suppressGroupChangesColumnVisibility: "suppressGroupChangesColumnVisibility",
        suppressMakeColumnVisibleAfterUnGroup: [2, "suppressMakeColumnVisibleAfterUnGroup", "suppressMakeColumnVisibleAfterUnGroup", booleanAttribute],
        suppressRowGroupHidesColumns: [2, "suppressRowGroupHidesColumns", "suppressRowGroupHidesColumns", booleanAttribute],
        colResizeDefault: "colResizeDefault",
        suppressAutoSize: [2, "suppressAutoSize", "suppressAutoSize", booleanAttribute],
        autoSizePadding: "autoSizePadding",
        skipHeaderOnAutoSize: [2, "skipHeaderOnAutoSize", "skipHeaderOnAutoSize", booleanAttribute],
        autoSizeStrategy: "autoSizeStrategy",
        components: "components",
        editType: "editType",
        singleClickEdit: [2, "singleClickEdit", "singleClickEdit", booleanAttribute],
        suppressClickEdit: [2, "suppressClickEdit", "suppressClickEdit", booleanAttribute],
        readOnlyEdit: [2, "readOnlyEdit", "readOnlyEdit", booleanAttribute],
        stopEditingWhenCellsLoseFocus: [2, "stopEditingWhenCellsLoseFocus", "stopEditingWhenCellsLoseFocus", booleanAttribute],
        enterNavigatesVertically: [2, "enterNavigatesVertically", "enterNavigatesVertically", booleanAttribute],
        enterNavigatesVerticallyAfterEdit: [2, "enterNavigatesVerticallyAfterEdit", "enterNavigatesVerticallyAfterEdit", booleanAttribute],
        enableCellEditingOnBackspace: [2, "enableCellEditingOnBackspace", "enableCellEditingOnBackspace", booleanAttribute],
        undoRedoCellEditing: [2, "undoRedoCellEditing", "undoRedoCellEditing", booleanAttribute],
        undoRedoCellEditingLimit: "undoRedoCellEditingLimit",
        defaultCsvExportParams: "defaultCsvExportParams",
        suppressCsvExport: [2, "suppressCsvExport", "suppressCsvExport", booleanAttribute],
        defaultExcelExportParams: "defaultExcelExportParams",
        suppressExcelExport: [2, "suppressExcelExport", "suppressExcelExport", booleanAttribute],
        excelStyles: "excelStyles",
        findSearchValue: "findSearchValue",
        findOptions: "findOptions",
        quickFilterText: "quickFilterText",
        cacheQuickFilter: [2, "cacheQuickFilter", "cacheQuickFilter", booleanAttribute],
        includeHiddenColumnsInQuickFilter: [2, "includeHiddenColumnsInQuickFilter", "includeHiddenColumnsInQuickFilter", booleanAttribute],
        quickFilterParser: "quickFilterParser",
        quickFilterMatcher: "quickFilterMatcher",
        applyQuickFilterBeforePivotOrAgg: [2, "applyQuickFilterBeforePivotOrAgg", "applyQuickFilterBeforePivotOrAgg", booleanAttribute],
        excludeChildrenWhenTreeDataFiltering: [2, "excludeChildrenWhenTreeDataFiltering", "excludeChildrenWhenTreeDataFiltering", booleanAttribute],
        enableAdvancedFilter: [2, "enableAdvancedFilter", "enableAdvancedFilter", booleanAttribute],
        alwaysPassFilter: "alwaysPassFilter",
        includeHiddenColumnsInAdvancedFilter: [2, "includeHiddenColumnsInAdvancedFilter", "includeHiddenColumnsInAdvancedFilter", booleanAttribute],
        advancedFilterParent: "advancedFilterParent",
        advancedFilterBuilderParams: "advancedFilterBuilderParams",
        suppressAdvancedFilterEval: [2, "suppressAdvancedFilterEval", "suppressAdvancedFilterEval", booleanAttribute],
        suppressSetFilterByDefault: [2, "suppressSetFilterByDefault", "suppressSetFilterByDefault", booleanAttribute],
        enableCharts: [2, "enableCharts", "enableCharts", booleanAttribute],
        chartThemes: "chartThemes",
        customChartThemes: "customChartThemes",
        chartThemeOverrides: "chartThemeOverrides",
        chartToolPanelsDef: "chartToolPanelsDef",
        chartMenuItems: "chartMenuItems",
        loadingCellRenderer: "loadingCellRenderer",
        loadingCellRendererParams: "loadingCellRendererParams",
        loadingCellRendererSelector: "loadingCellRendererSelector",
        localeText: "localeText",
        masterDetail: [2, "masterDetail", "masterDetail", booleanAttribute],
        keepDetailRows: [2, "keepDetailRows", "keepDetailRows", booleanAttribute],
        keepDetailRowsCount: "keepDetailRowsCount",
        detailCellRenderer: "detailCellRenderer",
        detailCellRendererParams: "detailCellRendererParams",
        detailRowHeight: "detailRowHeight",
        detailRowAutoHeight: [2, "detailRowAutoHeight", "detailRowAutoHeight", booleanAttribute],
        context: "context",
        dragAndDropImageComponent: "dragAndDropImageComponent",
        dragAndDropImageComponentParams: "dragAndDropImageComponentParams",
        alignedGrids: "alignedGrids",
        tabIndex: "tabIndex",
        rowBuffer: "rowBuffer",
        valueCache: [2, "valueCache", "valueCache", booleanAttribute],
        valueCacheNeverExpires: [2, "valueCacheNeverExpires", "valueCacheNeverExpires", booleanAttribute],
        enableCellExpressions: [2, "enableCellExpressions", "enableCellExpressions", booleanAttribute],
        suppressTouch: [2, "suppressTouch", "suppressTouch", booleanAttribute],
        suppressFocusAfterRefresh: [2, "suppressFocusAfterRefresh", "suppressFocusAfterRefresh", booleanAttribute],
        suppressBrowserResizeObserver: [2, "suppressBrowserResizeObserver", "suppressBrowserResizeObserver", booleanAttribute],
        suppressPropertyNamesCheck: [2, "suppressPropertyNamesCheck", "suppressPropertyNamesCheck", booleanAttribute],
        suppressChangeDetection: [2, "suppressChangeDetection", "suppressChangeDetection", booleanAttribute],
        debug: [2, "debug", "debug", booleanAttribute],
        loading: [2, "loading", "loading", booleanAttribute],
        overlayLoadingTemplate: "overlayLoadingTemplate",
        loadingOverlayComponent: "loadingOverlayComponent",
        loadingOverlayComponentParams: "loadingOverlayComponentParams",
        suppressLoadingOverlay: [2, "suppressLoadingOverlay", "suppressLoadingOverlay", booleanAttribute],
        overlayNoRowsTemplate: "overlayNoRowsTemplate",
        noRowsOverlayComponent: "noRowsOverlayComponent",
        noRowsOverlayComponentParams: "noRowsOverlayComponentParams",
        suppressNoRowsOverlay: [2, "suppressNoRowsOverlay", "suppressNoRowsOverlay", booleanAttribute],
        pagination: [2, "pagination", "pagination", booleanAttribute],
        paginationPageSize: "paginationPageSize",
        paginationPageSizeSelector: "paginationPageSizeSelector",
        paginationAutoPageSize: [2, "paginationAutoPageSize", "paginationAutoPageSize", booleanAttribute],
        paginateChildRows: [2, "paginateChildRows", "paginateChildRows", booleanAttribute],
        suppressPaginationPanel: [2, "suppressPaginationPanel", "suppressPaginationPanel", booleanAttribute],
        pivotMode: [2, "pivotMode", "pivotMode", booleanAttribute],
        pivotPanelShow: "pivotPanelShow",
        pivotMaxGeneratedColumns: "pivotMaxGeneratedColumns",
        pivotDefaultExpanded: "pivotDefaultExpanded",
        pivotColumnGroupTotals: "pivotColumnGroupTotals",
        pivotRowTotals: "pivotRowTotals",
        pivotSuppressAutoColumn: [2, "pivotSuppressAutoColumn", "pivotSuppressAutoColumn", booleanAttribute],
        suppressExpandablePivotGroups: [2, "suppressExpandablePivotGroups", "suppressExpandablePivotGroups", booleanAttribute],
        functionsReadOnly: [2, "functionsReadOnly", "functionsReadOnly", booleanAttribute],
        aggFuncs: "aggFuncs",
        suppressAggFuncInHeader: [2, "suppressAggFuncInHeader", "suppressAggFuncInHeader", booleanAttribute],
        alwaysAggregateAtRootLevel: [2, "alwaysAggregateAtRootLevel", "alwaysAggregateAtRootLevel", booleanAttribute],
        aggregateOnlyChangedColumns: [2, "aggregateOnlyChangedColumns", "aggregateOnlyChangedColumns", booleanAttribute],
        suppressAggFilteredOnly: [2, "suppressAggFilteredOnly", "suppressAggFilteredOnly", booleanAttribute],
        removePivotHeaderRowWhenSingleValueColumn: [2, "removePivotHeaderRowWhenSingleValueColumn", "removePivotHeaderRowWhenSingleValueColumn", booleanAttribute],
        animateRows: [2, "animateRows", "animateRows", booleanAttribute],
        cellFlashDuration: "cellFlashDuration",
        cellFadeDuration: "cellFadeDuration",
        allowShowChangeAfterFilter: [2, "allowShowChangeAfterFilter", "allowShowChangeAfterFilter", booleanAttribute],
        domLayout: "domLayout",
        ensureDomOrder: [2, "ensureDomOrder", "ensureDomOrder", booleanAttribute],
        enableCellSpan: [2, "enableCellSpan", "enableCellSpan", booleanAttribute],
        enableRtl: [2, "enableRtl", "enableRtl", booleanAttribute],
        suppressColumnVirtualisation: [2, "suppressColumnVirtualisation", "suppressColumnVirtualisation", booleanAttribute],
        suppressMaxRenderedRowRestriction: [2, "suppressMaxRenderedRowRestriction", "suppressMaxRenderedRowRestriction", booleanAttribute],
        suppressRowVirtualisation: [2, "suppressRowVirtualisation", "suppressRowVirtualisation", booleanAttribute],
        rowDragManaged: [2, "rowDragManaged", "rowDragManaged", booleanAttribute],
        suppressRowDrag: [2, "suppressRowDrag", "suppressRowDrag", booleanAttribute],
        suppressMoveWhenRowDragging: [2, "suppressMoveWhenRowDragging", "suppressMoveWhenRowDragging", booleanAttribute],
        rowDragEntireRow: [2, "rowDragEntireRow", "rowDragEntireRow", booleanAttribute],
        rowDragMultiRow: [2, "rowDragMultiRow", "rowDragMultiRow", booleanAttribute],
        rowDragText: "rowDragText",
        fullWidthCellRenderer: "fullWidthCellRenderer",
        fullWidthCellRendererParams: "fullWidthCellRendererParams",
        embedFullWidthRows: [2, "embedFullWidthRows", "embedFullWidthRows", booleanAttribute],
        groupDisplayType: "groupDisplayType",
        groupDefaultExpanded: "groupDefaultExpanded",
        autoGroupColumnDef: "autoGroupColumnDef",
        groupMaintainOrder: [2, "groupMaintainOrder", "groupMaintainOrder", booleanAttribute],
        groupSelectsChildren: [2, "groupSelectsChildren", "groupSelectsChildren", booleanAttribute],
        groupLockGroupColumns: "groupLockGroupColumns",
        groupAggFiltering: "groupAggFiltering",
        groupTotalRow: "groupTotalRow",
        grandTotalRow: "grandTotalRow",
        suppressStickyTotalRow: "suppressStickyTotalRow",
        groupSuppressBlankHeader: [2, "groupSuppressBlankHeader", "groupSuppressBlankHeader", booleanAttribute],
        groupSelectsFiltered: [2, "groupSelectsFiltered", "groupSelectsFiltered", booleanAttribute],
        showOpenedGroup: [2, "showOpenedGroup", "showOpenedGroup", booleanAttribute],
        groupHideParentOfSingleChild: "groupHideParentOfSingleChild",
        groupRemoveSingleChildren: [2, "groupRemoveSingleChildren", "groupRemoveSingleChildren", booleanAttribute],
        groupRemoveLowestSingleChildren: [2, "groupRemoveLowestSingleChildren", "groupRemoveLowestSingleChildren", booleanAttribute],
        groupHideOpenParents: [2, "groupHideOpenParents", "groupHideOpenParents", booleanAttribute],
        groupAllowUnbalanced: [2, "groupAllowUnbalanced", "groupAllowUnbalanced", booleanAttribute],
        rowGroupPanelShow: "rowGroupPanelShow",
        groupRowRenderer: "groupRowRenderer",
        groupRowRendererParams: "groupRowRendererParams",
        treeData: [2, "treeData", "treeData", booleanAttribute],
        treeDataChildrenField: "treeDataChildrenField",
        treeDataParentIdField: "treeDataParentIdField",
        rowGroupPanelSuppressSort: [2, "rowGroupPanelSuppressSort", "rowGroupPanelSuppressSort", booleanAttribute],
        suppressGroupRowsSticky: [2, "suppressGroupRowsSticky", "suppressGroupRowsSticky", booleanAttribute],
        pinnedTopRowData: "pinnedTopRowData",
        pinnedBottomRowData: "pinnedBottomRowData",
        rowModelType: "rowModelType",
        rowData: "rowData",
        asyncTransactionWaitMillis: "asyncTransactionWaitMillis",
        suppressModelUpdateAfterUpdateTransaction: [2, "suppressModelUpdateAfterUpdateTransaction", "suppressModelUpdateAfterUpdateTransaction", booleanAttribute],
        datasource: "datasource",
        cacheOverflowSize: "cacheOverflowSize",
        infiniteInitialRowCount: "infiniteInitialRowCount",
        serverSideInitialRowCount: "serverSideInitialRowCount",
        suppressServerSideFullWidthLoadingRow: [2, "suppressServerSideFullWidthLoadingRow", "suppressServerSideFullWidthLoadingRow", booleanAttribute],
        cacheBlockSize: "cacheBlockSize",
        maxBlocksInCache: "maxBlocksInCache",
        maxConcurrentDatasourceRequests: "maxConcurrentDatasourceRequests",
        blockLoadDebounceMillis: "blockLoadDebounceMillis",
        purgeClosedRowNodes: [2, "purgeClosedRowNodes", "purgeClosedRowNodes", booleanAttribute],
        serverSideDatasource: "serverSideDatasource",
        serverSideSortAllLevels: [2, "serverSideSortAllLevels", "serverSideSortAllLevels", booleanAttribute],
        serverSideEnableClientSideSort: [2, "serverSideEnableClientSideSort", "serverSideEnableClientSideSort", booleanAttribute],
        serverSideOnlyRefreshFilteredGroups: [2, "serverSideOnlyRefreshFilteredGroups", "serverSideOnlyRefreshFilteredGroups", booleanAttribute],
        serverSidePivotResultFieldSeparator: "serverSidePivotResultFieldSeparator",
        viewportDatasource: "viewportDatasource",
        viewportRowModelPageSize: "viewportRowModelPageSize",
        viewportRowModelBufferSize: "viewportRowModelBufferSize",
        alwaysShowHorizontalScroll: [2, "alwaysShowHorizontalScroll", "alwaysShowHorizontalScroll", booleanAttribute],
        alwaysShowVerticalScroll: [2, "alwaysShowVerticalScroll", "alwaysShowVerticalScroll", booleanAttribute],
        debounceVerticalScrollbar: [2, "debounceVerticalScrollbar", "debounceVerticalScrollbar", booleanAttribute],
        suppressHorizontalScroll: [2, "suppressHorizontalScroll", "suppressHorizontalScroll", booleanAttribute],
        suppressScrollOnNewData: [2, "suppressScrollOnNewData", "suppressScrollOnNewData", booleanAttribute],
        suppressScrollWhenPopupsAreOpen: [2, "suppressScrollWhenPopupsAreOpen", "suppressScrollWhenPopupsAreOpen", booleanAttribute],
        suppressAnimationFrame: [2, "suppressAnimationFrame", "suppressAnimationFrame", booleanAttribute],
        suppressMiddleClickScrolls: [2, "suppressMiddleClickScrolls", "suppressMiddleClickScrolls", booleanAttribute],
        suppressPreventDefaultOnMouseWheel: [2, "suppressPreventDefaultOnMouseWheel", "suppressPreventDefaultOnMouseWheel", booleanAttribute],
        scrollbarWidth: "scrollbarWidth",
        rowSelection: "rowSelection",
        cellSelection: "cellSelection",
        rowMultiSelectWithClick: [2, "rowMultiSelectWithClick", "rowMultiSelectWithClick", booleanAttribute],
        suppressRowDeselection: [2, "suppressRowDeselection", "suppressRowDeselection", booleanAttribute],
        suppressRowClickSelection: [2, "suppressRowClickSelection", "suppressRowClickSelection", booleanAttribute],
        suppressCellFocus: [2, "suppressCellFocus", "suppressCellFocus", booleanAttribute],
        suppressHeaderFocus: [2, "suppressHeaderFocus", "suppressHeaderFocus", booleanAttribute],
        selectionColumnDef: "selectionColumnDef",
        rowNumbers: "rowNumbers",
        suppressMultiRangeSelection: [2, "suppressMultiRangeSelection", "suppressMultiRangeSelection", booleanAttribute],
        enableCellTextSelection: [2, "enableCellTextSelection", "enableCellTextSelection", booleanAttribute],
        enableRangeSelection: [2, "enableRangeSelection", "enableRangeSelection", booleanAttribute],
        enableRangeHandle: [2, "enableRangeHandle", "enableRangeHandle", booleanAttribute],
        enableFillHandle: [2, "enableFillHandle", "enableFillHandle", booleanAttribute],
        fillHandleDirection: "fillHandleDirection",
        suppressClearOnFillReduction: [2, "suppressClearOnFillReduction", "suppressClearOnFillReduction", booleanAttribute],
        sortingOrder: "sortingOrder",
        accentedSort: [2, "accentedSort", "accentedSort", booleanAttribute],
        unSortIcon: [2, "unSortIcon", "unSortIcon", booleanAttribute],
        suppressMultiSort: [2, "suppressMultiSort", "suppressMultiSort", booleanAttribute],
        alwaysMultiSort: [2, "alwaysMultiSort", "alwaysMultiSort", booleanAttribute],
        multiSortKey: "multiSortKey",
        suppressMaintainUnsortedOrder: [2, "suppressMaintainUnsortedOrder", "suppressMaintainUnsortedOrder", booleanAttribute],
        icons: "icons",
        rowHeight: "rowHeight",
        rowStyle: "rowStyle",
        rowClass: "rowClass",
        rowClassRules: "rowClassRules",
        suppressRowHoverHighlight: [2, "suppressRowHoverHighlight", "suppressRowHoverHighlight", booleanAttribute],
        suppressRowTransform: [2, "suppressRowTransform", "suppressRowTransform", booleanAttribute],
        columnHoverHighlight: [2, "columnHoverHighlight", "columnHoverHighlight", booleanAttribute],
        gridId: "gridId",
        deltaSort: [2, "deltaSort", "deltaSort", booleanAttribute],
        treeDataDisplayType: "treeDataDisplayType",
        enableGroupEdit: [2, "enableGroupEdit", "enableGroupEdit", booleanAttribute],
        initialState: "initialState",
        theme: "theme",
        loadThemeGoogleFonts: [2, "loadThemeGoogleFonts", "loadThemeGoogleFonts", booleanAttribute],
        themeCssLayer: "themeCssLayer",
        styleNonce: "styleNonce",
        themeStyleContainer: "themeStyleContainer",
        getContextMenuItems: "getContextMenuItems",
        getMainMenuItems: "getMainMenuItems",
        postProcessPopup: "postProcessPopup",
        processUnpinnedColumns: "processUnpinnedColumns",
        processCellForClipboard: "processCellForClipboard",
        processHeaderForClipboard: "processHeaderForClipboard",
        processGroupHeaderForClipboard: "processGroupHeaderForClipboard",
        processCellFromClipboard: "processCellFromClipboard",
        sendToClipboard: "sendToClipboard",
        processDataFromClipboard: "processDataFromClipboard",
        isExternalFilterPresent: "isExternalFilterPresent",
        doesExternalFilterPass: "doesExternalFilterPass",
        getChartToolbarItems: "getChartToolbarItems",
        createChartContainer: "createChartContainer",
        focusGridInnerElement: "focusGridInnerElement",
        navigateToNextHeader: "navigateToNextHeader",
        tabToNextHeader: "tabToNextHeader",
        navigateToNextCell: "navigateToNextCell",
        tabToNextCell: "tabToNextCell",
        getLocaleText: "getLocaleText",
        getDocument: "getDocument",
        paginationNumberFormatter: "paginationNumberFormatter",
        getGroupRowAgg: "getGroupRowAgg",
        isGroupOpenByDefault: "isGroupOpenByDefault",
        initialGroupOrderComparator: "initialGroupOrderComparator",
        processPivotResultColDef: "processPivotResultColDef",
        processPivotResultColGroupDef: "processPivotResultColGroupDef",
        getDataPath: "getDataPath",
        getChildCount: "getChildCount",
        getServerSideGroupLevelParams: "getServerSideGroupLevelParams",
        isServerSideGroupOpenByDefault: "isServerSideGroupOpenByDefault",
        isApplyServerSideTransaction: "isApplyServerSideTransaction",
        isServerSideGroup: "isServerSideGroup",
        getServerSideGroupKey: "getServerSideGroupKey",
        getBusinessKeyForNode: "getBusinessKeyForNode",
        getRowId: "getRowId",
        resetRowDataOnUpdate: [2, "resetRowDataOnUpdate", "resetRowDataOnUpdate", booleanAttribute],
        processRowPostCreate: "processRowPostCreate",
        isRowSelectable: "isRowSelectable",
        isRowMaster: "isRowMaster",
        fillOperation: "fillOperation",
        postSortRows: "postSortRows",
        getRowStyle: "getRowStyle",
        getRowClass: "getRowClass",
        getRowHeight: "getRowHeight",
        isFullWidthRow: "isFullWidthRow"
      },
      outputs: {
        toolPanelVisibleChanged: "toolPanelVisibleChanged",
        toolPanelSizeChanged: "toolPanelSizeChanged",
        columnMenuVisibleChanged: "columnMenuVisibleChanged",
        contextMenuVisibleChanged: "contextMenuVisibleChanged",
        cutStart: "cutStart",
        cutEnd: "cutEnd",
        pasteStart: "pasteStart",
        pasteEnd: "pasteEnd",
        columnVisible: "columnVisible",
        columnPinned: "columnPinned",
        columnResized: "columnResized",
        columnMoved: "columnMoved",
        columnValueChanged: "columnValueChanged",
        columnPivotModeChanged: "columnPivotModeChanged",
        columnPivotChanged: "columnPivotChanged",
        columnGroupOpened: "columnGroupOpened",
        newColumnsLoaded: "newColumnsLoaded",
        gridColumnsChanged: "gridColumnsChanged",
        displayedColumnsChanged: "displayedColumnsChanged",
        virtualColumnsChanged: "virtualColumnsChanged",
        columnEverythingChanged: "columnEverythingChanged",
        columnHeaderMouseOver: "columnHeaderMouseOver",
        columnHeaderMouseLeave: "columnHeaderMouseLeave",
        columnHeaderClicked: "columnHeaderClicked",
        columnHeaderContextMenu: "columnHeaderContextMenu",
        componentStateChanged: "componentStateChanged",
        cellValueChanged: "cellValueChanged",
        cellEditRequest: "cellEditRequest",
        rowValueChanged: "rowValueChanged",
        cellEditingStarted: "cellEditingStarted",
        cellEditingStopped: "cellEditingStopped",
        rowEditingStarted: "rowEditingStarted",
        rowEditingStopped: "rowEditingStopped",
        undoStarted: "undoStarted",
        undoEnded: "undoEnded",
        redoStarted: "redoStarted",
        redoEnded: "redoEnded",
        cellSelectionDeleteStart: "cellSelectionDeleteStart",
        cellSelectionDeleteEnd: "cellSelectionDeleteEnd",
        rangeDeleteStart: "rangeDeleteStart",
        rangeDeleteEnd: "rangeDeleteEnd",
        fillStart: "fillStart",
        fillEnd: "fillEnd",
        filterOpened: "filterOpened",
        filterChanged: "filterChanged",
        filterModified: "filterModified",
        advancedFilterBuilderVisibleChanged: "advancedFilterBuilderVisibleChanged",
        findChanged: "findChanged",
        chartCreated: "chartCreated",
        chartRangeSelectionChanged: "chartRangeSelectionChanged",
        chartOptionsChanged: "chartOptionsChanged",
        chartDestroyed: "chartDestroyed",
        cellKeyDown: "cellKeyDown",
        gridReady: "gridReady",
        firstDataRendered: "firstDataRendered",
        gridSizeChanged: "gridSizeChanged",
        modelUpdated: "modelUpdated",
        virtualRowRemoved: "virtualRowRemoved",
        viewportChanged: "viewportChanged",
        bodyScroll: "bodyScroll",
        bodyScrollEnd: "bodyScrollEnd",
        dragStarted: "dragStarted",
        dragStopped: "dragStopped",
        dragCancelled: "dragCancelled",
        stateUpdated: "stateUpdated",
        paginationChanged: "paginationChanged",
        rowDragEnter: "rowDragEnter",
        rowDragMove: "rowDragMove",
        rowDragLeave: "rowDragLeave",
        rowDragEnd: "rowDragEnd",
        rowDragCancel: "rowDragCancel",
        columnRowGroupChanged: "columnRowGroupChanged",
        rowGroupOpened: "rowGroupOpened",
        expandOrCollapseAll: "expandOrCollapseAll",
        pivotMaxColumnsExceeded: "pivotMaxColumnsExceeded",
        pinnedRowDataChanged: "pinnedRowDataChanged",
        rowDataUpdated: "rowDataUpdated",
        asyncTransactionsFlushed: "asyncTransactionsFlushed",
        storeRefreshed: "storeRefreshed",
        headerFocused: "headerFocused",
        cellClicked: "cellClicked",
        cellDoubleClicked: "cellDoubleClicked",
        cellFocused: "cellFocused",
        cellMouseOver: "cellMouseOver",
        cellMouseOut: "cellMouseOut",
        cellMouseDown: "cellMouseDown",
        rowClicked: "rowClicked",
        rowDoubleClicked: "rowDoubleClicked",
        rowSelected: "rowSelected",
        selectionChanged: "selectionChanged",
        cellContextMenu: "cellContextMenu",
        rangeSelectionChanged: "rangeSelectionChanged",
        cellSelectionChanged: "cellSelectionChanged",
        tooltipShow: "tooltipShow",
        tooltipHide: "tooltipHide",
        sortChanged: "sortChanged"
      },
      standalone: true,
      features: [i0.ɵɵProvidersFeature([AngularFrameworkOverrides, AngularFrameworkComponentWrapper]), i0.ɵɵInputTransformsFeature, i0.ɵɵNgOnChangesFeature, i0.ɵɵStandaloneFeature],
      decls: 0,
      vars: 0,
      template: function AgGridAngular_Template(rf, ctx) {},
      encapsulation: 2
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AgGridAngular, [{
    type: Component,
    args: [{
      selector: 'ag-grid-angular',
      standalone: true,
      template: '',
      providers: [AngularFrameworkOverrides, AngularFrameworkComponentWrapper],
      // tell angular we don't want view encapsulation, we don't want a shadow root
      encapsulation: ViewEncapsulation.None
    }]
  }], () => [{
    type: i0.ElementRef
  }, {
    type: i0.ViewContainerRef
  }, {
    type: AngularFrameworkOverrides
  }, {
    type: AngularFrameworkComponentWrapper
  }], {
    gridOptions: [{
      type: Input
    }],
    modules: [{
      type: Input
    }],
    statusBar: [{
      type: Input
    }],
    sideBar: [{
      type: Input
    }],
    suppressContextMenu: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    preventDefaultOnContextMenu: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    allowContextMenuWithControlKey: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    columnMenu: [{
      type: Input
    }],
    suppressMenuHide: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    enableBrowserTooltips: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    tooltipTrigger: [{
      type: Input
    }],
    tooltipShowDelay: [{
      type: Input
    }],
    tooltipHideDelay: [{
      type: Input
    }],
    tooltipMouseTrack: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    tooltipShowMode: [{
      type: Input
    }],
    tooltipInteraction: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    popupParent: [{
      type: Input
    }],
    copyHeadersToClipboard: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    copyGroupHeadersToClipboard: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    clipboardDelimiter: [{
      type: Input
    }],
    suppressCopyRowsToClipboard: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    suppressCopySingleCellRanges: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    suppressLastEmptyLineOnPaste: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    suppressClipboardPaste: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    suppressClipboardApi: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    suppressCutToClipboard: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    columnDefs: [{
      type: Input
    }],
    defaultColDef: [{
      type: Input
    }],
    defaultColGroupDef: [{
      type: Input
    }],
    columnTypes: [{
      type: Input
    }],
    dataTypeDefinitions: [{
      type: Input
    }],
    maintainColumnOrder: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    enableStrictPivotColumnOrder: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    suppressFieldDotNotation: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    headerHeight: [{
      type: Input
    }],
    groupHeaderHeight: [{
      type: Input
    }],
    floatingFiltersHeight: [{
      type: Input
    }],
    pivotHeaderHeight: [{
      type: Input
    }],
    pivotGroupHeaderHeight: [{
      type: Input
    }],
    allowDragFromColumnsToolPanel: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    suppressMovableColumns: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    suppressColumnMoveAnimation: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    suppressMoveWhenColumnDragging: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    suppressDragLeaveHidesColumns: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    suppressGroupChangesColumnVisibility: [{
      type: Input
    }],
    suppressMakeColumnVisibleAfterUnGroup: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    suppressRowGroupHidesColumns: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    colResizeDefault: [{
      type: Input
    }],
    suppressAutoSize: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    autoSizePadding: [{
      type: Input
    }],
    skipHeaderOnAutoSize: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    autoSizeStrategy: [{
      type: Input
    }],
    components: [{
      type: Input
    }],
    editType: [{
      type: Input
    }],
    singleClickEdit: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    suppressClickEdit: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    readOnlyEdit: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    stopEditingWhenCellsLoseFocus: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    enterNavigatesVertically: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    enterNavigatesVerticallyAfterEdit: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    enableCellEditingOnBackspace: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    undoRedoCellEditing: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    undoRedoCellEditingLimit: [{
      type: Input
    }],
    defaultCsvExportParams: [{
      type: Input
    }],
    suppressCsvExport: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    defaultExcelExportParams: [{
      type: Input
    }],
    suppressExcelExport: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    excelStyles: [{
      type: Input
    }],
    findSearchValue: [{
      type: Input
    }],
    findOptions: [{
      type: Input
    }],
    quickFilterText: [{
      type: Input
    }],
    cacheQuickFilter: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    includeHiddenColumnsInQuickFilter: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    quickFilterParser: [{
      type: Input
    }],
    quickFilterMatcher: [{
      type: Input
    }],
    applyQuickFilterBeforePivotOrAgg: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    excludeChildrenWhenTreeDataFiltering: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    enableAdvancedFilter: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    alwaysPassFilter: [{
      type: Input
    }],
    includeHiddenColumnsInAdvancedFilter: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    advancedFilterParent: [{
      type: Input
    }],
    advancedFilterBuilderParams: [{
      type: Input
    }],
    suppressAdvancedFilterEval: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    suppressSetFilterByDefault: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    enableCharts: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    chartThemes: [{
      type: Input
    }],
    customChartThemes: [{
      type: Input
    }],
    chartThemeOverrides: [{
      type: Input
    }],
    chartToolPanelsDef: [{
      type: Input
    }],
    chartMenuItems: [{
      type: Input
    }],
    loadingCellRenderer: [{
      type: Input
    }],
    loadingCellRendererParams: [{
      type: Input
    }],
    loadingCellRendererSelector: [{
      type: Input
    }],
    localeText: [{
      type: Input
    }],
    masterDetail: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    keepDetailRows: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    keepDetailRowsCount: [{
      type: Input
    }],
    detailCellRenderer: [{
      type: Input
    }],
    detailCellRendererParams: [{
      type: Input
    }],
    detailRowHeight: [{
      type: Input
    }],
    detailRowAutoHeight: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    context: [{
      type: Input
    }],
    dragAndDropImageComponent: [{
      type: Input
    }],
    dragAndDropImageComponentParams: [{
      type: Input
    }],
    alignedGrids: [{
      type: Input
    }],
    tabIndex: [{
      type: Input
    }],
    rowBuffer: [{
      type: Input
    }],
    valueCache: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    valueCacheNeverExpires: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    enableCellExpressions: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    suppressTouch: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    suppressFocusAfterRefresh: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    suppressBrowserResizeObserver: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    suppressPropertyNamesCheck: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    suppressChangeDetection: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    debug: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    loading: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    overlayLoadingTemplate: [{
      type: Input
    }],
    loadingOverlayComponent: [{
      type: Input
    }],
    loadingOverlayComponentParams: [{
      type: Input
    }],
    suppressLoadingOverlay: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    overlayNoRowsTemplate: [{
      type: Input
    }],
    noRowsOverlayComponent: [{
      type: Input
    }],
    noRowsOverlayComponentParams: [{
      type: Input
    }],
    suppressNoRowsOverlay: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    pagination: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    paginationPageSize: [{
      type: Input
    }],
    paginationPageSizeSelector: [{
      type: Input
    }],
    paginationAutoPageSize: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    paginateChildRows: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    suppressPaginationPanel: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    pivotMode: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    pivotPanelShow: [{
      type: Input
    }],
    pivotMaxGeneratedColumns: [{
      type: Input
    }],
    pivotDefaultExpanded: [{
      type: Input
    }],
    pivotColumnGroupTotals: [{
      type: Input
    }],
    pivotRowTotals: [{
      type: Input
    }],
    pivotSuppressAutoColumn: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    suppressExpandablePivotGroups: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    functionsReadOnly: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    aggFuncs: [{
      type: Input
    }],
    suppressAggFuncInHeader: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    alwaysAggregateAtRootLevel: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    aggregateOnlyChangedColumns: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    suppressAggFilteredOnly: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    removePivotHeaderRowWhenSingleValueColumn: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    animateRows: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    cellFlashDuration: [{
      type: Input
    }],
    cellFadeDuration: [{
      type: Input
    }],
    allowShowChangeAfterFilter: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    domLayout: [{
      type: Input
    }],
    ensureDomOrder: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    enableCellSpan: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    enableRtl: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    suppressColumnVirtualisation: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    suppressMaxRenderedRowRestriction: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    suppressRowVirtualisation: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    rowDragManaged: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    suppressRowDrag: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    suppressMoveWhenRowDragging: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    rowDragEntireRow: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    rowDragMultiRow: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    rowDragText: [{
      type: Input
    }],
    fullWidthCellRenderer: [{
      type: Input
    }],
    fullWidthCellRendererParams: [{
      type: Input
    }],
    embedFullWidthRows: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    groupDisplayType: [{
      type: Input
    }],
    groupDefaultExpanded: [{
      type: Input
    }],
    autoGroupColumnDef: [{
      type: Input
    }],
    groupMaintainOrder: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    groupSelectsChildren: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    groupLockGroupColumns: [{
      type: Input
    }],
    groupAggFiltering: [{
      type: Input
    }],
    groupTotalRow: [{
      type: Input
    }],
    grandTotalRow: [{
      type: Input
    }],
    suppressStickyTotalRow: [{
      type: Input
    }],
    groupSuppressBlankHeader: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    groupSelectsFiltered: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    showOpenedGroup: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    groupHideParentOfSingleChild: [{
      type: Input
    }],
    groupRemoveSingleChildren: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    groupRemoveLowestSingleChildren: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    groupHideOpenParents: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    groupAllowUnbalanced: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    rowGroupPanelShow: [{
      type: Input
    }],
    groupRowRenderer: [{
      type: Input
    }],
    groupRowRendererParams: [{
      type: Input
    }],
    treeData: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    treeDataChildrenField: [{
      type: Input
    }],
    treeDataParentIdField: [{
      type: Input
    }],
    rowGroupPanelSuppressSort: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    suppressGroupRowsSticky: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    pinnedTopRowData: [{
      type: Input
    }],
    pinnedBottomRowData: [{
      type: Input
    }],
    rowModelType: [{
      type: Input
    }],
    rowData: [{
      type: Input
    }],
    asyncTransactionWaitMillis: [{
      type: Input
    }],
    suppressModelUpdateAfterUpdateTransaction: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    datasource: [{
      type: Input
    }],
    cacheOverflowSize: [{
      type: Input
    }],
    infiniteInitialRowCount: [{
      type: Input
    }],
    serverSideInitialRowCount: [{
      type: Input
    }],
    suppressServerSideFullWidthLoadingRow: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    cacheBlockSize: [{
      type: Input
    }],
    maxBlocksInCache: [{
      type: Input
    }],
    maxConcurrentDatasourceRequests: [{
      type: Input
    }],
    blockLoadDebounceMillis: [{
      type: Input
    }],
    purgeClosedRowNodes: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    serverSideDatasource: [{
      type: Input
    }],
    serverSideSortAllLevels: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    serverSideEnableClientSideSort: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    serverSideOnlyRefreshFilteredGroups: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    serverSidePivotResultFieldSeparator: [{
      type: Input
    }],
    viewportDatasource: [{
      type: Input
    }],
    viewportRowModelPageSize: [{
      type: Input
    }],
    viewportRowModelBufferSize: [{
      type: Input
    }],
    alwaysShowHorizontalScroll: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    alwaysShowVerticalScroll: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    debounceVerticalScrollbar: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    suppressHorizontalScroll: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    suppressScrollOnNewData: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    suppressScrollWhenPopupsAreOpen: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    suppressAnimationFrame: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    suppressMiddleClickScrolls: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    suppressPreventDefaultOnMouseWheel: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    scrollbarWidth: [{
      type: Input
    }],
    rowSelection: [{
      type: Input
    }],
    cellSelection: [{
      type: Input
    }],
    rowMultiSelectWithClick: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    suppressRowDeselection: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    suppressRowClickSelection: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    suppressCellFocus: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    suppressHeaderFocus: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    selectionColumnDef: [{
      type: Input
    }],
    rowNumbers: [{
      type: Input
    }],
    suppressMultiRangeSelection: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    enableCellTextSelection: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    enableRangeSelection: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    enableRangeHandle: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    enableFillHandle: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    fillHandleDirection: [{
      type: Input
    }],
    suppressClearOnFillReduction: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    sortingOrder: [{
      type: Input
    }],
    accentedSort: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    unSortIcon: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    suppressMultiSort: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    alwaysMultiSort: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    multiSortKey: [{
      type: Input
    }],
    suppressMaintainUnsortedOrder: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    icons: [{
      type: Input
    }],
    rowHeight: [{
      type: Input
    }],
    rowStyle: [{
      type: Input
    }],
    rowClass: [{
      type: Input
    }],
    rowClassRules: [{
      type: Input
    }],
    suppressRowHoverHighlight: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    suppressRowTransform: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    columnHoverHighlight: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    gridId: [{
      type: Input
    }],
    deltaSort: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    treeDataDisplayType: [{
      type: Input
    }],
    enableGroupEdit: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    initialState: [{
      type: Input
    }],
    theme: [{
      type: Input
    }],
    loadThemeGoogleFonts: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    themeCssLayer: [{
      type: Input
    }],
    styleNonce: [{
      type: Input
    }],
    themeStyleContainer: [{
      type: Input
    }],
    getContextMenuItems: [{
      type: Input
    }],
    getMainMenuItems: [{
      type: Input
    }],
    postProcessPopup: [{
      type: Input
    }],
    processUnpinnedColumns: [{
      type: Input
    }],
    processCellForClipboard: [{
      type: Input
    }],
    processHeaderForClipboard: [{
      type: Input
    }],
    processGroupHeaderForClipboard: [{
      type: Input
    }],
    processCellFromClipboard: [{
      type: Input
    }],
    sendToClipboard: [{
      type: Input
    }],
    processDataFromClipboard: [{
      type: Input
    }],
    isExternalFilterPresent: [{
      type: Input
    }],
    doesExternalFilterPass: [{
      type: Input
    }],
    getChartToolbarItems: [{
      type: Input
    }],
    createChartContainer: [{
      type: Input
    }],
    focusGridInnerElement: [{
      type: Input
    }],
    navigateToNextHeader: [{
      type: Input
    }],
    tabToNextHeader: [{
      type: Input
    }],
    navigateToNextCell: [{
      type: Input
    }],
    tabToNextCell: [{
      type: Input
    }],
    getLocaleText: [{
      type: Input
    }],
    getDocument: [{
      type: Input
    }],
    paginationNumberFormatter: [{
      type: Input
    }],
    getGroupRowAgg: [{
      type: Input
    }],
    isGroupOpenByDefault: [{
      type: Input
    }],
    initialGroupOrderComparator: [{
      type: Input
    }],
    processPivotResultColDef: [{
      type: Input
    }],
    processPivotResultColGroupDef: [{
      type: Input
    }],
    getDataPath: [{
      type: Input
    }],
    getChildCount: [{
      type: Input
    }],
    getServerSideGroupLevelParams: [{
      type: Input
    }],
    isServerSideGroupOpenByDefault: [{
      type: Input
    }],
    isApplyServerSideTransaction: [{
      type: Input
    }],
    isServerSideGroup: [{
      type: Input
    }],
    getServerSideGroupKey: [{
      type: Input
    }],
    getBusinessKeyForNode: [{
      type: Input
    }],
    getRowId: [{
      type: Input
    }],
    resetRowDataOnUpdate: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    processRowPostCreate: [{
      type: Input
    }],
    isRowSelectable: [{
      type: Input
    }],
    isRowMaster: [{
      type: Input
    }],
    fillOperation: [{
      type: Input
    }],
    postSortRows: [{
      type: Input
    }],
    getRowStyle: [{
      type: Input
    }],
    getRowClass: [{
      type: Input
    }],
    getRowHeight: [{
      type: Input
    }],
    isFullWidthRow: [{
      type: Input
    }],
    toolPanelVisibleChanged: [{
      type: Output
    }],
    toolPanelSizeChanged: [{
      type: Output
    }],
    columnMenuVisibleChanged: [{
      type: Output
    }],
    contextMenuVisibleChanged: [{
      type: Output
    }],
    cutStart: [{
      type: Output
    }],
    cutEnd: [{
      type: Output
    }],
    pasteStart: [{
      type: Output
    }],
    pasteEnd: [{
      type: Output
    }],
    columnVisible: [{
      type: Output
    }],
    columnPinned: [{
      type: Output
    }],
    columnResized: [{
      type: Output
    }],
    columnMoved: [{
      type: Output
    }],
    columnValueChanged: [{
      type: Output
    }],
    columnPivotModeChanged: [{
      type: Output
    }],
    columnPivotChanged: [{
      type: Output
    }],
    columnGroupOpened: [{
      type: Output
    }],
    newColumnsLoaded: [{
      type: Output
    }],
    gridColumnsChanged: [{
      type: Output
    }],
    displayedColumnsChanged: [{
      type: Output
    }],
    virtualColumnsChanged: [{
      type: Output
    }],
    columnEverythingChanged: [{
      type: Output
    }],
    columnHeaderMouseOver: [{
      type: Output
    }],
    columnHeaderMouseLeave: [{
      type: Output
    }],
    columnHeaderClicked: [{
      type: Output
    }],
    columnHeaderContextMenu: [{
      type: Output
    }],
    componentStateChanged: [{
      type: Output
    }],
    cellValueChanged: [{
      type: Output
    }],
    cellEditRequest: [{
      type: Output
    }],
    rowValueChanged: [{
      type: Output
    }],
    cellEditingStarted: [{
      type: Output
    }],
    cellEditingStopped: [{
      type: Output
    }],
    rowEditingStarted: [{
      type: Output
    }],
    rowEditingStopped: [{
      type: Output
    }],
    undoStarted: [{
      type: Output
    }],
    undoEnded: [{
      type: Output
    }],
    redoStarted: [{
      type: Output
    }],
    redoEnded: [{
      type: Output
    }],
    cellSelectionDeleteStart: [{
      type: Output
    }],
    cellSelectionDeleteEnd: [{
      type: Output
    }],
    rangeDeleteStart: [{
      type: Output
    }],
    rangeDeleteEnd: [{
      type: Output
    }],
    fillStart: [{
      type: Output
    }],
    fillEnd: [{
      type: Output
    }],
    filterOpened: [{
      type: Output
    }],
    filterChanged: [{
      type: Output
    }],
    filterModified: [{
      type: Output
    }],
    advancedFilterBuilderVisibleChanged: [{
      type: Output
    }],
    findChanged: [{
      type: Output
    }],
    chartCreated: [{
      type: Output
    }],
    chartRangeSelectionChanged: [{
      type: Output
    }],
    chartOptionsChanged: [{
      type: Output
    }],
    chartDestroyed: [{
      type: Output
    }],
    cellKeyDown: [{
      type: Output
    }],
    gridReady: [{
      type: Output
    }],
    firstDataRendered: [{
      type: Output
    }],
    gridSizeChanged: [{
      type: Output
    }],
    modelUpdated: [{
      type: Output
    }],
    virtualRowRemoved: [{
      type: Output
    }],
    viewportChanged: [{
      type: Output
    }],
    bodyScroll: [{
      type: Output
    }],
    bodyScrollEnd: [{
      type: Output
    }],
    dragStarted: [{
      type: Output
    }],
    dragStopped: [{
      type: Output
    }],
    dragCancelled: [{
      type: Output
    }],
    stateUpdated: [{
      type: Output
    }],
    paginationChanged: [{
      type: Output
    }],
    rowDragEnter: [{
      type: Output
    }],
    rowDragMove: [{
      type: Output
    }],
    rowDragLeave: [{
      type: Output
    }],
    rowDragEnd: [{
      type: Output
    }],
    rowDragCancel: [{
      type: Output
    }],
    columnRowGroupChanged: [{
      type: Output
    }],
    rowGroupOpened: [{
      type: Output
    }],
    expandOrCollapseAll: [{
      type: Output
    }],
    pivotMaxColumnsExceeded: [{
      type: Output
    }],
    pinnedRowDataChanged: [{
      type: Output
    }],
    rowDataUpdated: [{
      type: Output
    }],
    asyncTransactionsFlushed: [{
      type: Output
    }],
    storeRefreshed: [{
      type: Output
    }],
    headerFocused: [{
      type: Output
    }],
    cellClicked: [{
      type: Output
    }],
    cellDoubleClicked: [{
      type: Output
    }],
    cellFocused: [{
      type: Output
    }],
    cellMouseOver: [{
      type: Output
    }],
    cellMouseOut: [{
      type: Output
    }],
    cellMouseDown: [{
      type: Output
    }],
    rowClicked: [{
      type: Output
    }],
    rowDoubleClicked: [{
      type: Output
    }],
    rowSelected: [{
      type: Output
    }],
    selectionChanged: [{
      type: Output
    }],
    cellContextMenu: [{
      type: Output
    }],
    rangeSelectionChanged: [{
      type: Output
    }],
    cellSelectionChanged: [{
      type: Output
    }],
    tooltipShow: [{
      type: Output
    }],
    tooltipHide: [{
      type: Output
    }],
    sortChanged: [{
      type: Output
    }]
  });
})();
const booleanMixedGridOptions = new Set(_BOOLEAN_MIXED_GRID_OPTIONS);
/**
 * Used to support the user setting combined boolean and string / object properties
 * as plain HTML attributes and us correctly mapping that to true.
 * For example cellSection can be boolean or an object
 */
function getValueOrCoercedValue(key, valueToUse) {
  if (booleanMixedGridOptions.has(key)) {
    // Handle plain HTML boolean attributes and convert them to boolean values
    // Also handle the false string case to match Angular boolean attributes
    return valueToUse === '' ? true : valueToUse === 'false' ? false : valueToUse;
  }
  return valueToUse;
}
class AgGridModule {
  static {
    this.ɵfac = function AgGridModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || AgGridModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: AgGridModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AgGridModule, [{
    type: NgModule,
    args: [{
      imports: [AgGridAngular],
      exports: [AgGridAngular]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { AgComponentContainer, AgGridAngular, AgGridModule, AngularFrameworkComponentWrapper, AngularFrameworkOverrides };
