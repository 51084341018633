export enum PermissionsEnum {
    ReadCustomers = 'read:customer',
    CreateCustomers = 'create:customer',
    UpdateCustomers = 'update:customer',
    DeleteCustomers = 'delete:customer',

    ReadSalesOrders = 'read:sales-order',
    CreateSalesOrders = 'create:sales-order',
    UpdateSalesOrders = 'update:sales-order',
    DeleteSalesOrders = 'delete:sales-order',
    PrintSalesOrders = 'print:sales-order',
    ReprintSalesOrders = 're-print:sales-order',
    ExportSalesOrders = 'export:sales-order',
    PlaceSalesOrder = 'place:sales-order',
    ExportSalesOrdersV3 = 'export:sales-order-v3',
    FulfillSalesOrder = 'fulfill:sales-order',

    ReadServiceItems = 'read:service-item',
    CreateServiceItems = 'create:service-item',
    UpdateServiceItems = 'update:service-item',
    DeleteServiceItems = 'delete:service-item',

    ReadSalesPersons = 'read:sales-person',
    CreateSalesPersons = 'create:sales-person',
    UpdateSalesPersons = 'update:sales-person',
    DeleteSalesPersons = 'delete:sales-person',

    ReadWarehouses = 'read:warehouse',
    CreateWarehouses = 'create:warehouse',
    UpdateWarehouses = 'update:warehouse',
    DeleteWarehouses = 'delete:warehouse',

    ReadStockItems = 'read:stock-item',
    CreateStockItems = 'create:stock-item',
    UpdateStockItems = 'update:stock-item',
    DeleteStockItems = 'delete:stock-item',

    ReadBrands = 'read:brand',
    CreateBrands = 'create:brand',
    UpdateBrands = 'update:brand',
    DeleteBrands = 'delete:brand',

    ReadWorkOrders = 'read:work-order',
    CreateWorkOrders = 'create:work-order',
    UpdateWorkOrders = 'update:work-order',
    DeleteWorkOrders = 'delete:work-order',

    ReadAccounts = 'read:account',
    CreateAccounts = 'create:account',
    UpdateAccounts = 'update:account',
    DeleteAccounts = 'delete:account',

    ReadCurrencys = 'read:currency',
    CreateCurrencys = 'create:currency',
    UpdateCurrencys = 'update:currency',
    DeleteCurrencys = 'delete:currency',

    ReadCostCenters = 'read:cost-center',
    CreateCostCenters = 'create:cost-center',
    UpdateCostCenters = 'update:cost-center',
    DeleteCostCenters = 'delete:cost-center',

    ReadJournalEntrys = 'read:journal-entry',
    CreateJournalEntrys = 'create:journal-entry',
    UpdateJournalEntrys = 'update:journal-entry',
    DeleteJournalEntrys = 'delete:journal-entry',

    ReadSalesStores = 'read:sales-store',
    CreateSalesStores = 'create:sales-store',
    UpdateSalesStores = 'update:sales-store',
    DeleteSalesStores = 'delete:sales-store',

    ReadBundles = 'read:bundle',
    CreateBundles = 'create:bundle',
    UpdateBundles = 'update:bundle',
    DeleteBundles = 'delete:bundle',

    ReadCostTypes = 'read:cost-type',
    CreateCostTypes = 'create:cost-type',
    UpdateCostTypes = 'update:cost-type',
    DeleteCostTypes = 'delete:cost-type',

    ReadBillOfMaterials = 'read:bill-of-material',
    CreateBillOfMaterials = 'create:bill-of-material',
    UpdateBillOfMaterials = 'update:bill-of-material',
    DeleteBillOfMaterials = 'delete:bill-of-material',

    ReadProductionOrders = 'read:production-order',
    CreateProductionOrders = 'create:production-order',
    UpdateProductionOrders = 'update:production-order',
    DeleteProductionOrders = 'delete:production-order',

    ReadProductionPlans = 'read:production-plan',
    CreateProductionPlans = 'create:production-plan',
    UpdateProductionPlans = 'update:production-plan',
    DeleteProductionPlans = 'delete:production-plan',

    ReadSystemSettings = 'read:system-setting',
    CreateSystemSettings = 'create:system-setting',
    UpdateSystemSettings = 'update:system-setting',
    DeleteSystemSettings = 'delete:system-setting',

    ReadFixedAssets = 'read:asset',
    CreateFixedAssets = 'create:asset',
    UpdateFixedAssets = 'update:asset',
    DeleteFixedAssets = 'delete:asset',
    DisposeFixedAssets = 'dispose:asset',
    UndisposeFixedAssets = 'undispose:asset',

    DepreciateFixedAssets = 'depreciate:asset',
    UndepreciateFixedAssets = 'undepreciate:asset',

    ReadPromotions = 'read:promotion',
    CreatePromotions = 'create:promotion',
    UpdatePromotions = 'update:promotion',
    DeletePromotions = 'delete:promotion',

    ReadCashRegisters = 'read:cash-register',
    CreateCashRegisters = 'create:cash-register',
    UpdateCashRegisters = 'update:cash-register',
    DeleteCashRegisters = 'delete:cash-register',
    PairDeviceCashRegister = 'pair-device:cash-register',
    UnpairDeviceCashRegister = 'unpair-device:cash-register',
    OpenCashRegister = 'open:cash-register',
    CloseCashRegister = 'close:cash-register',
    ActivateCashRegister = 'activate:cash-register',
    DeactivateCashRegister = 'deactivate:cash-register',
    CashInCashRegister = 'cash-in:cash-register',
    CashOutCashRegister = 'cash-out:cash-register',

    ReadSuppliers = 'read:supplier',
    CreateSuppliers = 'create:supplier',
    UpdateSuppliers = 'update:supplier',
    DeleteSuppliers = 'delete:supplier',

    ReadPurchaseOrders = 'read:purchase-order',
    CreatePurchaseOrders = 'create:purchase-order',
    UpdatePurchaseOrders = 'update:purchase-order',
    DeletePurchaseOrders = 'delete:purchase-order',

    ReadPurchaseRequests = 'read:purchase-request',
    CreatePurchaseRequests = 'create:purchase-request',
    UpdatePurchaseRequests = 'update:purchase-request',
    DeletePurchaseRequests = 'delete:purchase-request',

    ReadPurchaseInvoices = 'read:purchase-invoice',
    CreatePurchaseInvoices = 'create:purchase-invoice',
    UpdatePurchaseInvoices = 'update:purchase-invoice',
    DeletePurchaseInvoices = 'delete:purchase-invoice',

    ReadDebitNotes = 'read:debit-note',
    CreateDebitNotes = 'create:debit-note',
    UpdateDebitNotes = 'update:debit-note',
    DeleteDebitNotes = 'delete:debit-note',

    ReadSalesInvoices = 'read:sales-invoice',

    ReadCreditNotes = 'read:credit-note',

    ReadProducts = 'read:product',
    CreateProducts = 'create:product',
    UpdateProducts = 'update:product',
    DeleteProducts = 'delete:product',

    ReadLocations = 'read:location',
    CreateLocations = 'create:location',
    UpdateLocations = 'update:location',
    DeleteLocations = 'delete:location',

    ReadDeliveryZones = 'read:delivery-zone',
    CreateDeliveryZones = 'create:delivery-zone',
    UpdateDeliveryZones = 'update:delivery-zone',
    DeleteDeliveryZones = 'delete:delivery-zone',

    ReadLocalData = 'read:local-data',
    DeleteLocalData = 'delete:local-data'
}

export class PagePermissions {
    static StockItems: string[] = [
        PermissionsEnum.ReadStockItems,
        PermissionsEnum.CreateStockItems,
        PermissionsEnum.UpdateStockItems,
        PermissionsEnum.DeleteStockItems
    ];

    static Customers: string[] = [
        PermissionsEnum.ReadCustomers,
        PermissionsEnum.CreateCustomers,
        PermissionsEnum.UpdateCustomers,
        PermissionsEnum.DeleteCustomers
    ];

    static ServiceItems: string[] = [
        PermissionsEnum.ReadServiceItems,
        PermissionsEnum.CreateServiceItems,
        PermissionsEnum.UpdateServiceItems,
        PermissionsEnum.DeleteServiceItems
    ];

    static SalesOrders: string[] = [
        PermissionsEnum.ReadSalesOrders,
        PermissionsEnum.CreateSalesOrders,
        PermissionsEnum.UpdateSalesOrders,
        PermissionsEnum.DeleteSalesOrders,
        PermissionsEnum.PrintSalesOrders,
        PermissionsEnum.ReprintSalesOrders,
        PermissionsEnum.ExportSalesOrders,
        PermissionsEnum.FulfillSalesOrder
    ];

    static BillOfMaterials: string[] = [
        PermissionsEnum.ReadBillOfMaterials,
        PermissionsEnum.CreateBillOfMaterials,
        PermissionsEnum.UpdateBillOfMaterials,
        PermissionsEnum.DeleteBillOfMaterials
    ];

    static ProductionOrders: string[] = [
        PermissionsEnum.ReadProductionOrders,
        PermissionsEnum.CreateProductionOrders,
        PermissionsEnum.UpdateProductionOrders,
        PermissionsEnum.DeleteProductionOrders
    ];

    static ProductionPlans: string[] = [
        PermissionsEnum.ReadProductionPlans,
        PermissionsEnum.CreateProductionPlans,
        PermissionsEnum.UpdateProductionPlans,
        PermissionsEnum.DeleteProductionPlans
    ];

    static FixedAssets: string[] = [
        PermissionsEnum.ReadFixedAssets,
        PermissionsEnum.CreateFixedAssets,
        PermissionsEnum.UpdateFixedAssets,
        PermissionsEnum.DeleteFixedAssets,
        PermissionsEnum.DisposeFixedAssets,
        PermissionsEnum.UndisposeFixedAssets
    ];

    static FixedAssetsDepreciation: string[] = [
        PermissionsEnum.DepreciateFixedAssets,
        PermissionsEnum.UndepreciateFixedAssets
    ];

    static SystemSettings: string[] = [
        PermissionsEnum.ReadSystemSettings,
        PermissionsEnum.CreateSystemSettings,
        PermissionsEnum.UpdateSystemSettings,
        PermissionsEnum.DeleteSystemSettings
    ];

    static Promotions: string[] = [
        PermissionsEnum.ReadPromotions,
        PermissionsEnum.CreatePromotions,
        PermissionsEnum.UpdatePromotions,
        PermissionsEnum.DeletePromotions
    ];

    static CashRegisters: string[] = [
        PermissionsEnum.ReadCashRegisters,
        PermissionsEnum.CreateCashRegisters,
        PermissionsEnum.UpdateCashRegisters,
        PermissionsEnum.DeleteCashRegisters,
        PermissionsEnum.PairDeviceCashRegister,
        PermissionsEnum.UnpairDeviceCashRegister,
        PermissionsEnum.OpenCashRegister,
        PermissionsEnum.CloseCashRegister,
        PermissionsEnum.CashInCashRegister,
        PermissionsEnum.CashOutCashRegister,
        PermissionsEnum.ActivateCashRegister,
        PermissionsEnum.DeactivateCashRegister,
    ];

    static Suppliers: string[] = [
        PermissionsEnum.ReadSuppliers,
        PermissionsEnum.CreateSuppliers,
        PermissionsEnum.UpdateSuppliers,
        PermissionsEnum.DeleteSuppliers
    ];

    static PurchaseRequests: string[] = [
        PermissionsEnum.ReadPurchaseRequests,
        PermissionsEnum.CreatePurchaseRequests,
        PermissionsEnum.UpdatePurchaseRequests,
        PermissionsEnum.DeletePurchaseRequests
    ];

    static PurchaseOrders: string[] = [
        PermissionsEnum.ReadPurchaseOrders,
        PermissionsEnum.CreatePurchaseOrders,
        PermissionsEnum.UpdatePurchaseOrders,
        PermissionsEnum.DeletePurchaseOrders
    ];

    static Products: string[] = [
        PermissionsEnum.ReadProducts,
        PermissionsEnum.CreateProducts,
        PermissionsEnum.UpdateProducts,
        PermissionsEnum.DeleteProducts
    ];

    static Locations: string[] = [
        PermissionsEnum.ReadLocations,
        PermissionsEnum.CreateLocations,
        PermissionsEnum.UpdateLocations,
        PermissionsEnum.DeleteLocations
    ];

    static PurchaseInvoices: string[] = [
        PermissionsEnum.ReadPurchaseInvoices,
        PermissionsEnum.CreatePurchaseInvoices,
        PermissionsEnum.DeletePurchaseInvoices,
        PermissionsEnum.UpdatePurchaseInvoices,
    ]

    static DebitNotes: string[] = [
        PermissionsEnum.ReadDebitNotes,
        PermissionsEnum.CreateDebitNotes,
        PermissionsEnum.DeleteDebitNotes,
        PermissionsEnum.UpdateDebitNotes,
    ]

    static SalesInvoices: string[] = [
        PermissionsEnum.ReadSalesInvoices
    ];

    static CreditNotes: string[] = [
        PermissionsEnum.ReadCreditNotes
    ];

    static DeliveryZones: string[] = [
        PermissionsEnum.ReadDeliveryZones,
        PermissionsEnum.CreateDeliveryZones,
        PermissionsEnum.UpdateDeliveryZones,
        PermissionsEnum.DeleteDeliveryZones
    ];

    static LocalData: string[] = [
      PermissionsEnum.ReadLocalData,
      PermissionsEnum.DeleteLocalData,
    ];
}
