import { City, Country, District, State } from "@shared/models/customer-v3";

export class OrderAddress {
    country!: Country;
    state!: State;
    city!: City;
    district?: District;
    street?: string;
    phone?: string;

    description?: string;

    public static clone(orderAddress: OrderAddress): OrderAddress {
        const newOrderAddressObj = Object.assign(new OrderAddress(), orderAddress);
        const street = newOrderAddressObj.street ? `${newOrderAddressObj.street}, ` : '';
        newOrderAddressObj.description = `${street}${newOrderAddressObj.city?.name}, ${newOrderAddressObj.state?.name}, ${newOrderAddressObj.country?.name}`;
        return newOrderAddressObj;
    }
}