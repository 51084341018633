import { Injectable } from '@angular/core';
import { SystemSettingsService } from './system-settings.service';
import { ModuleVersion } from 'src/app/system/models/system-settings';
import { AuthService } from '@auth';

@Injectable({
  providedIn: 'root'
})
export class ModuleActivationService {

  constructor(
    private systemSettingsService: SystemSettingsService,
    private authService: AuthService
  ) { }

  async isInvoicingEnabled(): Promise<boolean> {
    const globalSettings = await this.systemSettingsService.GlobalSettings();
    return globalSettings.modulesActivation.invoicing;
  }

  async isPointOfSaleV3Enabled(): Promise<boolean> {
    const globalSettings = await this.systemSettingsService.GlobalSettings();
    if (!globalSettings.modulesActivation.moduleVersionConfig) return true; // Default use v3.0

    return globalSettings.modulesActivation.moduleVersionConfig.posVersion.version === ModuleVersion.V3_0 ||
      (
        globalSettings.modulesActivation.moduleVersionConfig.posVersion.version === ModuleVersion.UserLevel &&
        !!globalSettings.modulesActivation.moduleVersionConfig.posVersion.usersLevelVersion &&
        globalSettings.modulesActivation.moduleVersionConfig.posVersion.usersLevelVersion.some(x => x.user === this.authService.currentUserEmail && x.version === ModuleVersion.V3_0)
      );
  }
} 