import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PageMenuDateService } from '@shared/services/page-menu-date.service';

@Component({
  selector: 'app-side-nav-link',
  templateUrl: './side-nav-link.component.html',
  styleUrls: ['./side-nav-link.component.scss'],
})
export class SideNavLinkComponent {

  @Input() item: any;
  @Input() navMenuState: any;
  @Input() checkStarred: boolean = false;
  @Input() isPointOfSaleV3Enabled: boolean = false;
  @Output() onRouteClick = new EventEmitter<boolean>();
  @Output() onToggleStarred = new EventEmitter<boolean>();
  @Output() onRouteLastTimeClicked = new EventEmitter<boolean>();

  constructor(
    public PageMenuDateService: PageMenuDateService
  ) { }

  routeClick() {
    this.onRouteClick.emit(true);
    this.onRouteLastTimeClicked.emit(true);
  }

  toggleStarred(e: Event) {
    e.preventDefault();
    e.stopImmediatePropagation();
    this.onToggleStarred.emit(true);
  }

}
