<div class="landing">
  <div class="landing__header">
    <h2 class="landing__title typo-headline-small">{{pageTitle | translate}}</h2>
    <!-- <button mat-button color="primary" class="landing__switch-list">
          <mat-icon class="button__icon">view_list</mat-icon>
          List
      </button> -->
  </div>
  <div class="landing__content">
    <ng-container *ngFor="let menuItem of menuItems">
      <ng-container *ngIf="menuItem.module === landingModuleName">
        <div class="landing-list">
          <div class="row g-4">
            <ng-container *ngFor="let modulePage of menuItem.details">
              <div class="col-md-3"
                *ngIf="(modulePage.permissions || modulePage.permissions === true) && (modulePage.checkForModuleVersion ? modulePage.isV3  === isPointOfSaleV3Enabled : true)">
                <app-landing-list-item [itemData]="modulePage"
                  (onToggleStarred)="onToggleStarred($event, modulePage,menuItem)"
                  (onRouteLastTimeClicked)="onRouteLastTimeClicked(modulePage)">
                </app-landing-list-item>
              </div>
            </ng-container>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>