<mat-form-field [class]="cssClass">
  <mat-label *ngIf="label">{{ label }}</mat-label>
  <input matInput type="text" placeholder="{{placeHolder}}" [formControl]="autoCompleteControl" [matAutocomplete]="auto"
    [id]="inputId" (input)="onTextChange($event)">
  <mat-autocomplete class="custom-autocomplete" #auto="matAutocomplete" [displayWith]="displayFn.bind(this)"
    (opened)="onAutocompleteOpened()" (closed)="onAutocompleteClosed()"
    (optionSelected)="onOptionSelected($event.option.value)">
    @for (option of filteredOptions | async; track option[valueProperty]) {
    <mat-option class="mat-select-actions" [value]="option">
      {{option[textProperty]}}
      <div class="mat-select-actions__buttons opacity-1" *ngIf="enableAddAction && noOptions">
        <button type="button" id="btn_createNewAutoCompleteOption" mat-stroked-button color="primary" class="opacity-1">
          {{ 'autoComplete.create' | translate }}
        </button>
      </div>
      <div class="mat-select-actions__buttons" *ngIf="showActionsButtons && !noOptions">
        <button type="button" id="btn_deleteAutoCompleteOption" mat-icon-button (click)="onDeleteOption(option)">
          <icon name="delete"></icon>
        </button>
        <button type="button" id="btn_editAutoCompleteOption" mat-icon-button (click)="onEditOption(option)">
          <icon name="edit"></icon>
        </button>
      </div>
    </mat-option>
    }
  </mat-autocomplete>
  <div matSuffix class="d-flex">
    <button type="button" id="btn_clearAutoComplete" mat-icon-button (click)="onClear($event)"
      *ngIf="autoCompleteControl.value && !disabled">
      <icon name="close" class="font-semibold"></icon>
    </button>
    <icon name="info" matTooltip="{{ infoText }}" matTooltipPosition="above" *ngIf="infoText">
    </icon>
    <mat-spinner id="spinner_autoComplete" diameter="25" *ngIf="selectedOptionsLoading"></mat-spinner>
  </div>
  <mat-error [id]="matErrorId" *ngIf="formSubmitted && autoCompleteControl.errors?.['required'] && requiredText">
    {{ requiredText }}
  </mat-error>
</mat-form-field>