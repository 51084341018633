import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent  {
    title: string;
    message: string;
    btnOkText: string;
    btnCancelText: string;
    showCloseIconOnTop: boolean;
    confirmDialogType: ConfirmDialogType;
    okButtonColor: 'primary' | 'warn' = 'primary';

    constructor(public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
      @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogModel) {
      this.title = data.title;
      this.message = data.message;
      this.btnOkText = data.btnOkText;
      this.btnCancelText = data.btnCancelText;
      this.showCloseIconOnTop = data.showCloseIconOnTop;
      this.confirmDialogType = data.confirmDialogType;
      this.okButtonColor = data.okButtonColor;
    }

    onConfirm(): void {
      // Close the dialog, return true
      this.dialogRef.close(true);
    }

    onDismiss(): void {
      // Close the dialog, return false
      this.dialogRef.close(false);
    }
  }

  export class ConfirmDialogModel {
    constructor(public title: string,
      public message: string,
      public btnOkText: string,
      public btnCancelText: string,
      public showCloseIconOnTop: boolean,
      public confirmDialogType: ConfirmDialogType,
      public okButtonColor: 'primary' | 'warn' = 'primary'
      ) {
    }
  }

  export enum ConfirmDialogType {
    basic,
    warning,
    warningNotify
  }
