import { Injectable } from '@angular/core';
import { DocumentReference, deleteDoc } from '@angular/fire/firestore';
import { AuthService } from '@auth';
import { FirestoreService } from '@core';
import { CollectionReference, query } from 'firebase/firestore';
import { MenuItem, ModulePage, ModuleSideNavTabs } from '@shared/models/modules-landing.model';
import { BehaviorSubject } from 'rxjs';
import { HasPermissionsPipe } from '@shared/pipes/has-permissions.pipe';
import { PagePermissions } from '@shared/models/permissions';
import { RolesEnum } from '@shared/models/roles';
import { IsInRolePipe } from '@shared/pipes/is-in-role.pipe';

@Injectable({
  providedIn: 'root'
})
export class PagesMenuService {

  navMenuState: any = {
    selectedMenuIndex: -1,
    isPointOfSaleV3Enabled: false
  };
  menu_items: any[] = [];
  isLoading: boolean = false;
  starredIndicator: string = "";
  private readonly _starredPages = new BehaviorSubject<ModulePage[]>([]);
  readonly starredPages$ = this._starredPages.asObservable();

  get starredPages(): ModulePage[] {
    return this._starredPages.getValue();
  }

  private set starredPages(val: ModulePage[]) {
    this._starredPages.next(val);
  }

  public get pagePermissions(): typeof PagePermissions {
    return PagePermissions;
  }

  public get roles(): typeof RolesEnum {
    return RolesEnum;
  }

  constructor(
    private authService: AuthService,
    private firestoreService: FirestoreService,
    public hasPermissions: HasPermissionsPipe,
    public isInRole: IsInRolePipe
  ) { }

  private get starredPageDocumentsCollection(): CollectionReference<ModulePage> {
    return this.firestoreService.col(
      `accountSettings/${this.authService.currentUserId}/starredPages`
    );
  }

  starredPageDocument(docId: string): DocumentReference<ModulePage> {
    return this.firestoreService.doc(
      `accountSettings/${this.authService.currentUserId}/starredPages/${docId}`
    );
  }

  loadStarredPages(): Promise<ModulePage[]> {
    const queryFn = query(
      this.firestoreService.col<ModulePage>(
        `accountSettings/${this.authService.currentUserId}/starredPages`
      )
    );
    return this.firestoreService.getCollectionWithIdsAsync<ModulePage>(queryFn);
  }

  addStarredPage(data: ModulePage) {
    return this.firestoreService.addDoc(
      this.starredPageDocumentsCollection,
      data
    );
  }

  deleteStarredPage(docId: string) {
    return deleteDoc(this.starredPageDocument(docId));
  }

  loadStarredPagesList() {
    this.isLoading = true;
    this.loadStarredPages()
      .then((starredPagesRes: any) => {
        this.isLoading = false;
        this.starredPages = starredPagesRes;
      })
      .catch((err: any) => {
        this.isLoading = false;
      });
  }

  setStarredIndicator(moduleItem: MenuItem) {
    this.starredIndicator = moduleItem.module;
    setTimeout(() => { this.starredIndicator = "" }, 500);
  }

  changeModuleSideNavTab(moduleItem: MenuItem, tab: ModuleSideNavTabs) {
    moduleItem.activeTab = tab;
  }

  onToggleStarred(isStarred: boolean, data: ModulePage, moduleItem: MenuItem) {
    if (this.isLoading) { return };
    if (isStarred) {
      const starredPage = this.starredPages.find((starredPage: ModulePage) => starredPage.routerLink === data.routerLink);
      if (!starredPage) {
        this.isLoading = true;
        if (data.doc_id) {
          delete data.doc_id;
        }
        this.addStarredPage(data).then((res: any) => {
          this.setStarredIndicator(moduleItem);
          this.isLoading = false;
          this.loadStarredPagesList();
        });
      }
    } else {
      if (data.doc_id) {
        this.isLoading = true;
        this.deleteStarredPage(data.doc_id).then((res: any) => {
          this.isLoading = false;
          this.loadStarredPagesList();
        })
      }
    }
  }

  menuItemsMapper(menu_items: MenuItem[] = []) {
    const menu_items_cloned = [...menu_items];
    menu_items_cloned.forEach((moduleItem: any) => {
      moduleItem.hasStarredPages = false;
      //moduleItem.children.forEach((group: ModuleChildrenGroup) => {
      moduleItem.details.forEach((modulePage: ModulePage) => {
        modulePage.isStarred = false;
        const starredPage = this.starredPages.find((starredPage: ModulePage) =>
          starredPage.routerLink === modulePage.routerLink &&
          (modulePage.checkForModuleVersion ? modulePage.isV3 === this.navMenuState.isPointOfSaleV3Enabled : true)
        );
        if (starredPage) {
          modulePage.isStarred = true;
          moduleItem.hasStarredPages = true;
          modulePage.doc_id = starredPage.doc_id;
          // if(!moduleItem.activeTabDone) {
          //   moduleItem.activeTab = ModuleSideNavTabs.starred;
          //   moduleItem.activeTabDone = true;
          // }
        }
      });
      moduleItem.details.forEach((modulePage: any) => {
        const showItem = (modulePage.permissions || modulePage.permissions === true) &&
          (modulePage.checkForModuleVersion ? modulePage.isV3 === this.navMenuState.isPointOfSaleV3Enabled : true);
        modulePage.showItem = showItem;
      });

      moduleItem.starredItems = moduleItem.details.filter((modulePage: any) => modulePage.isStarred);
      moduleItem.starredCount = 0;
      moduleItem.starredCount += moduleItem.starredItems.length;
    });
    // });
    // menu_items = menu_items.filter(item => (!item.details || !!item.details.find(subItem => subItem.permissions)) && !item?.isHidden);

    return menu_items;
  }

}
