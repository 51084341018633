<ng-container
  *ngIf="(item.permissions || item.permissions === true) && (item.checkForModuleVersion ? item.isV3 === isPointOfSaleV3Enabled : true)">
  <a class="side-nav__link d-block" matRipple [routerLinkActive]="['side-nav__link--active']"
    (click)="navMenuState.selectedMenuIndex = -1; routeClick()" [routerLink]="item.routerLink"
    *ngIf="checkStarred ? item?.isStarred : true" [id]="item.id">
    <div class="w-100 d-flex justify-content-between align-items-center">
      <span>{{ item.label | translate | capitalize }}</span>
      <icon (click)="toggleStarred($event)" *ngIf="!item?.isStarred"
        class="side-nav__link--starred-icon side-nav__link--starred-icon--active" [isSvg]="true" name="star"></icon>
      <icon (click)="toggleStarred($event)" *ngIf="item?.isStarred" class="side-nav__link--starred-icon" [isSvg]="true"
        name="star_border_purple500"></icon>
    </div>
  </a>
</ng-container>