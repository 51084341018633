import { MoneyHelper } from '@shared';
import { MinifiedCustomerV3, MinifiedDeliveryZone } from "./customer-v3";
import { Product, ProductType, ProductUnitOfMeasure, ProductVariant } from "src/app/point-of-sale/models/product";
import { SalesOrderItemV3 } from "./sales-order-item-v3";
import { Metadata } from './metadata';
import { SalesOrderV3Payment } from './sales-order-v3-payment';
import { MinifiedPosLocation, PickupType } from 'src/app/point-of-sale/models/pos-location';
import { MinifiedCashRegisterV3 } from 'src/app/point-of-sale/models/cash-register-v3';
import { DiscountDto } from 'src/app/point-of-sale/models/discount';
import { DiscountType } from '@shared/enums/discount-type';
import { filter, sumBy } from 'lodash';
import { OrderAddress } from 'src/app/point-of-sale/models/order-address';
import { PaymentStatus } from 'src/app/point-of-sale/enums/payment-status';
import { DeliveryStatus } from 'src/app/point-of-sale/enums/delivery-status.enum';
import { FulfillmentStatus } from 'src/app/point-of-sale/enums/fulfillment-status.enum';
import { SalesRecognitionSetting } from 'src/app/system/models/system-settings';

const moneyHelper = new MoneyHelper();

export class SalesOrderV3 {
    id?: string;
    code?: string;
    preOrderCode?: string;
    orderDate!: Date;
    location?: MinifiedPosLocation;
    fulfillmentLocation?: MinifiedPosLocation;
    customer?: MinifiedCustomerV3;
    discount!: DiscountDto;
    deliveryFees: number = 0;
    refundedDeliveryFees?: number;
    deliveryFeesAvailable?: number;
    orderAddress?: OrderAddress;
    note?: string;
    grossTotal!: number;
    netTotal!: number;
    lineItems: SalesOrderItemV3[] = [];
    payments: SalesOrderV3Payment[] = [];
    paymentStatus?: PaymentStatus;
    paymentDate?: Date;
    cashRegister?: MinifiedCashRegisterV3;
    refundable?: boolean;
    lineItemsDiscount?: number;
    totalDiscount?: number;
    taxable!: boolean;
    priceIncludeTax!: boolean;
    defaultTaxRate: number = 0;
    subTotal!: number;
    totalTax!: number;
    metadata?: Metadata;
    extraAttributes?: SalesOrderV3ExtraAttributes;
    deliveryStatus?: DeliveryStatus;
    deliveryDate?: Date;
    fulfillmentStatus?: FulfillmentStatus;
    fulfillmentDate?: Date;
    salesRecognitionDate?: Date;
    pickupType?: PickupType;
    deliveryZone?: MinifiedDeliveryZone;

    holdId?: number;
    holdDate?: Date;

    constructor() {
        this.discount = {
            value: 0,
            type: DiscountType.Amount
        };
        this.deliveryFees = 0;
    }

    get totalItemsCount(): number {
        let totalItemsCount = 0;
        this.lineItems.forEach(orderItem => {
            totalItemsCount += orderItem.quantity;
        });
        return totalItemsCount;
    }

    get totalItemsDiscount(): number {
        let totalDiscount = 0;
        this.lineItems.forEach(orderItem => {
            totalDiscount += orderItem.totalDiscount;
        });
        return totalDiscount;
    }

    get discountAmount() {
        if (this.discount?.type === DiscountType.Amount) {
            return this.discount.value > (this.itemsNetAmount + this.deliveryFees) ? (this.itemsNetAmount + this.deliveryFees) : this.discount.value
        } else if (this.discount?.type === DiscountType.Percentage) {
            return (this.itemsNetAmount + this.deliveryFees) * this.discount.value / 100;
        }
        return 0;
    }

    get itemsNetAmount() {
        let itemsNetAmount = 0;
        this.lineItems.forEach(orderItem => {
            itemsNetAmount += orderItem.netAmount;
        });
        return itemsNetAmount;
    }

    get totalOrderDiscount(): number {
        return this.totalItemsDiscount + this.discountAmount;
    }

    get taxRate() {
        return this.defaultTaxRate / 100;
    }

    get taxAmount(): number {
        let totalItemsTax = 0;
        let deliveryFeesTax = 0;

        if (this.taxable) {
            for (const orderItem of this.lineItems) {
                totalItemsTax += this.calculateItemTax(orderItem);
            }
            deliveryFeesTax = this.calculateDeliveryFeesTax();
        }

        return totalItemsTax + deliveryFeesTax;
    }

    get grossAmount(): number {
        let grossAmount = 0;
        this.lineItems.forEach(orderItem => {
            grossAmount += orderItem.grossTotal;
        });
        if (this.priceIncludeTax) {
            this.grossTotal = grossAmount - this.taxAmount;
        } else {
            this.grossTotal = grossAmount;
        }
        return moneyHelper.round(grossAmount, 2);
    }

    get subAmount(): number {
        return moneyHelper.round(this.grossAmount + this.deliveryFees - this.totalOrderDiscount, 2);
    }

    get netAmount(): number {
        const pricesIncludeVAT = this.taxable && this.priceIncludeTax;
        if (!pricesIncludeVAT) {
            this.netTotal = this.grossAmount + this.deliveryFees + this.taxAmount - this.totalOrderDiscount;
        } else {
            this.netTotal = this.grossAmount + this.deliveryFees - this.totalOrderDiscount;
        }
        return moneyHelper.round(this.netTotal, 2);
    }

    get maxDiscountValue(): number {
        return moneyHelper.round(this.grossAmount + this.deliveryFees + this.taxAmount - this.totalItemsDiscount, 2);
    }

    public static clone(salesOrder: SalesOrderV3): SalesOrderV3 {
        const newSalesOrder: SalesOrderV3 = Object.assign(new SalesOrderV3(), salesOrder);
        newSalesOrder.lineItems = [];
        for (let index = 0; index < salesOrder.lineItems.length; index++) {
            const element = salesOrder.lineItems[index];
            newSalesOrder.lineItems.push(SalesOrderItemV3.clone(element));
        }
        if (salesOrder.orderAddress) {
            newSalesOrder.orderAddress = OrderAddress.clone(salesOrder.orderAddress);
        }
        return newSalesOrder;
    }

    get cashPaymentTotal(): number {
        const cashPayments = filter(this.payments, x => x.paymentTypeName === "Cash");
        return sumBy(cashPayments, x => x.amount);
    }

    get cardPaymentTotal(): number {
        const cardPayments = filter(this.payments, x => x.paymentTypeName === "Card");
        return sumBy(cardPayments, x => x.amount);
    }

    public addLineItem(product: Product, quantity: number, unitOfMeasure?: ProductUnitOfMeasure, variant?: ProductVariant, mergeSimilarItems?: boolean) {
        // Set default tax rate to product
        if (this.taxable && !product.tax) {
            product.tax = {
                id: this.extraAttributes?.defaultTaxId,
                rate: this.defaultTaxRate
            };
        }

        if (product.type === ProductType.Product) {
            this.addProduct(product, quantity, unitOfMeasure, variant, mergeSimilarItems);
        } else if (product.type === ProductType.Bundle) {
            this.addBundle(product, quantity, mergeSimilarItems);
        }
    }

    private addProduct(product: Product, quantity: number, unitOfMeasure?: ProductUnitOfMeasure, variant?: ProductVariant, mergeSimilarItems?: boolean): SalesOrderItemV3 {
        unitOfMeasure = unitOfMeasure
          ?? product.unitOfMeasures.find(uom => uom.baseUnit);

        if (
          !variant &&
          product.type === ProductType.Product &&
          !product.attributes?.length &&
          product.variants.length === 1 &&
          product.variants[0].title?.includes('Default Variant')
        ) {
            variant = product.variants[0];
        }

        if (mergeSimilarItems) {
            // Find Item in salesOrder_details
            const retreivedLineItem = this.lineItems.find(itm =>
                itm.productId === product.id
                && itm.unitOfMeasure?.id === unitOfMeasure?.id
                && itm.productVariant?.id === variant?.id
            );

            // If StockItem exists (sum quantities)
            if (retreivedLineItem) {
                retreivedLineItem.quantity += quantity;
                return retreivedLineItem;
            }
        }

        const orderItem = new SalesOrderItemV3(product, quantity, unitOfMeasure, variant);

        // Add item to SO's details list
        this.lineItems = [orderItem, ...this.lineItems];

        // Reset SO details serials
        this.resetOrderItemsSerials();

        return orderItem;
    }

    private addBundle(product: Product, quantity: number, mergeSimilarItems?: boolean): SalesOrderItemV3 {
        if (mergeSimilarItems) {
            // Find Item in salesOrder_details
            const retreivedLineItem = this.lineItems.find(itm => itm.productId === product.id);

            // If StockItem exists (sum quantities)
            if (retreivedLineItem) {
                retreivedLineItem.quantity += quantity;
                return retreivedLineItem;
            }
        }

        const orderItem = new SalesOrderItemV3(product, quantity);

        // Add item to SO's details list
        this.lineItems = [orderItem, ...this.lineItems];

        // Reset SO details serials
        this.resetOrderItemsSerials();

        return orderItem;
    }

    public increaseLineItemQty(product: Product) {
        // Find Item in salesOrder_details
        const retreivedLineItem = this.lineItems.find(itm => itm.productId === product.id);

        // If StockItem exists (sum quantities)
        if (retreivedLineItem) {
            retreivedLineItem.quantity++;
        }
    }

    public decreaseLineItemQty(product: Product) {
        // Find Item in salesOrder_details
        const retreivedLineItem = this.lineItems.find(itm => itm.productId === product.id);

        // If StockItem exists (sum quantities)
        if (retreivedLineItem && retreivedLineItem.quantity > 1) {
            retreivedLineItem.quantity--;
        }
    }

    public removeOrderItem(serial: number) {
        // Remove SO item
        this.lineItems = this.lineItems.filter((_, index) => index !== serial - 1);

        // Reset SO details serials
        this.resetOrderItemsSerials();
    }

    public removeSelectedOrderItems() {
        this.lineItems = this.lineItems.filter(itm => !itm.isSelected);

        // Reset SO details serials
        this.resetOrderItemsSerials();
    }

    private resetOrderItemsSerials() {
        this.lineItems.forEach((orderItem, index) => {
            orderItem.extraAttributes ??= {};
            orderItem.extraAttributes.serial = index + 1;
        });
    }

    public getClosureCode(): string | undefined {
        return this.extraAttributes?.closureCode;
    }

    /**
    * Remove unneeded properties, should be used before sending to the back-end API
    */
    public minify(): SalesOrderV3 {
        const minified = SalesOrderV3.clone(this);

        delete minified.id;
        delete minified.metadata;
        delete minified.holdId;

        minified.lineItems.forEach(item => {
            item.minify();
        });

        return minified;
    }

    public calculateItemTax(orderItem: SalesOrderItemV3): number {
        const pricesIncludeVAT = this.taxable && this.priceIncludeTax;
        const lineItemsDiscountPortion = this.calculateLineItemsDiscountPortion();
        const discountPortion = lineItemsDiscountPortion.find(li => li.lineItemSerial === orderItem.extraAttributes.serial)?.discountPortion ?? 0;
        if (!pricesIncludeVAT) {
            return (orderItem.netAmount - discountPortion) * orderItem.taxRatePercentage;
        } else {
            return (orderItem.netAmount - discountPortion) * orderItem.taxRatePercentage / (1 + orderItem.taxRatePercentage);
        }
    }

    private calculateDeliveryFeesTax() {
        const pricesIncludeVAT = this.taxable && this.priceIncludeTax;
        if (!pricesIncludeVAT) {
            return this.deliveryFees * this.taxRate;
        } else {
            return this.deliveryFees * this.taxRate / (1 + this.taxRate);
        }
    }

    // Calculate the portion of the sales order discount for each line item
    private calculateLineItemsDiscountPortion() {
        const salesOrderDiscount = this.discount?.value ?? 0;
        const lineItemsDiscountPortion = this.lineItems.map(li => {
            return {
                lineItemSerial: li.extraAttributes.serial,
                lineQuantity: li.quantity,
                discountPortion: this.itemsNetAmount ? (salesOrderDiscount * li.netAmount / this.itemsNetAmount) : 0
            };
        }).map(li => {
            return {
                lineItemSerial: li.lineItemSerial,
                lineQuantity: li.lineQuantity,
                discountPortion: li.discountPortion,
                discountPortionPerUnit: li.discountPortion / li.lineQuantity
            };
        });
        return lineItemsDiscountPortion;
    }

    //#region ZATCA
    get zatcaTaxAmount(): number {
        let totalItemsTax = 0;
        if (this.taxable) {
            for (let index = 0; index < this.lineItems.length; index++) {
                const orderItem = this.lineItems[index];
                totalItemsTax += this.calculateZatcaItemTax(orderItem);
            }
        }
        return totalItemsTax;
    }

    get zatcaGrossAmount(): number {
        let grossAmount = 0;
        this.lineItems.forEach(orderItem => {
            grossAmount += orderItem.grossTotal;
        });
        if (this.priceIncludeTax) {
            return grossAmount - this.zatcaTaxAmount;
        } else {
            return grossAmount;
        }
    }

    get zatcaNetAmount(): number {
        return this.zatcaGrossAmount + this.zatcaTaxAmount;
    }

    public calculateZatcaItemTax(orderItem: SalesOrderItemV3): number {
        const pricesIncludeVAT = this.taxable && this.priceIncludeTax;
        if (!pricesIncludeVAT) {
            return orderItem.grossTotal * orderItem.taxRatePercentage;
        } else {
            return orderItem.grossTotal * orderItem.taxRatePercentage / (1 + orderItem.taxRatePercentage);
        }
    }

    public calculateZatcaItemUnitPrice(orderItem: SalesOrderItemV3): number {
        const pricesIncludeVAT = this.taxable && this.priceIncludeTax;
        if (pricesIncludeVAT) {
            const tax = orderItem.grossTotal * orderItem.taxRatePercentage / (1 + orderItem.taxRatePercentage);
            return (orderItem.grossTotal - tax) / orderItem.quantity;
        } else {
            return orderItem.price;
        }
    }

    public calculateZatcaItemPriceIncludeTax(orderItem: SalesOrderItemV3): number {
        const pricesIncludeVAT = this.taxable && this.priceIncludeTax;
        if (pricesIncludeVAT) {
            return orderItem.price * orderItem.quantity;
        } else {
            return orderItem.price * (1 + orderItem.taxRatePercentage) * orderItem.quantity;
        }
    }
    //#endregion
}

export class SalesOrderV3ExtraAttributes {
    defaultTaxId?: string;
    salesRecognitionCondition?: SalesRecognitionSetting;
    refundCodes?: string[];
    closureCode?: string;
    businessDay?: Date;
}

export class PaySalesOrderCommand {
    id?: string;
    code?: string;
    cashRegister!: MinifiedCashRegisterV3;
    payments: SalesOrderV3Payment[] = [];
    extraAttributes?: SalesOrderV3ExtraAttributes;
    metadata?: Metadata;
}

export class FulfillSalesOrderCommand {
    id?: string;
    extraAttributes?: any;
}
