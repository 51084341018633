export enum LandingModulesNames {
    inventory = 'inventory',
    purchasing = 'purchasing',
    pos = 'pos',
    fixedAssets = 'fixedAssets',
    manufacturing = 'Manufacturing',
    invoicing = 'Invoicing',
    dataModule = 'Data',
    systemModule = 'System',
    samples = 'samples',
    noModule = 'noModule',
}
export enum ModuleSideNavTabs {
    all = 'all',
    starred = 'starred',
}
export class MenuItem {
    label: string = '';
    icon: string = '';
    routerLink: string = '';
    link: string = '';
    level2Location: string = '';
    divider?: boolean = false;
    primaryMenu: boolean = false;
    module!: LandingModulesNames;
    hasStarredPages?: boolean = false;
    isHidden?: boolean = false;
    activeTab?: ModuleSideNavTabs = ModuleSideNavTabs.all;
    activeTabDone?: boolean = false;
    children: ModuleChildrenGroup[] = [];
    starredCount?: number;
}
export class ModuleChildrenGroup {
    title: string = '';
    children: ModulePage[] = [];
    starredItems?: ModulePage[];
}
export class ModulePage {
    id: string = '';
    title: string = '';
    routerLink: string = '';
    module?: LandingModulesNames;
    checkForModuleVersion?: boolean;
    isV3?: boolean;
    permissions?: any;
    isStarred?: boolean;
    doc_id?: string = '';
    lastTimeOpend?: number;
    description?: string = '';
    showItem?: boolean;
}

export class ModulePageOpen {
    doc_id?: string = '';
    id: string = '';
    lastTimeOpend!: number
}

// export const getModuleLandingPages = (moduleLandingPage:MenuItem[] = [], module:string = '') : ModulePage[] => {
//   return moduleLandingPage.find(((_moduleLandingPage:MenuItem) => _moduleLandingPage.module === module))?.children || [];
// }


export const childrensMap = (menu_items: any[]): ModulePage[] => {
    const childrens: ModulePage[] = [];
    const checkChildrens = (child: any) => {
        childrens.push(child);
        if ('children' in child) {
            child.children.forEach((child: any) => {
                checkChildrens(child);
            });
        }
    };
    menu_items.forEach((child) => {
        checkChildrens(child);
    });
    return childrens
};
