
import { ColDef } from "ag-grid-community";

export const defaultAgGridSortOperators = [
    { value: "A → Z", code: "asc" },
    { value: "Z → A", code: "desc" },
];

export class SortConditions {
    column: string | null = null;
    sortType: string | null = null;
    columnDefs: ColDef[] = [];
    constructor(data?:any) {
        if (data) {
            this.column = data?.column || null;
            this.sortType = data?.sortType || null;
            this.columnDefs = data?.columnDefs || [];
        }
    }
    get isValid(): boolean {
        return !!this.column && !!this.sortType
    }
}
export enum agGridSortEnum {
    asc = "asc",
    desc = "desc",
}

export const getAgGridSortStructure = (
    sortConditions: SortConditions[] = []
) => {
    let sortModel = [];
    sortModel = sortConditions.map((sortCond: SortConditions) => {
        return { colId: sortCond.column, sort: sortCond.sortType };
    });
    return sortModel;
};


class FilterConditions {
    column: string | null = null;
    type: string | null = null;
    filter: string | null = null;
}

export enum AgGridCommonFilterViewKeys {
    filterViewList = "ag-grid-filter-view-list",
}

export class AgGridCommonFilterViewItem {
    id: string | null = null;
    name: string | null = null;
    pageName: FilterViewPageNames | null = null;
    tenantId?: string;
    filterConditions: FilterConditions[] = [];
    sortConditions: SortConditions[] = [];
    fieldsAllColumnsState: any[] = [];
    createdAt: number | null = null;
    updatedAt: number | null = null;
    filterOperator: 'AND' | 'OR' = 'AND';
    docId?: string | null = null;
    isDefaultView?: boolean = false;
    lastUsed?: null | number = null;
}
export enum FilterViewPageNames {
    PurchasingProducts = "purchasing-products",
    PurchasingRequestsByRequest = "purchasing-requests-by-request",
    PurchasingRequestsByLineItem = "purchasing-requests-by-line-item",
    PurchasingOrders = "purchasing-orders",
    DebitNotes = "debit-notes",
    DeliveryZones = "delivery-zones"
}

export const FilterConstants = {
    bigLimit: 50000
}
