import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { AccountSettings } from '@shared/models/account-settings';
import { Iti, SomeOptions } from 'intl-tel-input';
import intlTelInput from 'intl-tel-input/intlTelInputWithUtils';
import { AccountSettingsService } from 'src/app/core/services/account-settings.service';
import { FocusMonitor } from "@angular/cdk/a11y";

@Component({
  selector: 'app-intl-tel-input',
  templateUrl: './intl-tel-input.component.html',
  styleUrls: ['./intl-tel-input.component.scss']
})
export class IntlTelInputComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() label: string = '';
  @Input() validationText: string = '';
  @Input() requiredText: string = '';
  @Input() required = false;
  @Input() disabled = false;
  @Input() formSubmitted = false;
  @Input() onlyLocalized = false;
  @Input() inputId: string = '';
  @Input() initialCountry?: string = '';
  @Input()
  set bindingValue(value: string | undefined) {
    if (!value) return;
    this._bindingValue = value;
    this.formCtrl.setValue(value);
  }
  get bindingValue() {
    return this._bindingValue;
  }

  @Output() private E164MobileChange = new EventEmitter<E164MobileChangeEvent>();

  formCtrl = new FormControl({ value: '', disabled: this.disabled });
  private _intlTelInput!: Iti;
  private _options: SomeOptions = {
    initialCountry: 'auto',
    excludeCountries: ['il']
  };
  private _bindingValue: string = '';
  private _E164Mobile: string = '';

  @ViewChild('intlTelInput') private _inputElement!: ElementRef<HTMLInputElement>;

  constructor(private accountSettingsService: AccountSettingsService, private focusMonitor: FocusMonitor) {
  }

  ngOnInit(): void {
    if (this.initialCountry) {
      this._options.initialCountry = this.initialCountry;
    } else {
      this.loadAccountSettings();
    }
  }

  ngAfterViewInit(): void {
    if (this.onlyLocalized) {
      this.modifyCountryData();
    }

    const intlTelInputInstance = intlTelInput;
    this._intlTelInput = intlTelInputInstance(this._inputElement.nativeElement, this._options);

    this.formCtrl.valueChanges.subscribe(value => {
      if (value) {
        this.formCtrl.setErrors({ invalidNumber: true });
        this.onMobileValueChange();
      } else {
        this.E164MobileChange.emit({
          value: '',
          isValid: !this.formCtrl.invalid
        });
      }
    });

    if (this._bindingValue) {
      setTimeout(() => {
        this.formCtrl.setErrors({ invalidNumber: true });
        this.onMobileValueChange();
      });
    }

    this._inputElement?.nativeElement.addEventListener('open:countrydropdown', () => {
      setTimeout(() => {
        this.focusMonitor.focusVia(this._intlTelInput['searchInput'], 'program');
      });
    });
  }

  ngOnDestroy() {
    this._intlTelInput.destroy();
  }

  onMobileValueChange(): void {
    this._E164Mobile = '';
    if (this._intlTelInput.isValidNumber()) {
      const countryCode = `+${this._intlTelInput.getSelectedCountryData().dialCode}`;
      this._E164Mobile = `${countryCode} ${this._intlTelInput.getNumber().split(countryCode).pop()}`;
      this.formCtrl.setErrors(null);
    }
    this.E164MobileChange.emit({
      value: this._E164Mobile,
      isValid: !this.formCtrl.invalid
    });
  }

  onSelectCountryCode() {
    // update preferences country code
    this.onSaveAccountSettings();
    this.onMobileValueChange();
  }

  private async loadAccountSettings() {
    const accountSettings = await this.accountSettingsService.loadAccountSettings();
    if (accountSettings?.countryCode) {
      this._intlTelInput.setCountry(accountSettings.countryCode);
    }
  }

  private onSaveAccountSettings() {
    const settings: AccountSettings = {
      countryCode: this._intlTelInput['defaultCountry']
    };
    this.accountSettingsService.saveAccountSettings(settings);
  }

  private modifyCountryData(): void {
    (window as any).intlTelInputGlobals.getCountryData().forEach((country: any) =>
      country.name = country.name.replace(/.+\((.+)\)/, '$1'));
  }

}

export class E164MobileChangeEvent {
  value!: string;
  isValid!: boolean;
}
