import { Injectable } from '@angular/core';
import { DocumentReference, setDoc } from '@angular/fire/firestore';
import { AuthService } from '@auth';
import { OrganizationSettings, AccountSettings } from '../../shared/models/account-settings';
import { FirestoreService } from './firestore.service';

@Injectable({
  providedIn: 'root'
})
export class AccountSettingsService {

  private get organizationSettingsDocument(): DocumentReference<OrganizationSettings> {
    return this.firestoreSerivce.doc(`accountSettings/${this.authService.currentUserId}/organizationSettings/${this.authService.currentTenant}`);
  }

  private get accountSettingsDocument(): DocumentReference<AccountSettings> {
    return this.firestoreSerivce.doc(`accountSettings/${this.authService.currentUserId}`);
  }

  constructor(
    private authService: AuthService,
    private firestoreSerivce: FirestoreService) {
  }

  loadAccountSettings(): Promise<AccountSettings | undefined> {
    return this.firestoreSerivce.getDocValueAsync(this.accountSettingsDocument);
  }

  loadOrganizationSettings(): Promise<OrganizationSettings | undefined> {
    return this.firestoreSerivce.getDocValueAsync(this.organizationSettingsDocument);
  }

  saveAccountSettings(data: AccountSettings): Promise<void> {
    return setDoc(this.accountSettingsDocument, data, { merge: true });
  }

  saveOrganizationSetting(settings: OrganizationSettings): Promise<void> {
    return setDoc(this.organizationSettingsDocument, settings, { merge: true });
  }

}
