import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, tap } from 'rxjs/operators';
import { Customer } from '../../shared/models/customer';
import { ServiceItem } from '../../shared/models/service-item';
import { SalesOrder, SyncSalesOrderResult } from '../../shared/models/sales-order';
import { SalesPerson } from '../../shared/models/sales-person';
import { ApiResponse, ErrorType } from '../../shared/models/api-response';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { QueryStringHelper } from '../../shared/helpers/query-string.helper';
import { PrintTemplate } from '../../shared/models/print-template';
import { SalesStore } from '../../shared/models/sales-store';
import { SyncStatus } from '../../shared/models/offline-metadata';
import { query, where, orderBy, limit, updateDoc } from '@angular/fire/firestore';
import { SalesBundle } from 'src/app/shared/models/sales-bundle';
import { ServiceResult } from 'src/app/shared/models/service-result';
import { Tag } from 'src/app/shared/models/tag';
import { Promotion, PromotionStatus } from 'src/app/shared/models/promotion';
import { PagedList } from 'src/app/shared/models/paged-list';
import { CashRegisterQuery, CashRegistersClosuresQuery, CashTransactionQuery, CustomerQuery, PromotionQuery, CashRegisterSalesSummaryQuery } from 'src/app/shared/models/query';
import { CashRegister } from 'src/app/shared/models/cash-register';
import { AppSettingsService, FirestoreService, OnlineOfflineService } from '@core';
import { AuthService } from '@auth';
import { CustomerV3, DexieDbProvider, NativeServiceSyncStatus, SalesOrderV3, SyncResult } from '@shared';
import { Product } from 'src/app/point-of-sale/models/product';
import { PrintTemplateV3 } from '@shared/models/print-template-v3';
import { ImageService } from '@core/services/image.service';
import { convertToDate } from '@shared/helpers/convert-to-date';
import { flatMap, uniq } from 'lodash';
import { PosLocation } from 'src/app/point-of-sale/models/pos-location';
import { CustomerSyncResult, CustomerV3SyncStatus } from '@core/models/customer-sync-result';
import { Tax } from 'src/app/point-of-sale/models/tax.model';
import { DeliveryZone } from 'src/app/point-of-sale/models/delivery-zone';

@Injectable({
  providedIn: 'root'
})
export class CorePosService {
  baseUrl = '';
  edaraCoreBaseUrl = '';
  edaraNativeServiceBaseUrl = '';
  promotionFilter?: BehaviorSubject<PromotionQuery>;
  cashRegisterFilter?: BehaviorSubject<CashRegisterQuery>;
  cashTransactionFilter?: BehaviorSubject<CashTransactionQuery>;
  cashRegistersClosuresFilter?: BehaviorSubject<CashRegistersClosuresQuery>;
  customerFilter?: BehaviorSubject<CustomerQuery>;
  cashRegisterSalesSummaryFilter?: BehaviorSubject<CashRegisterSalesSummaryQuery>;

  constructor(
    private authService: AuthService,
    private httpClient: HttpClient,
    private firestoreSerivce: FirestoreService,
    private localDbProvider: DexieDbProvider,
    private onlineOfflineService: OnlineOfflineService,
    private imageService: ImageService) {
    this.baseUrl = AppSettingsService.appSettings.edaraApi.baseUrl;
    this.edaraCoreBaseUrl = AppSettingsService.appSettings.edaraCoreApi.baseUrl;
    if (this.edaraCoreBaseUrl.endsWith('/')) {
      this.edaraCoreBaseUrl = this.edaraCoreBaseUrl.slice(0, -1);
    }
    this.edaraNativeServiceBaseUrl = AppSettingsService.appSettings.edaraNativeService.baseUrl;
    if (this.edaraNativeServiceBaseUrl.endsWith('/')) {
      this.edaraNativeServiceBaseUrl = this.edaraNativeServiceBaseUrl.slice(0, -1);
    }
  }

  async loadAllCustomers(): Promise<ServiceResult<Customer[]>> {
    const query = {
      offset: 0,
      limit: 1000
    };
    const serviceResult = new ServiceResult<Customer[]>();
    const count = await this.countAllCustomersLocalAsync();
    if (!this.onlineOfflineService.isOnline || count > 0) {
      query.limit = count;
      serviceResult.statusCode = 200;
      serviceResult.result = await this.getCustomersLocalAsync(query);
      serviceResult.totalCount = count;
      return serviceResult;
    } else {
      let response: ApiResponse<Customer[]> | undefined;
      let customers: Customer[] = [];
      do {
        response = await this.getCustomersAsync(query);
        if (response && response.status_code === 200) {
          query.offset += query.limit;
          customers = customers.concat(response.result);
          this.addCustomersToLocalDb(response.result);
        }
      } while (response && response.status_code === 200 && response.total_count > query.offset);
      serviceResult.statusCode = response?.status_code;
      serviceResult.result = customers;
      serviceResult.statusMessage = response?.error_message;
      serviceResult.totalCount = response?.total_count;
      return serviceResult;
    }
  }

  private countAllCustomersLocalAsync(): Promise<number> {
    return this.localDbProvider.db.customers.count()
      .catch((err: any) => this.localDbProvider.handleErrors(err));
  }

  addCustomersToLocalDb(customers: Customer[]) {
    if (customers && customers.length > 0) {
      this.deleteOfflineCustomers(customers);
      customers.forEach(customer => this.mapCustomer(customer));
      this.localDbProvider.db.customers.bulkPut(customers)
        .catch((err: any) => this.localDbProvider.handleErrors(err));
    }
  }

  deleteOfflineCustomers(customers: Customer[]) {
    const offlineCustomers = customers.filter(customer => customer.external_id);
    for (let index = 0; index < offlineCustomers.length; index++) {
      const element = offlineCustomers[index];
      if (element.external_id) {
        this.deleteCustomerByExternalIdFromLocalDb(element.external_id);
      }
    };
  }

  deleteCustomerByExternalIdFromLocalDb(externalId: string) {
    this.localDbProvider.db.customers
      .where('external_id').equals(externalId)
      .delete()
      .catch((err: any) => this.localDbProvider.handleErrors(err));
  }

  getCustomersAsync(query: any): Promise<ApiResponse<Customer[]> | undefined> {
    const queryString = new QueryStringHelper().toQueryString(query);
    return firstValueFrom(
      this.httpClient.get<ApiResponse<Customer[]>>(this.baseUrl + 'v2.0/customers?' + queryString)
        .pipe(map(res => {
          if (res.result) {
            res.result.forEach(customer => this.mapCustomer(customer));
          }
          return res;
        }))
    ).catch(() => { return undefined; });
  }

  private async getCustomersLocalAsync(query: any): Promise<Customer[]> {
    return this.getCustomersQueryFn(query)
      .offset(query.offset)
      .limit(query.limit)
      .toArray();
  }

  private getCustomersQueryFn(query: any) {
    const queryFn = this.localDbProvider.db.customers.orderBy('id')
      .filter((itm: Customer) => !query.code || itm.code?.includes(query.code))
      .and((itm: Customer) => !query.name || itm.name.includes(query.name))
      .and((itm: Customer) => !query.phone || itm.phone?.includes(query.phone))
      .and((itm: Customer) => !query.mobile || itm.mobile?.includes(query.mobile))
      .and((itm: Customer) => !query.email || itm.email?.includes(query.email));

    if (query.syncStatus === SyncStatus.Synced)
      queryFn.and((itm: Customer) => !itm.syncMetadata);
    else if (query.syncStatus === SyncStatus.Pending)
      queryFn.and((itm: Customer) => itm.syncMetadata);

    return queryFn;
  }

  addCustomerToLocalDb(customer: Customer) {
    if (customer) {
      this.mapCustomer(customer);
      this.localDbProvider.db.customers.put(customer)
        .catch((err: any) => this.localDbProvider.handleErrors(err));
    }
  }

  updateCustomersSyncStatus(salesOrders: SalesOrder[], syncSalesOrderResults: SyncSalesOrderResult[]) {
    if (salesOrders?.length && syncSalesOrderResults?.length) {
      syncSalesOrderResults.forEach(syncResult => {
        if (syncResult.customer_id) {
          const salesOrder = salesOrders.find(so => so.external_id === syncResult.external_id);
          if (salesOrder?.customer?.syncMetadata) {
            this.saveSyncedCustomerToLocalDb(syncResult.customer_id, salesOrder.customer);
          }
        }
      });
    }
  }

  saveSyncedCustomerToLocalDb(customerId: number, customer: Customer) {
    if (customer.external_id) {
      this.deleteCustomerByExternalIdFromLocalDb(customer.external_id);
    }
    customer.id = customerId;
    delete customer.syncMetadata;
    this.addCustomerToLocalDb(customer);
  }

  private mapCustomer(customer: Customer) {
    if (customer.mobile && customer.mobile.trim().includes(' ')) {
      customer.mobile_parts = customer.mobile.split(' ').filter(itm => !!itm);
    }
  }

  getServiceItemsAsync(query: any): Promise<ApiResponse<ServiceItem[]> | undefined> {
    const queryString = new QueryStringHelper().toQueryString(query);
    return firstValueFrom(
      this.httpClient.get<ApiResponse<ServiceItem[]>>(this.baseUrl + 'v2.0/serviceItems?' + queryString)
        .pipe(map(res => {
          if (res.result) {
            res.result.forEach(serviceItem => this.mapServiceItem(serviceItem));
          }
          return res;
        }))
    ).catch(() => { return undefined; });
  }

  addServiceItemsToLocalDb(serviceItems: ServiceItem[]) {
    if (serviceItems && serviceItems.length > 0) {
      serviceItems.forEach(serviceItem => this.mapServiceItem(serviceItem));
      this.localDbProvider.db.serviceItems.bulkAdd(serviceItems)
        .catch((err: any) => this.localDbProvider.handleErrors(err));
    }
  }

  searchServiceItemsServer(searchKeyWords: string) {
    return this.httpClient.get<ApiResponse<ServiceItem[]>>(this.baseUrl + `v2.0/serviceItems/SearchServiceItems/${searchKeyWords}`)
      .pipe(map((res: ApiResponse<ServiceItem[]>) => {
        if (res.result) {
          this.addServiceItemsToLocalDb(res.result);
          return res.result;
        }
        return [];
      }));
  }

  private mapServiceItem(serviceItem: ServiceItem) {
    if (serviceItem.description && serviceItem.description.trim().includes(' ')) {
      serviceItem.description_keywords = serviceItem.description.split(' ').filter(itm => !!itm);
    }
  }

  private minifySalesOrders(salesOrders: SalesOrder[]): SalesOrder[] {
    return salesOrders.map(so => {
      so = SalesOrder.clone(so);
      return so.minify();
    });
  }

  syncSalesOrdersToServerAsync(salesOrders: SalesOrder[]): Promise<ApiResponse<SyncSalesOrderResult[]> | undefined> {
    return firstValueFrom(
      this.httpClient.post<ApiResponse<SyncSalesOrderResult[]>>(this.baseUrl + 'v2.0/salesOrders/sync', this.minifySalesOrders(salesOrders))
    ).catch(() => { return undefined; });
  }

  async getPendingSalesOrders(queryLimit: number): Promise<SalesOrder[]> {
    const queryFn = query(this.firestoreSerivce.col<SalesOrder>('offlineSalesOrders'),
      where('offlineMetadata.organizationId', '==', this.authService.currentTenant),
      where('offlineMetadata.createdBy.email', '==', this.authService.userProfile?.email),
      where('offlineMetadata.syncPending', '==', SyncStatus.Pending),
      orderBy('document_date', 'desc'),
      limit(queryLimit)
    );

    return this.firestoreSerivce.getCollectionAsync<SalesOrder>(queryFn);
  }

  getProcessingSalesOrders(queryLimit: number): Promise<SalesOrder[]> {
    const tenMinutesAgo = new Date(Date.now() - 10 * 60 * 1000);
    const queryFn = query(this.firestoreSerivce.col<SalesOrder>('offlineSalesOrders'),
      where('offlineMetadata.organizationId', '==', this.authService.currentTenant),
      where('offlineMetadata.createdBy.email', '==', this.authService.userProfile?.email),
      where('offlineMetadata.syncPending', '==', SyncStatus.Processing),
      where('offlineMetadata.processingStartedAt', '<', tenMinutesAgo),
      orderBy('offlineMetadata.processingStartedAt'),
      limit(queryLimit)
    );

    return this.firestoreSerivce.getCollectionAsync(queryFn);
  }

  async getPendingOrProcessingSalesOrders(limit: number): Promise<SalesOrder[]> {
    let result = await this.getPendingSalesOrders(limit);
    if (result?.length < limit) {
      const processingOrders = await this.getProcessingSalesOrders(limit - result.length);
      if (processingOrders?.length > 0) {
        result = result.concat(processingOrders);
      }
    }

    return result.map(so => this.mapFirestoreDocumentToSalesOrder(so));
  }

  async getAllUnsyncedSalesOrders(): Promise<SalesOrder[]> {
    const queryFn = query(this.firestoreSerivce.col<SalesOrder>('offlineSalesOrders'),
      where('offlineMetadata.organizationId', '==', this.authService.currentTenant),
      where('offlineMetadata.syncPending', '>=', 1),
      orderBy('offlineMetadata.syncPending'),
      orderBy('document_date', 'desc')
    );

    const result = await this.firestoreSerivce.getCollectionAsync<SalesOrder>(queryFn);

    return result.map(so => this.mapFirestoreDocumentToSalesOrder(so));
  }

  async markSalesOrdersAsProcessingAsync(salesOrders: SalesOrder[]) {
    console.log('Marking salesOrders as processing and set processing start date...');
    const dateNow = this.firestoreSerivce.serverDateTime();
    salesOrders.forEach(async (salesOrder) => {
      const updatedSalesOrder = {
        'offlineMetadata.syncPending': SyncStatus.Processing, // Processing,
        'offlineMetadata.syncStatusMessage': "Processing", // Processing
        'offlineMetadata.processingStartedAt': dateNow
      };
      await updateDoc(this.firestoreSerivce.doc(`offlineSalesOrders/${salesOrder.external_id}`), updatedSalesOrder);
    });
  }

  async loadAllPrintTemplates(): Promise<ServiceResult<PrintTemplate[]>> {
    const serviceResult = new ServiceResult<PrintTemplate[]>();
    const count = await this.countPrintTemplatesLocalAsync();
    if (!this.onlineOfflineService.isOnline || count > 0) {
      serviceResult.statusCode = 200;
      serviceResult.result = await this.getPrintTemplatesLocalAsync();
      serviceResult.totalCount = count;
      return serviceResult;
    } else {
      const query = {
        offset: 0,
        limit: 1000
      };
      let response: ApiResponse<PrintTemplate[]> | undefined;
      let printTemplates: PrintTemplate[] = [];
      do {
        response = await this.getPrintTemplatesAsync(query);
        if (response && response.status_code === 200) {
          query.offset += query.limit;
          printTemplates = printTemplates.concat(response.result);
          this.addPrintTemplatesToLocalDb(response.result);
        }
      } while (response && response.status_code === 200 && response.total_count > query.offset);
      serviceResult.statusCode = response?.status_code;
      serviceResult.result = printTemplates;
      serviceResult.statusMessage = response?.error_message;
      serviceResult.totalCount = response?.total_count;
      return serviceResult;
    }
  }

  addPrintTemplatesToLocalDb(printTemplates: PrintTemplate[]) {
    this.localDbProvider.db.printTemplates.bulkPut(printTemplates)
      .catch((err: any) => this.localDbProvider.handleErrors(err));
  }

  getPrintTemplatesAsync(query: any): Promise<ApiResponse<PrintTemplate[]> | undefined> {
    const queryString = new QueryStringHelper().toQueryString(query);
    return firstValueFrom(
      this.httpClient.get<ApiResponse<PrintTemplate[]>>(this.baseUrl + 'v2.0/salesOrders/PrintTemplates?' + queryString)
    ).catch(() => { return undefined; });
  }

  private countPrintTemplatesLocalAsync(): Promise<number> {
    return this.localDbProvider.db.printTemplates.count()
      .catch((err: any) => this.localDbProvider.handleErrors(err));
  }

  private getPrintTemplatesLocalAsync(): Promise<PrintTemplate[]> {
    return this.localDbProvider.db.printTemplates.toArray();
  }

  async updateSalesOrdersSyncStatusOnFirebase(syncSalesOrdersResult: SyncSalesOrderResult[]) {
    if (syncSalesOrdersResult && syncSalesOrdersResult.length > 0) {
      syncSalesOrdersResult.forEach(async (syncResult) => {
        const salesOrder = {
          'document_code': syncResult.document_code,
          'offlineMetadata.syncPending': syncResult.status_code === true ? SyncStatus.Synced : SyncStatus.ConflictOrError,
          'offlineMetadata.syncStatusCode': syncResult.status_code === true ? 200 : this.getErrorStatusCode(syncResult.status_message),
          'offlineMetadata.syncStatusMessage': syncResult.status_code === true ? 'Success' : syncResult.status_message,
          'offlineMetadata.processingCompletedAt': this.firestoreSerivce.serverDateTime()
        };
        await updateDoc(this.firestoreSerivce.doc(`offlineSalesOrders/${syncResult.external_id}`), salesOrder);
      });
    }
  }

  private getErrorStatusCode(statusMessage: string) {
    return statusMessage.startsWith(ErrorType.BadRequest) ? 400 : 500;
  }

  private mapFirestoreDocumentToSalesOrder(so: SalesOrder) {
    so.document_date = convertToDate(so.document_date);
    so.salesOrder_installments.map(installment => {
      installment.due_date = convertToDate(installment.due_date);
      return installment;
    });
    if (so.offlineMetadata) {
      so.offlineMetadata.createdDate = convertToDate(so.offlineMetadata.createdDate);
    }
    return so;
  }

  async loadAllSalesPersons(): Promise<ServiceResult<SalesPerson[]>> {
    const query = {
      offset: 0,
      limit: 1000
    };
    const serviceResult = new ServiceResult<SalesPerson[]>();
    const count = await this.countSalesPersonsLocalAsync();
    if (!this.onlineOfflineService.isOnline || count > 0) {
      query.limit = count;
      serviceResult.statusCode = 200;
      serviceResult.result = await this.getSalesPersonsLocalAsync(query);
      serviceResult.totalCount = count;
      return serviceResult;
    } else {
      let response: ApiResponse<SalesPerson[]> | undefined;
      let salesPersons: SalesPerson[] = [];
      do {
        response = await this.getSalesPersonsAsync(query);
        if (response && response.status_code === 200) {
          query.offset += query.limit;
          salesPersons = salesPersons.concat(response.result);
          this.addSalesPersonsToLocalDb(response.result);
        }
      } while (response && response.status_code === 200 && response.total_count > query.offset);
      serviceResult.statusCode = response?.status_code;
      serviceResult.result = salesPersons;
      serviceResult.statusMessage = response?.error_message;
      serviceResult.totalCount = response?.total_count;
      return serviceResult;
    }
  }

  addSalesPersonsToLocalDb(salesPersons: SalesPerson[]) {
    if (salesPersons && salesPersons.length > 0) {
      this.localDbProvider.db.salesPersons.bulkPut(salesPersons)
        .catch((err: any) => this.localDbProvider.handleErrors(err));
    }
  }

  getSalesPersonsAsync(query: any): Promise<ApiResponse<SalesPerson[]> | undefined> {
    const queryString = new QueryStringHelper().toQueryString(query);
    return firstValueFrom(
      this.httpClient.get<ApiResponse<SalesPerson[]>>(this.baseUrl + 'v2.0/salesPersons?' + queryString)
    ).catch(() => { return undefined; });
  }

  private countSalesPersonsLocalAsync(): Promise<number> {
    return this.localDbProvider.db.salesPersons.count()
      .catch((err: any) => this.localDbProvider.handleErrors(err));
  }

  private getSalesPersonsLocalAsync(query: any): Promise<SalesPerson[]> {
    return this.localDbProvider.db.salesPersons
      .offset(query.offset)
      .limit(query.limit)
      .toArray();
  }

  getSalesStoresAsync(query: any): Promise<ApiResponse<SalesStore[]> | undefined> {
    const queryString = new QueryStringHelper().toQueryString(query);
    return firstValueFrom(
      this.httpClient.get<ApiResponse<SalesStore[]>>(this.baseUrl + 'v2.0/salesStores?' + queryString)
    ).catch(() => { return undefined; });
  }

  addSalesStoresToLocalDb(salesStores: SalesStore[]) {
    if (salesStores && salesStores.length > 0) {
      this.localDbProvider.db.salesStores.bulkAdd(salesStores)
        .catch((err: any) => this.localDbProvider.handleErrors(err));
    }
  }

  getSalesBundlesAsync(query: any): Promise<ApiResponse<SalesBundle[]> | undefined> {
    const queryString = new QueryStringHelper().toQueryString(query);
    return firstValueFrom(
      this.httpClient.get<ApiResponse<SalesBundle[]>>(this.baseUrl + 'v2.0/salesBundles?' + queryString)
        .pipe(map(res => {
          if (res.result) {
            res.result.forEach(salesBundle => this.mapSalesBundle(salesBundle));
          }
          return res;
        }))
    ).catch(() => { return undefined; });
  }

  addSalesBundlesToLocalDb(salesBundles: SalesBundle[]) {
    if (salesBundles && salesBundles.length > 0) {
      salesBundles.forEach(salesBundle => this.mapSalesBundle(salesBundle));
      this.localDbProvider.db.salesBundles.bulkAdd(salesBundles)
        .catch((err: any) => this.localDbProvider.handleErrors(err));
    }
  }

  searchSalesBundlesServer(searchKeyWords: string) {
    return this.httpClient.get<ApiResponse<SalesBundle[]>>(this.baseUrl + `v2.0/salesBundles/Search/${searchKeyWords}`)
      .pipe(map((res: ApiResponse<SalesBundle[]>) => {
        if (res.result) {
          this.addSalesBundlesToLocalDb(res.result);
          return res.result;
        }
        return [];
      }));
  }

  private mapSalesBundle(salesBundle: SalesBundle) {
    if (salesBundle.description && salesBundle.description.trim().includes(' ')) {
      salesBundle.description_keywords = salesBundle.description.split(' ').filter(itm => !!itm);
    }
  }

  addTagsToLocalDb(tags: Tag[]) {
    if (tags && tags.length > 0) {
      this.localDbProvider.db.tags.bulkPut(tags)
        .catch((err: any) => this.localDbProvider.handleErrors(err));
    }
  }

  getTagsAsync(query: any): Promise<ApiResponse<Tag[]> | undefined> {
    const queryString = new QueryStringHelper().toQueryString(query);
    return firstValueFrom(
      this.httpClient.get<ApiResponse<Tag[]>>(this.baseUrl + 'v2.0/common/tags?' + queryString)
    ).catch(() => { return undefined; });
  }

  addPromotionsToLocalDb(promotions?: Promotion[]) {
    if (promotions && promotions.length > 0) {
      this.localDbProvider.db.POS_Promotions.bulkPut(promotions)
        .catch((err: any) => this.localDbProvider.handleErrors(err));
    }
  }

  clearPromotionsFromLocalDb() {
    this.localDbProvider.db.POS_Promotions.clear();
  }

  getCurrentPromotionsAsync(): Promise<PagedList<Promotion[]> | undefined> {
    const query: PromotionQuery = {
      offset: 0,
      limit: 100,
      status: PromotionStatus.Current,
    };
    const queryString = new QueryStringHelper().toQueryString(query);
    return firstValueFrom(
      this.httpClient.get<PagedList<Promotion[]>>(this.edaraCoreBaseUrl + '/api/pos/promotions/find?' + queryString)
    ).catch(() => { return undefined; });
  }

  async findPromotionsAsync(query: any): Promise<PagedList<Promotion[]> | undefined> {
    const queryString = new QueryStringHelper().toQueryString(query);
    return firstValueFrom(
      this.httpClient.get<PagedList<Promotion[]>>(this.edaraCoreBaseUrl + '/api/pos/promotions/find?' + queryString)
    ).catch(() => { return undefined; });
  }

  deletePromotion(promotionId: string) {
    this.deletePromotionLocal(promotionId);
    return this.httpClient.delete<boolean>(this.edaraCoreBaseUrl + '/api/pos/promotions/' + promotionId);
  }

  async getCurrentPromotions(): Promise<Promotion[]> {
    let result: Promotion[] = [];
    const count = await this.countPromotionsLocalAsync();
    if (!this.onlineOfflineService.isOnline || count > 0) {
      result = await this.getCurrentPromotionsLocalAsync();
      return result;
    } else {
      const res = await this.getCurrentPromotionsAsync();
      this.addPromotionsToLocalDb(res?.items);
      return res ? res.items : [];
    }
  }

  private countPromotionsLocalAsync(): Promise<number> {
    return this.localDbProvider.db.POS_Promotions.count()
      .catch((err: any) => this.localDbProvider.handleErrors(err));
  }

  private getCurrentPromotionsLocalAsync(): Promise<Promotion[]> {
    return this.localDbProvider.db.POS_Promotions
      .filter((itm: Promotion) => new Date(itm.startDate) < new Date())
      .and((itm: Promotion) => new Date(itm.endDate) > new Date())
      .toArray();
  }

  private deletePromotionLocal(promotionId: string) {
    return this.localDbProvider.db.POS_Promotions.delete(promotionId)
      .catch((err: any) => this.localDbProvider.handleErrors(err));
  }

  async checkEdaraNativeServiceHealthAsync(): Promise<boolean> {
    try {
      const res = await firstValueFrom(
        this.httpClient.get(this.edaraNativeServiceBaseUrl + `/healthcheck`, { responseType: 'text' })
      ).catch(() => { return undefined; });
      return res === 'Healthy';
    } catch {
      return false;
    }
  }

  async findCashRegistersAsync(query: any): Promise<PagedList<CashRegister[]> | undefined> {
    const queryString = new QueryStringHelper().toQueryString(query);
    return firstValueFrom(
      this.httpClient.get<PagedList<CashRegister[]>>(this.edaraCoreBaseUrl + '/api/pos/cashregisters/find?' + queryString)
        .pipe(tap(data => this.cacheCashRegistersImages(data)))
    ).catch(() => { return undefined; });
  }

  private async cacheCashRegistersImages(data: PagedList<CashRegister[]>) {
    if (data.items?.length > 0) {
      await Promise.all(
        data.items.filter(cashRegister => !!cashRegister.printTemplates)
          .map(cashRegister => this.cachePrintTemplatesImages(cashRegister.printTemplates))
      );
    }
    return data;
  }

  private async cachePrintTemplatesImages(printTemplates: PrintTemplateV3[]) {
    for (let index = 0; index < printTemplates.length; index++) {
      const element = printTemplates[index];
      if (element.header?.logoUrl) {
        await this.imageService.fetchImage(element.header.logoUrl)
          .then(blob => this.imageService.saveImageToDatabase(element.header!.logoUrl!, blob));
      }
    }
  }

  async findCashRegisterByUserEmail(email: string): Promise<CashRegister | null> {
    const localResponse = await this.getCashRegistersByUserEmailLocalAsync(email);
    if (localResponse) return localResponse.active ? localResponse : null;

    const query = {
      activatedUser: email,
      active: true
    };
    const response = await this.findCashRegistersAsync(query);
    return response?.items?.length ? response.items[0] : null;
  }

  private getCashRegistersByUserEmailLocalAsync(email: string): Promise<CashRegister | undefined> {
    return firstValueFrom(
      this.httpClient.get<CashRegister>(this.edaraNativeServiceBaseUrl + `/cashregisters/ByEmail?email=${email}`)
    ).catch(() => { return undefined; });
  }

  addCashRegistersToLocalDb(cashRegisters: CashRegister[]) {
    if (cashRegisters && cashRegisters.length > 0) {
      this.localDbProvider.db.POS_CashRegisters.bulkPut(cashRegisters)
        .catch((err: any) => this.localDbProvider.handleErrors(err));
    }
  }

  clearCashRegistersFromLocalDb() {
    this.localDbProvider.db.POS_CashRegisters.clear();
  }

  findPosProductsAsync(query: any): Promise<PagedList<Product[]> | undefined> {
    const queryString = new QueryStringHelper().toQueryString(query);
    return firstValueFrom(
      this.httpClient.get<PagedList<Product[]>>(this.edaraCoreBaseUrl + '/api/pos/Products/find?' + queryString)
    ).catch(() => { return undefined; });
  }

  addPosProductsToLocalDb(products: Product[]) {
    if (products && products.length > 0) {
      products.forEach(product => this.mapPosProduct(product));
      this.localDbProvider.db.POS_Products.bulkPut(products)
        .catch((err: any) => this.localDbProvider.handleErrors(err));
    }
  }

  private mapPosProduct(product: Product) {
    if (product.unitOfMeasures) {
      product.uom_barcodes = product.unitOfMeasures
        .map(item => item.partNumber)
        .filter((itm): itm is string => !!itm);

      product.uom_SKUs = product.unitOfMeasures
        .map(item => item.sku)
        .filter((itm): itm is string => !!itm);
    }

    if (product.variants) {
      product.variant_barcodes = product.variants.map(item => item.partNumber).filter(itm => !!itm);
      product.variant_SKUs = product.variants.map(item => item.sku).filter(itm => !!itm);
    }

    const searchKeywords = product.name.split(' ').filter(itm => !!itm);
    if (product.attributes) {
      searchKeywords.push(...flatMap(product.attributes, x => x.values));
    }
    product.searchKeywords = uniq(searchKeywords);
  }

  getUnsyncedCustomersV3Async(): Promise<CustomerV3[]> {
    return this.localDbProvider.db.POS_Customers_V3.orderBy('createdDate')
      .and((itm: CustomerV3) => !!itm.localId)
      .toArray();
  }

  syncCustomersV3ToServerAsync(customers: CustomerV3[]): Promise<CustomerSyncResult[] | undefined> {
    return firstValueFrom(
      this.httpClient.post<CustomerSyncResult[]>(this.edaraCoreBaseUrl + '/api/pos/customers/sync', customers)
    ).catch(() => { return undefined; });
  }

  saveSyncedCustomersV3ToLocalDb(syncResults: CustomerSyncResult[]) {
    for (let customerSyncResult of syncResults) {
      if (customerSyncResult.syncStatus === CustomerV3SyncStatus.Success) {
        // Delete old customer record
        this.deleteCustomerV3ByLocalIdFromLocalDb(customerSyncResult.localId);

        // Add customer to local db
        this.mapCustomerV3(customerSyncResult.customer);
        this.localDbProvider.db.POS_Customers_V3.put(customerSyncResult.customer)
          .catch((err: any) => this.localDbProvider.handleErrors(err));
      }
    }
  }

  deleteCustomerV3ByLocalIdFromLocalDb(localId: string) {
    this.localDbProvider.db.POS_Customers_V3
      .where('localId').equals(localId)
      .delete()
      .catch((err: any) => this.localDbProvider.handleErrors(err));
  }

  findCustomersV3Async(query: CustomerQuery): Promise<PagedList<CustomerV3[]> | undefined> {
    const queryString = new QueryStringHelper().toQueryString(query);
    return firstValueFrom(
      this.httpClient.get<PagedList<CustomerV3[]>>(this.edaraCoreBaseUrl + '/api/pos/Customers/find?' + queryString)
    ).catch(() => { return undefined; });
  }

  addCustomersV3ToLocalDb(customers: CustomerV3[]) {
    if (customers && customers.length > 0) {
      customers.forEach(customer => this.mapCustomerV3(customer));
      this.localDbProvider.db.POS_Customers_V3.bulkPut(customers)
        .catch((err: any) => this.localDbProvider.handleErrors(err));
    }
  }

  private mapCustomerV3(customer: CustomerV3) {
    if (customer.mobile && customer.mobile.trim().includes(' ')) {
      customer.mobileParts = customer.mobile.split(' ').filter(itm => !!itm);
    }
  }

  async getPendingOrProcessingSalesOrdersV3(limit: number): Promise<SalesOrderV3[]> {
    let result = await this.getPendingSalesOrdersV3(limit);
    if (result?.length < limit) {
      const processingOrders = await this.getProcessingSalesOrdersV3(limit - result.length);
      if (processingOrders?.length > 0) {
        result = result.concat(processingOrders);
      }
    }
    return result.map(so => this.mapFirestoreDocumentToSalesOrderV3(so));
  }

  async getPendingSalesOrdersV3(queryLimit: number): Promise<SalesOrderV3[]> {
    const queryFn = query(this.firestoreSerivce.col<SalesOrderV3>('salesOrdersV3'),
      where('metadata.organizationInfo.id', '==', this.authService.currentTenant),
      where('metadata.createdBy.email', '==', this.authService.userProfile?.email),
      where('metadata.syncInfo.syncStatus', '==', NativeServiceSyncStatus[NativeServiceSyncStatus.Pending]),
      orderBy('orderDate', 'desc'),
      limit(queryLimit)
    );
    return this.firestoreSerivce.getCollectionAsync<SalesOrderV3>(queryFn);
  }

  getProcessingSalesOrdersV3(queryLimit: number): Promise<SalesOrderV3[]> {
    const tenMinutesAgo = new Date(Date.now() - 10 * 60 * 1000);
    const queryFn = query(this.firestoreSerivce.col<SalesOrderV3>('salesOrdersV3'),
      where('metadata.organizationInfo.id', '==', this.authService.currentTenant),
      where('metadata.createdBy.email', '==', this.authService.userProfile?.email),
      where('metadata.syncInfo.syncStatus', '==', NativeServiceSyncStatus[NativeServiceSyncStatus.Processing]),
      where('metadata.syncInfo.processingStartedAt', '<', tenMinutesAgo),
      orderBy('metadata.syncInfo.processingStartedAt'),
      limit(queryLimit)
    );
    return this.firestoreSerivce.getCollectionAsync(queryFn);
  }

  private mapFirestoreDocumentToSalesOrderV3(salesOrder: SalesOrderV3) {
    salesOrder.orderDate = convertToDate(salesOrder.orderDate);
    if (salesOrder.metadata?.createdDate) {
      salesOrder.metadata.createdDate = convertToDate(salesOrder.metadata.createdDate);
    }
    return salesOrder;
  }

  async markSalesOrdersV3AsProcessingAsync(salesOrders: SalesOrderV3[]) {
    console.log('Marking Sales Orders V3 as processing and set processing start date...');
    const dateNow = this.firestoreSerivce.serverDateTime();
    salesOrders.forEach(async (salesOrder) => {
      if (salesOrder.metadata?.localId) {
        const updatedSalesOrder = {
          'metadata.syncInfo.syncStatus': NativeServiceSyncStatus[NativeServiceSyncStatus.Processing],
          'metadata.syncInfo.processingStartedAt': dateNow
        };
        await updateDoc(this.firestoreSerivce.doc(`salesOrdersV3/${salesOrder.metadata.localId}`), updatedSalesOrder);
      }
    });
  }

  createSalesOrderV3(salesOrder: SalesOrderV3): Promise<SalesOrderV3 | undefined> {
    return firstValueFrom(
      this.httpClient.post<SalesOrderV3>(this.edaraCoreBaseUrl + '/api/pos/SalesOrders', salesOrder.minify())
    ).catch(() => { return undefined; });
  }

  findLocationsAsync(query: any): Promise<PagedList<PosLocation[]> | undefined> {
    const queryString = new QueryStringHelper().toQueryString(query);
    return firstValueFrom(
      this.httpClient.get<PagedList<PosLocation[]>>(this.edaraCoreBaseUrl + '/api/pos/Locations/find?' + queryString)
    ).catch(() => { return undefined; });
  }

  addLocationsToLocalDb(locations: PosLocation[]) {
    if (locations && locations.length > 0) {
      this.localDbProvider.db.POS_Locations.bulkPut(locations)
        .catch((err: any) => this.localDbProvider.handleErrors(err));
    }
  }

  syncSalesOrdersV3ToServerAsync(salesOrders: SalesOrderV3[]): Promise<SyncResult[] | undefined> {
    return firstValueFrom(
      this.httpClient.put<SyncResult[]>(this.edaraCoreBaseUrl + '/api/pos/salesorders/sync', salesOrders)
    ).catch(() => { return undefined; });
  }

  async updateSalesOrdersV3SyncStatusOnFirebase(syncResults: SyncResult[]) {

    if (!syncResults?.length) return;

    const dateNow = this.firestoreSerivce.serverDateTime()
    syncResults.forEach(async syncResult => {
      const salesOrderObj = {
        'metadata.syncInfo.syncStatus': NativeServiceSyncStatus[syncResult.syncStatus],
        'metadata.syncInfo.syncStatusMessage': syncResult.error,
        'metadata.syncInfo.processingCompletedAt': dateNow
      };
      await updateDoc(this.firestoreSerivce.doc(`salesOrdersV3/${syncResult.localId}`), salesOrderObj);
    });
  }

  findTaxesAsync(query: any): Promise<PagedList<Tax[]> | undefined> {
    const queryString = new QueryStringHelper().toQueryString(query);
    return firstValueFrom(
      this.httpClient.get<PagedList<Tax[]>>(this.edaraCoreBaseUrl + '/api/pos/taxes/find?' + queryString)
    ).catch(() => { return undefined; });
  }

  addTaxesToLocalDb(taxes: Tax[]) {
    if (taxes && taxes.length) {
      this.localDbProvider.db.POS_Taxes.bulkPut(taxes)
        .catch((err: any) => this.localDbProvider.handleErrors(err));
    }
  }

  findDeliveryZonesAsync(query: any): Promise<PagedList<DeliveryZone[]> | undefined> {
    const queryString = new QueryStringHelper().toQueryString(query);
    return firstValueFrom(
      this.httpClient.get<PagedList<DeliveryZone[]>>(this.edaraCoreBaseUrl + '/api/pos/DeliveryZones/find?' + queryString)
    ).catch(() => { return undefined; });
  }

  addDeliveryZonesToLocalDb(deliveryZones: DeliveryZone[]) {
    if (deliveryZones && deliveryZones.length > 0) {
      this.localDbProvider.db.POS_DeliveryZones.bulkPut(deliveryZones)
        .catch((err: any) => this.localDbProvider.handleErrors(err));
    }
  }
}
